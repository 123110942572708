import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Product } from '@shared/models/product';

// @Injectable({providedIn: 'root'})
@Injectable()
export class RoomsService {
  private rooms: any[] = [];
  roomsChanged = new Subject<Product[]>();
  private room: any;
  constructor() {}
  roomsList = [];

  setRooms(rooms) {
    this.rooms = rooms;
    this.roomsChanged.next(this.rooms.slice());
    this.roomList(this.rooms);

    return this.rooms;
  }

  getRooms() {
    return this.rooms.slice();
  }

  addNewRoom(room) {
    this.rooms.push(room);
    this.roomsChanged.next(this.rooms.slice());
    this.roomList(this.rooms);
  }

  editRoom(room, id) {
    for (const i in this.rooms) {
      if (this.rooms[i].id === id) {
      }
    }
    this.roomsChanged.next(this.rooms.slice());
    this.getRooms();
  }

  editSetRoom(room) {
    this.room = room;

    return this.room;
  }

  editGetRoom() {
    return this.room;
  }

  roomList(rooms) {
    this.rooms = rooms;
    this.roomsList = [];
    for (this.room of this.rooms) {
      // this.roomsList.push(this.room.name + ':' + this.room.maxCapacity + ':' + this.room.id + ':' + this.room.autoAccessEnabled + ':' + this.room.hasFreeAccess + ':' + this.room.restrictAccess);
      this.roomsList.push({name: this.room.name, maxCapacity: this.room.maxCapacity, id: this.room.id,
        autoAccessEnabled: this.room.autoAccessEnabled, hasFreeAccess: this.room.hasFreeAccess, restrictAccess: this.room.restrictAccess});
    }

    return this.roomsList;
  }
}
