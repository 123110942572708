import { Component, Input } from '@angular/core';

/**
 * BeeUnderConstruction, used to mock an unfinished feature.
 *
 * @example
 *
 * <bee-under-construction></bee-under-construction>
 */
@Component({
  selector: 'bee-under-construction',
  templateUrl: './bee-under-construction.component.html',
  styleUrls: ['./bee-under-construction.component.scss'],
})
export class BeeUnderConstructionComponent {
  /**
   * The description of the under construction page.
   * @type {string}
   */
  @Input() text = 'Página en construcción';
  /**
   * The image url of the under construction page. (Not used)
   * @type {string}
   */
  @Input() imageUrl: string;
}
