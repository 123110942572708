import { Component, Input } from '@angular/core';

/**
 * BeeAlertComponent, used for alerts.
 *
 * @example
 *
 * <bee-alert type="error"></bee-alert>
 */
@Component({
  selector: 'bee-alert',
  templateUrl: './bee-alert.component.html',
  styleUrls: ['./bee-alert.component.scss'],

})
export class BeeAlertComponent {
  /**
   * Type of the alert
   * Options: info, success, warn, error
   * @type {string}
   */
  @Input() type = 'info';

  /**
   * Controls if the alert is closed
   * @type {boolean}
   */
  hidden = false;

  /**
   * Closes the alert
   */
  closeAlert() {
    this.hidden = true;
  }
}
