import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { BeeBreadcrumbComponent } from './bee-breadcrumb.component';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
  declarations: [
    BeeBreadcrumbComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        FlexLayoutModule
    ],
  exports: [
    BeeBreadcrumbComponent
  ]
})
export class BeeBreadcrumbModule { }
