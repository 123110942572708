import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

/**
 * BeeDialogNotification, the alternative to the material snackbar.
 *
 * @example
 *
 * matDialog.open(BeeDialogNotificationComponent, {
 *  width: '320px',
 *  data: {
 *    icon: 'send',
 *    message: '¡Pedido enviado!',
 *    note: 'Gracias por enviar tu pedido.',
 *    messageAtTop: true,
 *    extraIcon: 'accepted'
 *  }
 * });
 */
@Component({
  selector: 'bee-dialog-notification',
  templateUrl: './bee-dialog-notification.component.html',
  styleUrls: ['./bee-dialog-notification.component.scss']
})
export class BeeDialogNotificationComponent {
  /**
   * Close text. (i18n)
   * @type {string}
   */
  closeText = 'Salir';
  /**
   * Message at top.
   * @type {boolean}
   */
  messageAtTop = false;
  /**
   * Icon styles.
   * @type {boolean}
   */
  iconStyles = {
    transform: 'scale(3.3)',
    color: '#9FE1CD'
  };

  /**
   * Constructor.
   * @param dialogRef
   * @param data The dialog injected data.
   */
  constructor(private dialogRef: MatDialogRef<BeeDialogNotificationComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.hasOwnProperty('closeText')) {
      this.closeText = data.closeText;
    }
    if (data.hasOwnProperty('messageAtTop')) {
      this.messageAtTop = data.messageAtTop;
    }
    if (data.hasOwnProperty('iconStyles')) {
      this.iconStyles = data.iconStyles;
    }
  }

  /**
   * Closes the dialog.
   */
  closeDialog(): void {
    this.dialogRef.close();
  }
}
