<div class="general-container" [ngClass]="{'standard': appearance === 'standard'}">
  <div *ngIf="label" class="label-wrapper">
    <label
        [ngClass]="{'selector-error': beeFormControl.invalid && beeFormControl.dirty, 'background-label': backgroundLabel, 'disabled': isDisabled}">
      {{ label }}
      <ng-container *ngIf="requiredSign && isRequired"> *</ng-container>
    </label>
  </div>
  <div [id]="id" class="bee-selector">
    <div #selector class="bee-selector-container"
         (click)="onSelectorClick()"
         (keydown)="onSelectorKeyDown($event)"
         (window:click)="onWindowClick()"
         (window:resize)="onWindowResize()"
         [attr.tabindex]="isDisabled ? null : 0"
         [ngClass]="{'open': show, 'disabled': isDisabled, 'error-border': beeFormControl.invalid && beeFormControl.dirty, 'with-icon': icon}">
      <div class="single">
        <div class="value" *ngIf="selectedOption">
          {{ selectedOption.label }}
        </div>
        <div *ngIf="!selectedOption" class="placeholder">
          {{ placeholder }}
        </div>
        <div class="clear"
             *ngIf="allowClear && selectedOption && !isDisabled"
             (click)="clearSelection(false); $event.stopPropagation()">
          &#x2715;
        </div>
        <div class="toggle"
             *ngIf="show && !isDisabled">
          <mat-icon svgIcon="menu-up"></mat-icon>
        </div>
        <div class="toggle"
             *ngIf="!show && !isDisabled">
          <mat-icon svgIcon="menu-down"></mat-icon>
        </div>
      </div>
    </div>
    <mat-icon *ngIf="icon" svgIcon={{icon}}></mat-icon>
  </div>

  <div (click)="onOptionsContainerClick()"
       class="bee-dropdown" [ngStyle]="{'width.px': width}" [ngClass]="{'open': show, 'inside-modal': insideModal}"
       [@showSelectorState]="show ? 'open' : 'close'">
    <ng-template [ngIf]="show">
      <ng-container *ngIf="isSetAbilityToSelect">
        <mat-spinner *ngIf="creating" [diameter]="50"></mat-spinner>

        <div *ngIf="!creating" class="filter">
          <mat-form-field>
            <input matInput placeholder="{{searchPlaceholder}}" (input)="searchSubject.next(filterInput.value)"
                   (keydown)="onSearchKeyDown($event)" #filterInput id="filter-input" name="search"
                   autocomplete="off" [value]="currentQuery">
            <button *ngIf="filterInput.value != ''" mat-button matSuffix mat-icon-button aria-label="Limpiar"
                    type="button" class="clear-input-button"
                    (click)="filterInput.value=''; search('')">
              <mat-icon svgIcon="close"></mat-icon>
            </button>
          </mat-form-field>

          <button class="insert-button button-primary-border"
                  *ngIf="searchText && allowCreation"
                  (click)="insertElement($event)"
                  mat-button
                  color="primary">
            <mat-icon svgIcon="plus"></mat-icon>
            <span>{{createButtonLabel}}</span>
          </button>
        </div>

        <mat-spinner *ngIf="loadingData && selectorValues.length == 0" [diameter]="50"></mat-spinner>

        <div *ngIf="!loadingData || selectorValues.length > 0" class="options" #optionsList
             [ngClass]="{'options-error': error}">
          <ul>
            <ng-container *ngFor="let item of selectorValues">
              <li *ngIf="!hiddenOptions.includes(item.value)"
                  class="selector-option"
                  [ngClass]="{'highlighted': item.highlighted, 'selected': item.selected, 'disabled': item.disabled}"
                  (click)="onOptionClick(item)"
                  (mouseover)="onOptionMouseOver(item)">
                <img *ngIf="item.icon" src="{{item.icon}}" alt="icon">
                <span> {{ item.label }}</span>
              </li>
            </ng-container>

            <li *ngIf="selectorValues.length == 0 && !error" class="message">
              {{ noResultsText }}
            </li>

            <li *ngIf="error" class="message">
              <div class="valign-wrapper">
                <img class="margin-right" src="app/shared/assets/icons/high-priority.svg" alt="error">
                <span class="red-text">{{ error }}</span>
              </div>
            </li>
          </ul>
        </div>
        <mat-paginator *ngIf="!error && length > selectorValues.length"
                       [length]="length"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="pageSizeOptions"
                       [showFirstLastButtons]="true"
                       (page)="pageEvent($event)">
        </mat-paginator>
      </ng-container>

      <div *ngIf="!isSetAbilityToSelect" class="no-select-ability-container">
        <img src="app/shared/assets/images/forbidden.png" alt="No hay permiso">
        <span>No tienes permisos para realizar esta acción</span>
      </div>
    </ng-template>
  </div>

  <div *ngIf="beeFormControl.invalid && beeFormControl.dirty" class="selector-error">
    <span>{{ requiredText }}</span>
  </div>
</div>
