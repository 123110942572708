import { Injectable } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';

/**
 * SnackbarService, helper to create material snackbars.
 */
@Injectable()
export class SnackBarService {
  /**
   * Constructor.
   * @param snackBar
   */
  constructor(private snackBar: MatSnackBar) {
  }

  /**
   * Opens a snackbar.
   * @param message
   * @param action
   */
  openSnackBar(message: string, action: string = 'Cerrar') {
    this.snackBar.open(message, action, {
      duration: 2000,
      panelClass: 'snackbar'
    });
  }
  openActionSnackBar(customComponent) {
    this.snackBar.openFromComponent(customComponent);
  }
}
