<div class="close-container">
    <span class="close-box">
        <mat-icon svgIcon="close" (click)="closeDialog()"></mat-icon>
    </span>
</div>

<mat-dialog-content>
  <h1 mat-dialog-title>{{title}}</h1>

  <button mat-icon-button type="button"
          matTooltip="Rotar a la izquierda" matTooltipPosition="below"
          (click)="rotateLeft()">
    <mat-icon [svgIcon]="'rotate-left'"></mat-icon>
  </button>
  <button mat-icon-button type="button"
          matTooltip="Rotar a la derecha" matTooltipPosition="below"
          (click)="rotateRight()">
    <mat-icon [svgIcon]="'rotate-right'"></mat-icon>
  </button>
  <!--
  <mat-form-field appearance="outline">
    <mat-label>Rotación</mat-label>
    <input matInput type="number" [(ngModel)]="rotation" placeholder="Rotación" (input)="updateRotation()"
           (keyup)="updateRotation()">
  </mat-form-field>
  -->
  <button mat-icon-button type="button"
          matTooltip="Voltear horizontal" matTooltipPosition="below"
          (click)="flipHorizontal()">
    <mat-icon [svgIcon]="'flip-horizontal'"></mat-icon>
  </button>
  <button mat-icon-button type="button"
          matTooltip="Voltear vertical" matTooltipPosition="below"
          (click)="flipVertical()">
    <mat-icon [svgIcon]="'flip-vertical'"></mat-icon>
  </button>

  <button mat-icon-button type="button"
          matTooltip="Zoom -" matTooltipPosition="below"
          (click)="zoomOut()">
    <mat-icon [svgIcon]="'magnify-minus'"></mat-icon>
  </button>
  <button mat-icon-button type="button"
          matTooltip="Zoom +" matTooltipPosition="below"
          (click)="zoomIn()">
    <mat-icon [svgIcon]="'magnify-plus'"></mat-icon>
  </button>

  <button mat-icon-button type="button"
          matTooltip="Contener en relación de aspecto" matTooltipPosition="below"
          (click)="toggleContainWithinAspectRatio()">
    <mat-icon [svgIcon]="'aspect-ratio'"></mat-icon>
  </button>
  <button mat-icon-button type="button"
          matTooltip="Reset" matTooltipPosition="below"
          (click)="resetImage()">
    <mat-icon [svgIcon]="'autorenew'"></mat-icon>
  </button>

  <div id="image-cropper-container">
    <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [containWithinAspectRatio]="containWithinAspectRatio"
        [maintainAspectRatio]="maintainAspectRatio"
        [aspectRatio]="aspectRatio"
        [onlyScaleDown]="true"
        [roundCropper]="roundCropper"
        [canvasRotation]="canvasRotation"
        [transform]="transform"
        [alignImage]="'center'"
        [style.display]="showCropper ? null : 'none'"
        [format]="format"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady($event)"
        (loadImageFailed)="loadImageFailed()">
    </image-cropper>
  </div>

  <h2 class="subtitle">{{previewText}}</h2>
  <div id="image-preview-container">
    <img *ngIf="croppedImage" alt="cropped-image"
         [src]="croppedImage"
         [style.border]="croppedImage ? '1px solid black' : 'none'"
         [style.border-radius]="roundCropper ? '100%' : 'none'"/>
  </div>
  <span id="max-file-size-tip" *ngIf="croppedFile?.size > maxFileSize">{{maxFileSizeTip}}</span>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button type="button" mat-raised-button color="primary"
          [disabled]="croppedFile?.size > maxFileSize"
          (click)="confirmFile()">Aceptar</button>
</mat-dialog-actions>
