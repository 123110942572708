import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-date',
  templateUrl: './dialog-date.component.html',
  styleUrls: ['./dialog-date.component.scss']
})
export class DialogDateComponent implements OnInit {
  form: FormGroup;
  startingDate: string;
  endingDate: string;
  @Input() TimeFilter: boolean ;
  error: string;
  constructor(private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<DialogDateComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
    if ((data.startingDate !== undefined) && (data.endingDate !== undefined) &&
      (data.startingDate !== '') && (data.endingDate !== '') &&
      (!data.startingDate.includes('T') && !data.endingDate.includes('T'))) {
      this.startingDate = data.startingDate.split(' ')[0] + 'T' +
        data.startingDate.split(' ')[1].split(':')[0] + ':' + data.startingDate.split(' ')[1].split(':')[1];
      this.endingDate = data.endingDate.split(' ')[0] + 'T' +
        data.endingDate.split(' ')[1].split(':')[0] + ':' + data.endingDate.split(' ')[1].split(':')[1];
    } else {
      this.startingDate = data.startingDate;
      this.endingDate = data.endingDate;
    }
    this.form = formBuilder.group({
      startingDate: [this.startingDate, Validators.required],
      endingDate: [this.endingDate],
    });
  }

  ngOnInit() {
  }

  save() {
    if (this.form.value['endingDate'] === null) {
      this.dialogRef.close(this.form.value);
    } else {
      if (this.form.value.startingDate < this.form.value.endingDate) {
        this.dialogRef.close(this.form.value);
      } else {
        this.error = 'La fecha de fin debe ser posterior a la de inicio';
      }
    }
  }

  close() {
    this.dialogRef.close();
  }
}
