import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '@shared/angular-material';

import { BeePreloaderModule } from '@bee-components/common/bee-preloader/bee-preloader.module';

import { BeeWizardHelpComponent } from './bee-wizard-help.component';

@NgModule({
  declarations: [
    BeeWizardHelpComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    BeePreloaderModule,
    MaterialModule
  ],
  exports: [
    BeeWizardHelpComponent
  ]
})
export class BeeWizardHelpModule { }
