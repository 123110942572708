<mat-dialog-content [formGroup]="form" fxLayout="column" fxLayoutAlign="start center">
  <mat-form-field>
    <input matInput
           type="datetime-local"
           placeholder="Fecha de Inicio"
           formControlName="startingDate">
  </mat-form-field>
  <ng-container *ngIf="TimeFilter == true">
  <mat-form-field>
    <input matInput
           type="datetime-local"
           placeholder="Fecha de Fin"
           formControlName="endingDate">
  </mat-form-field>
</ng-container>
  <div fxFlex="100" class="left-align warn-color" id="error-line" style="padding-bottom: 15px">
    <ng-container *ngIf="error">{{ error }}</ng-container>
  </div>
</mat-dialog-content>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10">
  <button class="cancel primary-color"
          mat-button
          (click)="close()">Cancelar</button>
  <button class="ok"
          [class.disabled]="!form.valid"
          mat-button
          (click)="save()">OK</button>
</div>
