import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Input, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute,Router } from '@angular/router';

import { DashboardService } from '@app/dashboard/dashboard.service';
import { DialogDateComponent } from '@app/dashboard/dialog-date/dialog-date.component';
import { ProfitsService } from '@app/profits/profits.service';
import { UsersService } from '@app/users/users.service';
import { ShoppingCartComponent } from '@app/shopping-cart/shopping-cart.component';

import { ApiService, SnackBarService } from '@shared/services';
import { DataStorageService } from '@shared/services/data-storage.service';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss'],
  providers: [DatePipe]
})
export class NotificationDialogComponent implements OnInit {

  @Input() template: TemplateRef<any>;
  myForm: FormGroup;
  NotifyForm: FormGroup;
  onChangeTab: MatTabChangeEvent;
  myDate: string;
  public message = 'Enviar Ahora';
  private notify: any[] = [];
  public user: any[];
  public eventInfo: any;
  public usesList: any[];
  public emailList: any[];
  public infotab = 'Mail'; // Mail  por defecto
  public roomList = [];
  public pushLeft: any;
  public mailLeft: any;
  public roomName: string = null;
  public users = [];
  public tokens = [];
  private TokenPass = [];
  public mails = [];
  public userName: string;
  private click_action: string;
  private bodyText: string;
  public subject: string;
  public roomsAccess: any[];
  public accessEdit: boolean;
  public hasAccess: boolean;
  public hasVouchers: boolean;
  public userId: string;
  public roomId: string = null;
  public voucherId: string;
  private delemKey:string;

  public startingDate: string = null;
  public dateToSend: string = null;
  public userClientId: string;
  constructor(protected dialogRef: MatDialogRef<NotificationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              protected apiService: ApiService,
              protected activatedRoute: ActivatedRoute,
              protected changeDetectorRef: ChangeDetectorRef,
              private formBuilder: FormBuilder,
              private UserService: UsersService,
              public profitsService: ProfitsService,
              public dashboardService: DashboardService,
              private datePipe: DatePipe,
              private dialog: MatDialog,
              private router: Router,
              private snackBarService: SnackBarService,
              private dataStorageService: DataStorageService) {

      this.myDate = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
      this.eventInfo = this.UserService.getEventInfo();
      this.myForm = formBuilder.group({
        subject: [this.subject, Validators.required],
        bodytext: [this.bodyText, Validators.required],
        tokens: [this.tokens],
        mails: [this.mails],
        action: [this.click_action],
        timeToSend: [this.dateToSend],
        name:[this.data.el.name]
      });

    }

  ngOnInit(): void {
    this.users = this.data.users;
    if (!this.eventInfo[0]) {
      this.dataStorageService.fetchEvent2().subscribe(
        data => {
          
          if(this.data[0]['Credentials']){
            this.delemKey  = JSON.parse(this.data[0]['Credentials'])['delemapikey'];
          }              
          this.pushLeft = data[0]['pushLeft'];
          this.mailLeft = data[0]['emailLeft'];
          this.NotifyForm = this.formBuilder.group({
            pushLeft: [this.pushLeft],
            mailLeft: [this.mailLeft],
          });
        },
        err => {
          console.error(err);
        });
    } else {
      if(this.eventInfo[0]['Credentials']){
        this.delemKey  = JSON.parse(this.eventInfo[0]['Credentials'])['delemapikey'];
      }      
      this.pushLeft = this.eventInfo[0]['pushLeft'];
      this.mailLeft = this.eventInfo[0]['emailLeft'];
    }
  }

  sendNotificationUser(notify) {
    if (this.pushLeft) {
      this.notify = notify;
      this.dataStorageService.userPushNotification(this.notify);
      this.snackBarService.openSnackBar('Notificiones enviadas');

    } else {
      this.snackBarService.openSnackBar('No le quedan notificiones restantes');
    }
  }
  sendMailDelem(notify) {
      this.notify = notify;
      // this.dataStorageService.userPushNotification(this.notify);
      this.snackBarService.openSnackBar('Notificiones Delem enviadas');
  }
   sendMailUser(notify) {
    if (this.mailLeft) {
      this.notify = notify;
      this.dataStorageService.userMailNotification(this.notify);
      this.snackBarService.openSnackBar('Notificiones enviadas');

    } else {
      this.snackBarService.openSnackBar('No le quedan notificiones Mail restantes');
    }
  }
  sendMailPushNotificationUser(notify) {
    if (this.pushLeft && this.mailLeft) {
      this.notify = notify;
      // this.dataStorageService.userPushMailNotification(this.notify);
      this.dataStorageService.userMailNotification(this.notify);
      this.dataStorageService.userPushNotification(this.notify);
      this.snackBarService.openSnackBar('Notificiones enviadas');

    } else {
      this.snackBarService.openSnackBar('No le quedan notificiones restantes');
    }

  }

  showNotificationInfo() {
    this.mailLeft = this.dataStorageService.mailLeft;
    // let errorNotify = this.dataStorageService.noNotify
    if (this.mailLeft['error']) {
      this.snackBarService.openSnackBar(this.mailLeft['error']);
    } else {
      this.snackBarService.openSnackBar('Enviado');
    }
  }

  postSubmit(type) {
    if (this.startingDate) {
      this.myForm.value.timeToSend = this.dateToSend;
    }
    if (type === 'Push') {
      this.myForm.value.tokens = this.TokenPass;
      this.sendNotificationUser( this.myForm.value);

    } else if (type === 'Mail') {
      this.myForm.value.mails = this.myForm.value.mails.filter(e => e !== null);
      if(this.delemKey){
        this.sendMailDelem(this.myForm.value);

      }else{
        this.sendMailUser(this.myForm.value);
      }

    } else if (type === 'Push & Mail') {
      this.myForm.value.tokens = this.TokenPass;
      // this.myForm.value.mails=this.myForm.value.mails.filter(e => e!== null);
      this.sendMailPushNotificationUser( this.myForm.value);
    } 
    this.closeDialog();
  }
  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.infotab = tabChangeEvent.tab.textLabel;
  }

  programNotify() {
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    dialogConfig.maxWidth = '95vw';
    dialogConfig.data = {
      id: 1,
      title: 'Time Filter Personalized'
    };
    const dialogRef = this.dialog.open(DialogDateComponent, dialogConfig);
    const instance = dialogRef.componentInstance;
    instance.TimeFilter = false;
    dialogRef.afterClosed().subscribe(result => {
      this.startingDate = result.startingDate;
      if (this.startingDate) {
        this.dateToSend = this.datePipe.transform(this.startingDate, 'yyyy-MM-dd H:mm');
       // this.dateToSend = this.startingDate.split('T')[0] + ' ' + result.startingDate.split('T')[1] + ':00';
       // this.myForm.value.timeToSend = this.dateToSend;
        this.snackBarService.openSnackBar('Notificaciones Programadas.');
        this.message = 'Enviar';
      }
    });
  }

  openNotificationCartForm() {
    this.closeDialog();
    this.router.navigate(['/recharge']);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
