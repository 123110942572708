<bee-preloader *ngIf="!loaded"></bee-preloader>

<div *ngIf="loaded" fxLayout="row wrap">
  <div class="notifications-header" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="back-box">
      <div class="back-container" (click)="onBack()">
        <mat-icon svgIcon="chevron-left"></mat-icon>
        Volver
      </div>
    </div>
    <div class="form-title-box">
      <div class="form-title primary-color">
        <span>Notificaciones</span>
      </div>
    </div>
    <div class="tip-box">
      <button *ngIf="canCreate" mat-button color="primary" class="button-primary-border" (click)="createNotification()">
        <mat-icon svgIcon="plus"></mat-icon>
        <span>CREAR NOTIFICACIÓN</span>
      </button>
    </div>
  </div>

  <div *ngIf="initialNotificationsCount == 0" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center"
       class="no-results-container">
    <div class="no-results-icon-container" fxLayout="row" fxLayoutAlign="center center">
      <mat-icon [svgIcon]="'bell-off'"></mat-icon>
    </div>
    <div class="no-results-title" fxFlex="100">
      No hay notificaciones
    </div>
  </div>

  <ng-container *ngIf="initialNotificationsCount > 0">
    <div fxFlex="25" fxFlex.lt-md="50" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="16px"
         fxLayoutGap.xs="10px"
         class="search-container">
      <mat-icon (click)="onActivateSearch()" svgIcon="magnify"
                class="search-icon" [ngClass]="{'active': searchActive}">
      </mat-icon>
      <mat-form-field>
        <input matInput (input)="searchSubject.next(search.value)" value="{{currentQuery}}"
               [placeholder]="'Buscar'" #search id="search" name="search" autocomplete="off"
               (focus)="searchActive = true" (focusout)="searchActive = false">
        <button *ngIf="search.value!= ''" mat-button matSuffix mat-icon-button
                type="button"
                (click)="search.value=''; onEmptySearch()">
          <mat-icon svgIcon="close"></mat-icon>
        </button>
      </mat-form-field>
    </div>

    <mat-card class="notifications-card" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start">
      <div class="notifications-table-header-container" fxFlex="100" fxLayout="row">

        <div class="notifications-table-header" fxFlex="70" fxLayout="row" fxLayoutAlign="start center"
             (click)="onSort('title')">
        <span *ngIf="orderBy == 'title' && sortOrder === 'desc'"><i
            class="mdi mdi-arrow-down sort-icon primary-color"></i></span>
          <span *ngIf="orderBy == 'title' && sortOrder === 'asc'"><i
              class="mdi mdi-arrow-up sort-icon primary-color"></i></span>
          <span [ngClass]="{'primary-color': orderBy === 'title'}">Asunto</span>

        </div>
        <div class="notifications-table-header" fxFlex="30" fxLayout="row" fxLayoutAlign="start center"
             (click)="onSort('createdAt')">
        <span *ngIf="orderBy == 'createdAt' && sortOrder === 'desc'"><i
            class="mdi mdi-arrow-down sort-icon primary-color"></i></span>
          <span *ngIf="orderBy == 'createdAt' && sortOrder === 'asc'"><i
              class="mdi mdi-arrow-up sort-icon primary-color"></i></span>
          <span [ngClass]="{'primary-color': orderBy === 'createdAt'}">Fecha</span>
        </div>
      </div>

      <ng-container *ngIf="notifications.length > 0">
        <div *ngFor="let notification of notifications"
             (click)="beeNotificationsService.openNotification(notification, notificationDetailsData)" fxFlex="100"
             fxLayout="row" class="notification-container divider-color">
          <div class="notification-content-section-1" fxFlex="70" fxLayout="row wrap">
            <div fxFlex="100" fxLayout="row" fxLayoutAlign="start center" class="notification-title">
              <mat-icon [svgIcon]="'bell-ring'" *ngIf="!notification.isRead"></mat-icon>
              <span [ngClass]="{'title-padding': notification.isRead}">{{ notification.title }}</span>
            </div>
            <div fxFlex="100" class="notification-content">{{ notification.content }}</div>
          </div>
          <div class="notification-content-section-2" fxFlex="30" fxLayout="row wrap">
            <div fxFlex="100" class="notification-date">{{ notification.createdAt | beeDate }}
            </div>
            <span class="show-tip">Ver</span>
          </div>
        </div>
      </ng-container>

      <div *ngIf="notifications.length === 0" class="no-results-tip foreground-secondary-text-color" fxFlex="100"
           fxLayout="row" fxLayoutAlign="center center">
        No hay resultados
      </div>

      <mat-paginator fxFlex="100" [length]="length"
                     [pageSize]="pageSize"
                     [pageSizeOptions]="[5, 10, 25]"
                     [showFirstLastButtons]="true"
                     (page)="pageEvent($event)">
      </mat-paginator>
    </mat-card>
  </ng-container>
</div>
