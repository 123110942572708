import { dashCaseToCamelCase } from '@angular/compiler/src/util';
import { Injectable } from '@angular/core';

import { Product } from '@shared/models/product';

@Injectable()
export class DashboardService {
  private nameProd = '';
  private price = '';
  public ticketCount: number;
  public ticketsbyQr:any;
  private infoprod: { name: any; quantity: any; waiter: any };
  private prod: any[] = [];
  private waiters: any[] = [];
  private infowaiter: {name: any; rol: any; recharges: any; sells: any; rechargesVouchers: any; sellsVouchers: any };
  private prodMV: any = [{
    nameProd: '',
    quantity: '',
    price: ''
  },
    {
      nameProd: '',
      quantity: '',
      price: ''
    },
    {
      nameProd: '',
      quantity: '',
      price: ''
    }
  ];

  private catMV: any = [{
    nameCat: '',
    quantity: '',
    price: ''
  }
  ];

  private vouMV: any = [{
    nameVou: '',
    quantity: '',
    price: ''
  },
    {
      nameVou: '',
      quantity: '',
      price: ''
    },
    {
      nameVou: '',
      quantity: '',
      price: ''
    }
  ];

  private bookMV: any = [{
    nameBook: '',
    quantity: ''
  },
    {
      nameBook: '',
      quantity: ''
    },
    {
      nameBook: '',
      quantity: ''
    }
  ];
  private categoryBookMV: any = [{
    nameBook: '',
    quantity: '',
    price: ''
  }
  ];
  private prodName = [];
  private prodQuantity = [];
  private prodPrice = [];
  private catName = [];
  private catQuantity = [];
  private catPrice = [];
  private vouName = [];
  private vouQuantity = [];
  private vouPrice = [];
  private bookName = [];
  private bookQuantity = [];
  private bookPrice = [];
  private categoryBookName = [];
  private categoryBookQuantity = [];
  private categoryBookPrice = [];
  private cad: any;
  private cad2: any;
  private dataGain = [];
  private horaGain = [];
  private dataSale = [];
  private horaSale = [];

  private products: Product[] = [];
  private categories: any[] = [];
  private workers: any[] = [];
  private rooms: any[] = [];
  private activities: any[] = [];
  public movesRooms: any[] = [];
  public movesRoomsId: any;
  public moves: any[] = [];
  public movesId: any;
  public movesIdCopy: any;
  public l: any;
  public workersId: any;
  private data: any;
  private sumDifEuros: any;
  private sumDifVoucher: any;
  private sumTotal: any;
  private sumTotalVoucher: any;
  private roomName: any;
  public waiterName: any[];
  public waiterRecharges: any[];
  public waiterSells: any[];
  public waiterRol: any[];
  public waiterVouchersRecharges: any[];
  public waiterVouchersSells: any[];
  public waiter: any[];

  setDataAccount(data) {
    this.data = data;

    return this.data;
  }

  getDataAccount() {
    return this.data;
  }

  setProducts(products: Product[]) {
    this.products = products;

    return this.products;
  }

  getProducts() {
    return this.products.slice();
  }

  setCategories(categories) {
    this.categories = categories;

    return this.categories;
  }

  getCategories() {
    return this.categories.slice();
  }

  setWorkers(workers) {
    this.workers = workers;
    this.workers = this.transformWorkers(this.workers);

    return this.workers;
  }

  getWorkers() {
    return this.workers.slice();
  }

  transformWorkers(workers) {
    this.workers = workers;
    this.workersId = Object.keys(this.workers);
    const workers2 = [];
    this.l = this.workersId.length;
    for (let i = 0; i < this.l; i++) {
      workers2[i] = this.workers[this.workersId.shift()];
    }
    this.workers = workers2;

    return this.workers;
  }

  setRooms(rooms) {
    this.rooms = rooms;

    return this.rooms;
  }

  getRooms() {
    return this.rooms.slice();
  }

  setActivities(activities) {
    this.activities = activities;

  }

  getActivities() {
    return this.activities.slice();
  }

  setMoves(moves, sumDifEuros, sumDifVoucher, sumTotal,  sumTotalVoucher) {
    this.moves = moves;
    //this.moves = this.transformMoves(this.moves);
    this.sumDifEuros = sumDifEuros;
    this.sumDifVoucher = sumDifVoucher;
    this.sumTotal = sumTotal;
    this.sumTotalVoucher = sumTotalVoucher;
  }

  getMoves() {
    return [this.moves.slice(), this.sumDifEuros, this.sumDifVoucher, this.sumTotal, this.sumTotalVoucher];
  }

  transformMoves(moves) {
    this.moves = moves;
    this.movesId = Object.keys(this.moves);
    const moves2 = [];
    this.l = this.movesId.length;
    for (let i = 0; i < this.l; i++) {
      moves2[i] = this.moves[this.movesId.shift()];
    }
    this.moves = moves2;

    return this.moves;
  }

  transformMovesAccount(moves) {
    this.moves = moves;
    const movesId = Object.keys(this.moves);
    const moves2 = [];
    const moves3 = [];
    this.l = movesId.length;
    for (let i = 0; i < this.l; i++) {
      moves2[i] = this.moves[movesId.shift()];
      const movesId2 = Object.keys(moves2[i].data2);
      const ll = movesId2.length;
      for (let ii = 0; ii < ll; ii++) {
        moves3.push(moves2[i].data2[movesId2.shift()]);
      }
    }
    this.moves = moves3;

    return this.moves;
  }

  setMovesRooms(movesRooms) {
    this.movesRooms = movesRooms;
    this.movesRooms = this.transformMovesRooms(this.movesRooms);
  }

  getMovesRooms() {
    return this.movesRooms.slice();
  }

  transformMovesRooms(movesRooms) {
    this.movesRooms = movesRooms;
    this.movesRoomsId = Object.keys(this.movesRooms);
    const movesRooms2 = [];
    this.l = this.movesRoomsId.length;
    for (let i = 0; i < this.l; i++) {
      movesRooms2[i] = this.movesRooms[this.movesRoomsId.shift()];
    }
    this.movesRooms = movesRooms2;

    return this.movesRooms;
  }

  addSumGains(moves) {
    let sumGainT;
    let sumGainE = 0;
    let sumSaleE = 0;
    let sumGainV = 0;
    let sumSaleV = 0;
    let sumRec = 0;

    for (const move of moves) {
      if (move.creditMove.amount.includes('-')) {
        sumSaleE += parseFloat(move.creditMove.amount.split('-')[1]);
      } else {
        sumGainE += parseFloat(move.creditMove.amount);
      }
      if (move.creditMove.uses < 0) {
        sumSaleV += Math.abs(move.creditMove.uses);
      } else if (move.creditMove.uses > 0) {
        if (move.voucherId !== null) {
          sumGainV += parseFloat(move.voucher[0].price);
          sumRec += parseFloat(move.creditMove.uses);
        }
      }
    }
    sumGainT = sumGainE + sumGainV;

    return [sumGainT, sumGainE, sumSaleE, sumGainV, sumSaleV, sumRec];
  }

  addSumIn(moves, roomFilterSelected) {
    let sumIn = 0;
    let sumOut = 0;
    const wristbands = [];
    if (roomFilterSelected !== 'Todos') {
      moves = moves.filter(move => {
        if (move.name === roomFilterSelected) {
          const aux = move;
          move = [];
          move[0] = aux;

          return true;
        }
      });
    }
    for (const move of moves) {
      if (move.action === 'in') {
        sumIn += 1;
      } else {
        sumOut += 1;
      }
      if (wristbands.indexOf(move.wristbandId) === -1) {
        wristbands.push(move.wristbandId);
      }
    }

    return [sumIn, sumOut, wristbands.length];
  }

  addStock(moves) {
    this.prodName = [];
    this.prodQuantity = [];
    this.prod = [];
    this.waiter = [];
    for (const move of moves) {
      if (move.saleOrderLines !== null && ((move.creditMove.amount.includes('-')) || (move.creditMove.uses.includes('-')))) {
        for (const dim of move.saleOrderLines) {
        // for (let dim = 0; dim < move.saleOrderLines.length; dim++) {
          // this.hasMatch = false;
          // this.hasMatch2 = false;
          if (this.prodName.indexOf(dim.name) === -1) {
            this.prodName.push(dim.name);
            this.prodQuantity.push(dim.quantity.split('.', 1)[0]);
            this.prodPrice.push(String(parseFloat(dim.unitPrice) * parseInt(dim.quantity.split('.', 1)[0])));
            if (move.worker !== null) {
              this.waiter.push(move.worker[0].name);
            }
          } else {
            this.prodQuantity[this.prodName.indexOf(dim.name)] = String(parseInt(this.prodQuantity[this.prodName.indexOf(dim.name)]) + parseInt(dim.quantity.split('.', 1)[0]));
            this.prodPrice[this.prodName.indexOf(dim.name)] = String(parseFloat(this.prodPrice[this.prodName.indexOf(dim.name)]) + (parseFloat(dim.unitPrice) * parseInt(dim.quantity.split('.', 1)[0])));
          }
        }
      }
    }
    for (let _i = 0; _i < this.prodName.length; _i++) {
      this.infoprod = {name: this.prodName[_i], quantity: this.prodQuantity[_i], waiter: this.waiter[_i]};
      this.prod.push(this.infoprod);
    }

    return this.prod;
  }


  addBookingBestSell(moves) {
    this.bookName = [];
    this.bookQuantity = [];
    this.bookPrice = [];
    for (const move of moves) {
      if (move.booking !== null) {
        for (let dim = 0; dim < move.booking.length; dim++) {
          if (this.bookName.indexOf(move.booking[dim].name) === -1) {
            this.bookName.push(move.booking[dim].name);
            this.bookQuantity.push(String(parseInt(move.booking[dim].units)));
            this.bookPrice.push(String(parseFloat(move.booking[dim].amount)));
          } else {
            this.bookQuantity[this.bookName.indexOf(move.booking[dim].name)] = String(parseInt(this.bookQuantity[this.bookName.indexOf(move.booking[dim].name)]) + (parseFloat(move.booking[dim].units)));
            this.bookPrice[this.bookName.indexOf(move.booking[dim].name)] = String(parseFloat(this.bookPrice[this.bookName.indexOf(move.booking[dim].name)]) + (parseFloat(move.booking[dim].amount)));
          }
        }
      }
    }

    const catBookMV = this.addCategoriesBookingsBestSell(this.bookName, this.bookQuantity, this.bookPrice);

    let count = 0;
    let ii = 0;
    while (count < 3) {
      let max = parseInt(this.bookQuantity[0]);
      let maxIndex = 0;

      for (let i = 1; i < this.bookQuantity.length; i++) {
        if (parseInt((this.bookQuantity)[i]) > max) {
          maxIndex = i;
          max = parseInt((this.bookQuantity)[i]);
        }
      }
      this.bookMV[count].nameBook = this.bookName[maxIndex];
      this.bookMV[count].quantity = this.bookQuantity[maxIndex];
      this.bookMV[count].price = this.bookPrice[maxIndex];
      ii = this.bookQuantity.indexOf(String(max));
      if (ii >= 0) {
        this.bookName.splice(ii, 1);
        this.bookQuantity.splice(ii, 1);
        this.bookPrice.splice(ii, 1);
      }
      count += 1;
    }

    return [this.bookMV, catBookMV];
  }

  addCategoriesBookingsBestSell(booksName, booksQuantity, booksPrice) {
    this.categoryBookName = [];
    this.categoryBookQuantity = [];
    this.categoryBookPrice = [];
    for (const [i, book] of booksName.entries()) {
      if (/\d/.test(book)) {
        const bookWithoutNumber = book.replace((book).match(/\d+/g), '');
        if (this.categoryBookName.indexOf(bookWithoutNumber) === -1) {
          this.categoryBookName.push(bookWithoutNumber);
          this.categoryBookQuantity.push(String(parseInt(booksQuantity[i])));
          this.categoryBookPrice.push(String(parseFloat(booksPrice[i])));
        } else {
          this.categoryBookQuantity[this.categoryBookName.indexOf(bookWithoutNumber)] = String(parseInt(this.categoryBookQuantity[this.categoryBookName.indexOf(bookWithoutNumber)]) + parseInt(booksQuantity[i]));
          this.categoryBookPrice[this.categoryBookName.indexOf(bookWithoutNumber)] = String(parseFloat(this.categoryBookPrice[this.categoryBookName.indexOf(bookWithoutNumber)]) + (parseFloat(booksPrice[i])));
        }
      } else {
        if (this.categoryBookName.indexOf(book) === -1) {
          this.categoryBookName.push(book);
          this.categoryBookQuantity.push(String(parseInt(booksQuantity[i])));
          this.categoryBookPrice.push(String(parseFloat(booksPrice[i])));
        } else {
          this.categoryBookQuantity[this.categoryBookName.indexOf(book)] = String(parseInt(this.categoryBookQuantity[this.categoryBookName.indexOf(book)]) + parseInt(booksQuantity[i]));
          this.categoryBookPrice[this.categoryBookName.indexOf(book)] = String(parseFloat(this.categoryBookPrice[this.categoryBookName.indexOf(book)]) + (parseFloat(booksPrice[i])));
        }
      }
    }
    let count = 0;
    let ii = 0;
    while (count < 1) {
      let max = parseInt(this.categoryBookQuantity[0]);
      let maxIndex = 0;

      for (let i = 1; i < this.categoryBookQuantity.length; i++) {
        if (parseInt((this.categoryBookQuantity)[i]) > max) {
          maxIndex = i;
          max = parseInt((this.categoryBookQuantity)[i]);
        }
      }
      this.categoryBookMV[count].nameBook = this.categoryBookName[maxIndex];
      this.categoryBookMV[count].quantity = this.categoryBookQuantity[maxIndex];
      this.categoryBookMV[count].price = this.categoryBookPrice[maxIndex];
      ii = this.categoryBookQuantity.indexOf(String(max));
      if (ii >= 0) {
        this.categoryBookName.splice(ii, 1);
        this.categoryBookQuantity.splice(ii, 1);
        this.categoryBookPrice.splice(ii, 1);
      }
      count += 1;
    }

    return this.categoryBookMV;
  }

  addWaitersControl(moves) {
    this.waiters = [];
    this.waiterName = [];
    this.waiterRol = [];
    this.waiterRecharges = [];
    this.waiterSells = [];
    this.waiterVouchersRecharges = [];
    this.waiterVouchersSells = [];

    for (const move of moves) {
      if (move.worker !== null) {
        if (move.creditMove.amount.includes('-')) {
          if (this.waiterName.indexOf(move.worker[0].name) === -1) {
            this.waiterName.push(move.worker[0].name);
            this.waiterRol.push(move.worker[0].roleName);
            this.waiterSells.push(String(Math.abs(parseFloat(move.creditMove.amount))));
            this.waiterRecharges.push(String(0));
            this.waiterVouchersSells.push(String(0));
            this.waiterVouchersRecharges.push(String(0));
          } else {
            this.waiterSells[this.waiterName.indexOf(move.worker[0].name)] = String(parseFloat(this.waiterSells[this.waiterName.indexOf(move.worker[0].name)]) + Math.abs(parseFloat(move.creditMove.amount)));
          }
        } else if (move.creditMove.voucherId !== null) {
            if (move.creditMove.uses.includes('-')) {
              if (this.waiterName.indexOf(move.worker[0].name) === -1) {
                this.waiterName.push(move.worker[0].name);
                this.waiterRol.push(move.worker[0].roleName);
                this.waiterSells.push(String(0));
                this.waiterRecharges.push(String(0));
                this.waiterVouchersSells.push(String(Math.abs(parseFloat(move.creditMove.uses))));
                this.waiterVouchersRecharges.push(String(0));
              } else {
                this.waiterVouchersSells[this.waiterName.indexOf(move.worker[0].name)] = String(parseFloat(this.waiterVouchersSells[this.waiterName.indexOf(move.worker[0].name)]) + Math.abs(parseFloat(move.creditMove.uses)));
              }
            } else {
              if (this.waiterName.indexOf(move.worker[0].name) === -1) {
                if (move.voucher[0] !== undefined) {
                  this.waiterName.push(move.worker[0].name);
                  this.waiterRol.push(move.worker[0].roleName);
                  this.waiterSells.push(String(0));
                  this.waiterRecharges.push(String(0));
                  this.waiterVouchersSells.push(String(0));
                  // this.waiterVouchersRecharges.push(move.creditMove.uses[0]);
                  this.waiterVouchersRecharges.push(parseFloat(move.voucher[0].price));
                }
              } else {
                if (move.voucher[0] !== undefined) {
                  this.waiterVouchersRecharges[this.waiterName.indexOf(move.worker[0].name)] = String(parseFloat(this.waiterVouchersRecharges[this.waiterName.indexOf(move.worker[0].name)]) + parseFloat(move.voucher[0].price));
                }
              }
            }
        } else {
          if (this.waiterName.indexOf(move.worker[0].name) === -1) {
            this.waiterName.push(move.worker[0].name);
            this.waiterRol.push(move.worker[0].roleName);
            this.waiterSells.push(String(0));
            this.waiterRecharges.push(move.creditMove.amount);
            this.waiterVouchersSells.push(String(0));
            this.waiterVouchersRecharges.push(String(0));
          } else {
            this.waiterRecharges[this.waiterName.indexOf(move.worker[0].name)] = String(parseFloat(this.waiterRecharges[this.waiterName.indexOf(move.worker[0].name)]) + parseFloat(move.creditMove.amount));
          }
        }
      }
    }
    for (let _i = 0; _i < this.waiterName.length; _i++) {
      this.infowaiter = {name: this.waiterName[_i], rol: this.waiterRol[_i], recharges: String(parseFloat(this.waiterRecharges[_i]).toFixed(2)), sells: String(parseFloat(this.waiterSells[_i]).toFixed(2)), rechargesVouchers: String(parseFloat(this.waiterVouchersRecharges[_i]).toFixed(2)), sellsVouchers: String(parseFloat(this.waiterVouchersSells[_i]).toFixed(2))};
      this.waiters.push(this.infowaiter);
    }

    return this.waiters;
  }
  setInfoTickets(data: any) {
    this.ticketsbyQr = data.infoValidated;
    this.ticketCount = data.count;
  }
}
