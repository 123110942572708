import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ThemeService } from '@shared/services';

export interface Theme {
  primary: string;
  theme: string;
}

/**
 * BeeThemeSelectorComponent, button with a menu of the different themes to select.
 */
@Component({
  selector: 'bee-theme-selector',
  templateUrl: './bee-theme-selector.component.html',
  styleUrls: ['./bee-theme-selector.component.scss']
})
export class BeeThemeSelectorComponent implements OnInit {
  /** The selected theme. */
  selectedTheme: Theme;
  /** The selected theme. */
  @Input() selectedThemeName: string;
  /**
   * Tooltip in the theme selector button.
   * @type {string}
   */
  @Input() tooltipText = 'Selecciona un tema';
  /**
   * Additional text, info.
   * @type {string}
   */
  @Input() text = '';
  /**
   * Apply/Not apply the margin. (SCSS)
   * @type {boolean}
   */
  @Input() withMargin = true;
  /**
   * Apply/Not apply to the theme service.
   * @type {boolean}
   */
  @Input() appliesToThemeService = true;
  /** Outputs the selectedTheme */
  @Output() readonly themeSelected: EventEmitter<Theme> = new EventEmitter<Theme>();

  /**
   * The array of themes
   */
  themes: Theme[] = [
    {
      primary: '#E53935',
      theme: 'bee-red-theme'
    },
    {
      primary: '#D81B60',
      theme: 'bee-pink-theme'
    },
    {
      primary: '#8e24aa',
      theme: 'bee-purple-theme'
    },
    {
      primary: '#573c87',
      theme: 'bee-deep-purple-theme'
    },
    {
      primary: '#0A6C2D',
      theme: 'bee-deep-green-theme'
    },
    {
      primary: '#43A047',
      theme: 'bee-green-theme'
    },
    {
      primary: '#00897B',
      theme: 'bee-turquoise-theme'
    },
    {
      primary: '#cb2b99',
      theme: 'bee-redentradas-theme'
    },
    {
      primary: '#01ACC1',
      theme: 'bee-cyan-theme'
    },
    {
      primary: '#1E88E5',
      theme: 'bee-blue-theme'
    },
    {
      primary: '#3948AB',
      theme: 'bee-deep-blue-theme'
    },
    {
      primary: '#FDD835',
      theme: 'bee-yellow-theme'
    },
    {
      primary: '#CF9307',
      theme: 'bee-amber-theme'
    },
    {
      primary: '#D17603',
      theme: 'bee-orange-theme'
    },
    {
      primary: '#BC3308',
      theme: 'bee-deep-orange-theme'
    },
    {
      primary: '#1E1E1E',
      theme: 'bee-black-theme'
    },
    {
      primary: '#4E4E4E',
      theme: 'bee-deep-grey-theme'
    },
    {
      primary: '#546E7A',
      theme: 'bee-blue-grey-theme'
    },
    {
      primary: '#757575',
      theme: 'bee-grey-theme'
    },
    {
      primary: '#6D4C41',
      theme: 'bee-brown-theme'
    },
  ];

  /**
   * Constructor.
   * @param themeService The themeService instance.
   */
  constructor(public themeService: ThemeService) {
  }

  ngOnInit(): void {
    if (this.appliesToThemeService === true) {
      this.selectThemeByName(this.themeService.selectedTheme);
    }

    if (this.selectedThemeName) {
      this.selectThemeByName(this.selectedThemeName);
    }
  }

  /**
   * Select a theme in the themeService and applies it.
   * @param theme
   */
  selectTheme(theme) {
    this.selectedTheme = theme;
    if (this.appliesToThemeService) {
      this.themeService.selectedTheme = theme.theme;
    }
    this.themeSelected.next(this.selectedTheme);
  }

  /**
   * Selects a theme by name.
   *
   * @param themeName
   */
  selectThemeByName(themeName: string) {
    const themeIndex = this.themes.findIndex(theme => theme.theme === themeName);
    if (themeIndex > -1) {
      this.selectTheme(this.themes[themeIndex]);
    }
  }
}
