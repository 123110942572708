import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { NgUploaderService } from 'ngx-uploader';

/**
 * BeeCropperComponent.
 */
@Component({
  selector: 'bee-cropper',
  templateUrl: './bee-cropper.component.html',
  styleUrls: ['./bee-cropper.component.scss']
})
export class BeeCropperComponent implements OnInit {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  croppedFile: Blob;
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  originalFile;
  upload: NgUploaderService;
  /** Inputs */
  aspectRatio: number;
  maintainAspectRatio: boolean;
  roundCropper: boolean;
  title = 'Ajusta tu imagen';
  previewText = 'Previsualización:';
  maxFileSize: number;
  maxFileSizeTip = 'El tamaño de archivo supera el máximo específicado, elige otro o selecciona un área más pequeña.';
  format: 'png' | 'jpeg' | 'bmp' | 'webp' | 'ico' = 'png';

  /**
   * Constructor.
   * @param dialogRef
   * @param data
   */
  constructor(public dialogRef: MatDialogRef<BeeCropperComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.maxFileSize = this.data.maxFileSize;
    this.roundCropper = this.data.roundCropper;
    this.maintainAspectRatio = this.data.maintainAspectRatio;
    this.aspectRatio = this.data.aspectRatio;
    this.imageChangedEvent = this.data.event;
    this.originalFile = this.imageChangedEvent.target.files[0];
    if (this.originalFile.type === 'image/jpeg') {
      this.format = 'jpeg';
    }
    // console.log(this.data.event, this.originalFile, this.roundCropper);
  }

  fileChangeEvent(event: any): void {
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    const blob = base64ToFile(event.base64);
    this.croppedFile = new Blob([blob], {type: blob.type});
  }

  imageLoaded() {
    this.showCropper = true;
    // console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    // console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    // console.log('Load failed');
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    };
  }

  closeDialog() {
    this.dialogRef.close();
  }

  confirmFile() {
    this.dialogRef.close({croppedFile: this.croppedFile, croppedFileName: this.originalFile.name});
  }
}
