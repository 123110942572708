import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Product } from '@shared/models/product';

@Injectable()
export class ProductsService {
  productsChanged = new Subject<Product[]>();
  private products: Product[] = [];
  private categories: any[] = [];
  categoriesChanged = new Subject<Product[]>();
  private price: any;
  private product: Product;
  private category: any;
  productsList = [];
  productCategory: any;
  productCategoryIcon: any;

  constructor() {}

  setProducts(products: Product[]) {
    this.products = products;
    this.productsChanged.next(this.products.slice());
    this.productList(this.products);

    return this.products;
  }

  getProducts() {
    return this.products.slice();
  }

  setCategories(categories) {
    this.categories = categories;
    this.categoriesChanged.next(this.categories.slice());

    return this.categories;
  }

  getCategories() {
    return this.categories.slice();
  }

  addNewProduct(product: Product) {
    this.products.push(product);
    this.productsChanged.next(this.products.slice());
    this.productList(this.products);
  }

  addNewCategory(category) {
    this.categories.push(category);
    this.categoriesChanged.next(this.categories.slice());
  }

  editSetProduct(product: Product) {
    this.product = product;

    return this.product;
  }

  editGetProduct() {
    return this.product;
  }

  editSetCategory(category) {
    this.category = category;

    return this.category;
  }

  editGetCategory() {
    return this.category;
  }

  productList(products) {
    this.products = products;
    this.productsList = [];
    for (this.product of this.products) {
      this.categories = this.getCategories().slice();
      for (const category of this.categories) {
        if (category.id === this.product.productCategoryId) {
          this.productCategory = category.name;
          this.productCategoryIcon = category.icon;
        }
      }
      if (this.product.pivot !== undefined) {
        this.productsList.push({name: this.product.name, price: this.product.pivot.price, productCategory: this.productCategory, productCategoryIcon: this.productCategoryIcon, id: this.product.id, imageUrl: this.product.imageUrl});
      } else {
        this.productsList.push({name: this.product.name, price: this.product.price, productCategory: this.productCategory, productCategoryIcon: this.productCategoryIcon, id: this.product.id, imageUrl: this.product.imageUrl});
      }
    }

    return this.productsList;
  }
}
