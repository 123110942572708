import { Injectable } from '@angular/core';
import { DataStorageService } from '@shared/services/data-storage.service';
import { AuthenticationService, SnackBarService } from '@shared/services';

@Injectable()
export class OrdersService {
  onlinePlaces: any;

  constructor(private dataStorageService: DataStorageService,
    private authenticationService: AuthenticationService) {
  }

  getOrders() {
    return [];
  }

  setOrders(value) {
  }

  orderList() {

  }
  // getOrderPlaces(){
  //   const Todos = {
  //     name: 'Todos',
  //     id: ''
  //   };
  //     this.dataStorageService.listOrderPlaces().subscribe(
  //       data => {
  //         this.onlinePlaces = data;
  //         this.onlinePlaces.push(Todos);
  //         this.authenticationService.setLoader(false);
  //         return this.onlinePlaces;
  //       },
  //       err => {
  //         console.error(err);
  //         this.authenticationService.setLoader(false);
  //       });
  //   }
}
