import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from '@shared/services/api.service';

/**
 * BeeWizardHelp
 * @ignore
 */
@Component({
  selector: 'bee-wizard-help',
  templateUrl: './bee-wizard-help.component.html',
  styleUrls: ['./bee-wizard-help.component.scss']
})
export class BeeWizardHelpComponent implements OnInit {
  @Input() id: string = 'form-id-' + Math.floor((Math.random() * 1000) + 1);

  @Input() saveButtonEnabled = true;
  @Input() saveButtonText = 'GUARDAR CAMBIOS';

  @Input() baseTitle = 'Manual de usuario';
  @Input() baseUrl = '../panel';
  @Input() title = '';
  @Input() contactEnabled = true;
  @Input() contactText = '¿Alguna duda?';
  @Input() contactActionText = 'Contacta con nosotros';

  /* Navigation inputs */
  @Input() nextUrl = '';
  @Input() nextButtonText = 'SIGUIENTE';
  @Input() nextName = '';
  @Input() previousUrl = '';
  @Input() previousButtonText = 'ANTERIOR';
  @Input() previousName = '';

  @Input() loadingHelpText = 'Generando página...';
  @Input() loaded = true;
  @Input() helpWithPadding = false;

  @Input() templateRef: TemplateRef<any>;

  @Output() readonly wizardPreNavigatingFunction: EventEmitter<any> = new EventEmitter();
  @Output() readonly contactFunction: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router,
              private apiService: ApiService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    window.scroll(0, 0);
  }

  onNavigate(url: string = '') {
    this.wizardPreNavigatingFunction.emit(url);
    this.router.navigate(['../' + url], {relativeTo: this.route});
  }

  onBaseTitle() {
    this.router.navigate([this.baseUrl], {relativeTo: this.route});
  }

  onContact() {
    this.contactFunction.emit(true);
  }
}
