<div class="wizard-help">
  <div class="wizard-header">
    <div class="wizard-header-title">
      <span *ngIf="baseTitle" class="base-title" (click)="onBaseTitle()">{{ baseTitle }}</span>
      |
      <span *ngIf="title" class="title">{{ title }}</span>
    </div>

    <div class="wizard-header-contact">
      <ng-container *ngIf="contactEnabled">
        <span *ngIf="contactText" class="contact-text">{{ contactText }}</span>
        <span *ngIf="contactActionText" class="contact-action-text"
              (click)="onContact()">{{ contactActionText }}</span>
      </ng-container>
    </div>
  </div>

  <div class="wizard-help-container" [ngClass]="{'help-with-padding': helpWithPadding}" fxLayout="row wrap"
       fxLayoutAlign="start start">

    <bee-preloader *ngIf="!loaded" [absoluteCentered]="true"
                   [loadingText]="loadingHelpText"></bee-preloader>

    <ng-template *ngIf="loaded"
                 [ngTemplateOutlet]="templateRef">
    </ng-template>
  </div>

  <div class="wizard-footer">
    <div class="navigation-container">
      <div *ngIf="previousUrl" class="navigation-button" (click)="onNavigate(previousUrl)">
        <mat-icon svgIcon="arrow-left"></mat-icon>
        <span class="navigation-previous-button-text">{{ previousButtonText }}</span>
      </div>
      <span *ngIf="previousName" class="navigation-name navigation-name-previous">{{ previousName }}</span>
    </div>

    <div class="navigation-container">
      <span *ngIf="nextName" class="navigation-name navigation-name-next">{{ nextName }}</span>
      <div *ngIf="nextUrl" class="navigation-button" (click)="onNavigate(nextUrl)">
        <span class="navigation-next-button-text">{{ nextButtonText }}</span>
        <mat-icon svgIcon="arrow-right"></mat-icon>
      </div>
    </div>
  </div>
</div>

