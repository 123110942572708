import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '@shared/angular-material';

import { BeeConfirmNavigationModule } from '../common/bee-confirm-navigation';
import { BeeFileUploaderModule } from '../common/bee-file-uploader';
import { BeeImagePreviewModule } from '../common/bee-image-preview';
import { BeePipesModule } from '../common/bee-pipes';
import { BeePreloaderModule } from '../common/bee-preloader';
import { BeeFormItemModule } from './bee-form-item/bee-form-item.module';
import { BeeFormComponent } from './bee-form.component';
import { BeeSelectorModule } from './bee-selector/bee-selector.module';

@NgModule({
  declarations: [
    BeeFormComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    BeeFileUploaderModule,
    BeeFormItemModule,
    BeeSelectorModule,
    BeeConfirmNavigationModule,
    BeeSelectorModule,
    BeeImagePreviewModule,
    BeePreloaderModule,
    BeePipesModule
  ],
  exports: [
    BeeFormComponent,
    BeeFileUploaderModule,
    BeeFormItemModule,
    BeeSelectorModule,
    BeeConfirmNavigationModule,
    BeeSelectorModule,
    BeeImagePreviewModule,
    BeePreloaderModule
  ]
})
export class BeeFormModule { }
