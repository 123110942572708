<div id="unsubscribe-background-container" class="background"
     fxFlex="100" fxLayoutAlign="center center">
  <mat-card id="unsubscribe-container" fxLayout="row wrap" fxLayoutAlign="center center">
    <div fxLayoutAlign="center center"><img [src]="iconUrl" alt=""></div>
    <div id="unsubscribe-main-text">{{mainText}}</div>
    <div id="unsubscribe-additional-text">{{additionalText}}</div>
    <div fxLayoutAlign="center center" id="illustration-image"><img [src]="imageUrl" alt=""></div>
    <div fxLayoutAlign="center center">
      <button mat-button color="primary" [routerLink]="buttonRoute">{{buttonText}}</button>
    </div>
  </mat-card>
</div>
