import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '@shared/angular-material';

import { BeeUnsubscribeComponent } from './bee-unsubscribe.component';

@NgModule({
  declarations: [
    BeeUnsubscribeComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    RouterModule
  ],
  exports: [
    BeeUnsubscribeComponent
  ]
})
export class BeeUnsubscribeModule { }
