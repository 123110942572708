import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

/**
 * BeeEmail, used to send mails to one or more emails.
 * Used in dataGrid.
 */
@Component({
  selector: 'bee-email',
  templateUrl: 'bee-email.component.html',
  styleUrls: ['./bee-email.component.scss'],
})
export class BeeEmailComponent implements OnInit {
  /**
   * The formGroup containing the subject, content, documents, etc
   * @type {FormGroup}
   */
  myForm: FormGroup;
  /**
   * The formGroup containing the subject, content, documents, etc
   * @type {EventEmitter}
   */
  postSubmitEmitter: EventEmitter<any> = new EventEmitter();
  error: any;
  title: string;
  hideEmails = false;
  nameCandidates: string[] = [];
  emailCandidates: string[] = [];
  showCandidates = false;
  apiRoute = 'messages';
  filesTip = 'Los archivos adjuntos se enviarán por email';
  isEmailTooltip = 'Se enviará un correo a los destinatarios que puedan recibir correos electrónicos';
  noCandidatesTip = 'No hay destinatarios disponibles.';

  /**
   * Constructor
   * @param dialogRef
   * @param formBuilder
   * @param data The dialog injected data.
   */
  constructor(private dialogRef: MatDialogRef<BeeEmailComponent>,
              private formBuilder: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any) {

    this.myForm = formBuilder.group({
      data: [{}, Validators.required],
      subject: ['', Validators.required],
      content: ['', Validators.required],
      groupId: [''],
      documents: formBuilder.array([]),
      isEmail: [true]
    });
  }

  /**
   * OnInit lifecycle
   */
  ngOnInit() {
    this.title = this.data.title;
    this.emailCandidates = this.data.emailCandidates;
    if (this.data.hasOwnProperty('nameCandidates')) {
      this.nameCandidates = this.data.nameCandidates;
    }
    if (this.data.hasOwnProperty('hideEmails')) {
      this.hideEmails = this.data.hideEmails;
    }
    if (this.data.groupId) {
      this.myForm.controls['groupId'].setValue(this.data.groupId);
    }
    const data = this.myForm.controls.data.value;
    data['receivers'] = this.emailCandidates;
    this.myForm.controls.data.setValue(data);

    if (this.data.apiRoute) {
      this.apiRoute = this.data.apiRoute;
    }
    if (this.data.filesTip) {
      this.filesTip = this.data.filesTip;
    }
    if (this.data.isEmailTooltip) {
      this.isEmailTooltip = this.data.isEmailTooltip;
    }
    if (this.data.noCandidatesTip) {
      this.noCandidatesTip = this.data.noCandidatesTip;
    }
  }

  /**
   * PreSubmit event
   */
  onPreSubmit() {
    const data = this.myForm.controls.data.value;
    this.myForm.controls.data.setValue(data);

    this.myForm.controls.data.setValue(JSON.stringify(this.myForm.controls.data.value));
  }

  /**
   * PostSubmit event, closes the dialog
   * @param $event
   */
  onPostSubmit($event) {
    this.postSubmitEmitter.emit($event);
    this.closeDialog();
  }

  /**
   * Show the mail candidates
   */
  onShowCandidates() {
    this.showCandidates = !this.showCandidates;
  }

  /**
   * Removes a candidate
   * @param candidate
   */
  onRemoveCandidate(candidate) {
    this.emailCandidates.splice(this.emailCandidates.indexOf(candidate), 1);
  }

  /**
   * Removes a candidate name
   * @param candidate
   * @param i
   */
  onRemoveNameCandidate(candidate, i) {
    this.nameCandidates.splice(this.nameCandidates.indexOf(candidate), 1);
    this.emailCandidates.splice(i, 1);
  }

  /**
   * Closes the dialog
   */
  closeDialog(): void {
    this.dialogRef.close();
  }
}
