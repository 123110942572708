<bee-form [beeFormGroup]="myForm"
          [apiRoute]="'notifications'"
          [toastMessage]="'Notificación creada con éxito'"
          [errorMessage]="'Error al crear la notificación, inténtalo de nuevo'"
          [saveButtonText]="'CREAR NOTIFICACIÓN'"
          [insideModalTitle]="'Nueva notificación'"
          [insideModal]="true"
          (closeModalContainer)="closeDialog()"
          (postSubmitFunction)="onPostSubmit()">

  <bee-form-item fxFlex="100" [id]="'type'" [type]="'select'" [label]="'Tipo de notificación'"
                 [placeholder]="'Selecciona un tipo'"
                 [beeFormControl]="myForm.controls['type']"
                 [options]="globalArraysService.notificationTypes"></bee-form-item>

  <bee-form-item fxFlex="100" [id]="'title'" [label]="'Asunto'"
                 [placeholder]="'Introduce un asunto'"
                 [beeFormControl]="myForm.controls['title']"></bee-form-item>

  <bee-form-item fxFlex="100" [type]="'textarea'"
                 [id]="'content'"
                 [label]="'Contenido'"
                 [textAreaMinRows]="6"
                 [beeFormControl]="myForm.controls['content']"></bee-form-item>

  <bee-form-item fxFlex="100" [type]="'checkbox'" [id]="'is-email'"
                 [label]="'Enviar por email'"
                 [beeFormControl]="myForm.controls['isEmail']"></bee-form-item>
</bee-form>
