import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Action } from './action';

@Component({
  selector: 'app-actions-top-bar',
  templateUrl: './actions-top-bar.component.html',
  styleUrls: ['./actions-top-bar.component.scss']
})
export class ActionsTopBarComponent implements OnInit {
  @Input() actions: Action[];
  @Output() readonly triggerAction = new EventEmitter<Action>();
  @Output() readonly search = new EventEmitter<string>();
  searchActive = false;

  constructor() { }

  ngOnInit(): void {
  }

//  TODO: Implement search with delay

}
