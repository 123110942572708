import { Component, Input, OnInit } from '@angular/core';

import { ApiService, AuthenticationService } from '@shared/services';
import { DataStorageService } from '@shared/services/data-storage.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() activeTab: string;
  components: any[] = [];
  options: string[] = [];
  nButtons: number;
  icons: any[] = [];
  actives: any[] = [];
  class: any[] = [];
  public flag = false;
  public hasVouchers: boolean;
  public hasAccessControl: boolean;
  public hasPurchases: boolean;
  public hasOnlineOrders: boolean;
  public isTravel: boolean;
  roleUser: any;
  userId: any;

  constructor(public authService: AuthenticationService,
              private dataStorageService: DataStorageService,
              private apiService: ApiService) {
  }

  ngOnInit(): void {
    this.userId = JSON.parse(localStorage.getItem('userId'));
    this.roleUser = JSON.parse(localStorage.getItem('roleUser'));

        this.nButtons = 0;
        this.icons.push('account-tie');
        this.icons.push('account');
        this.icons.push('cellphone-nfc');
        this.options.push('Personal autorizado');
        this.options.push('Usuarios');
        this.options.push('Terminales');
        this.actives.push('workers');
        this.actives.push('users');
        this.actives.push('devices')
        this.components.push('/trabajadores');
        this.components.push('/usuarios');
        this.components.push('/devices')
        this.nButtons += 3;
  }

  changeMenu() {
    this.flag = !this.flag;
  }
}
