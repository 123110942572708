<div class="wizard">
  <div class="wizard-header">
    <div class="wizard-header-title">
      <span *ngIf="titlePrefix" class="title-prefix">{{ titlePrefix }}</span>
      <span *ngIf="formTitle" class="title"> {{ formTitle }}</span>
    </div>
    <div *ngIf="toggleEnabled" class="wizard-toggle-container">
      <span *ngIf="toggleButtonText" class="toggle-button-text">{{ toggleButtonText }}</span>
      <span class="toggle-off" [ngClass]="{'toggle-off-active': !toggleChecked}">OFF</span>
      <mat-slide-toggle color="primary" [checked]="toggleChecked" (change)="onToggleChange($event)"></mat-slide-toggle>
      <span class="toggle-on" [ngClass]="{'toggle-on-active': toggleChecked}">ON</span>
    </div>
    <div class="navigation-container">
      <span *ngIf="previousFormName" class="navigation-form-name">{{ previousFormName }}</span>
      <div *ngIf="previousFormUrl" class="navigation-form-button" (click)="onNavigate(previousFormUrl)">
        <span class="navigation-form-button-text">{{ previousFormButtonText }}</span>
        <mat-icon svgIcon="arrow-up"></mat-icon>
      </div>
    </div>
  </div>

  <form (keydown.enter)="checkPreventDefault($event)" [id]="id" [formGroup]="formGroup" (ngSubmit)="onSubmit()"
        [ngClass]="{'form-with-padding': formWithPadding}"
        fxLayout="row wrap" fxLayoutAlign="start start">

    <bee-preloader *ngIf="!loaded" [absoluteCentered]="true"
                   [loadingText]="loadingFormText"></bee-preloader>

    <mat-card *ngIf="loaded && unloadedDependenciesObject.length > 0" class="dependencies-warning-card">
      <div class="dependencies-warning-card-icon">
        <mat-icon svgIcon="alert" color="warn" class="warning-icon"></mat-icon>
        <p>{{ warningText }}</p>
      </div>
      <div class="dependencies-warning-card-content">
        <span>{{ thisFormText }} <span
            class="bold-text">{{ noRealDataText }}</span>{{ unloadedDependenciesText }}</span>
        <ul>
          <li *ngFor="let unloadDependencyForm of unloadedDependenciesObject"
              (click)="onDependency(unloadDependencyForm['link'])">
            <span>-> {{ unloadDependencyForm['name'] }}</span>
          </li>
        </ul>
      </div>
    </mat-card>

    <ng-template *ngIf="loaded"
                 [ngTemplateOutlet]="templateRef">
    </ng-template>

    <div *ngIf="error" fxFlex="100" class="center-align warn-text error-message">{{ errorMessage }}</div>

    <span *ngIf="requiredSign && hasRequiredFields"
          class="required-fields-tip-modal foreground-secondary-text-color">
              {{ requiredText }}
            </span>
  </form>

  <div class="wizard-footer">
    <div class="status-management-container">
      <button *ngIf="saveButtonEnabled && !updatingDependencies" mat-button color="primary" [id]="id+'submit-button'"
              class="save-button"
              [ngClass]="{'hidden': submitting}"
              [@submitState]="formGroup.valid ? 'valid' : 'invalid'"
              [disabled]="disabledClauses || unloadedDependenciesObject.length > 0" (click)="onSubmit(true)">
        {{ saveButtonText }}
        <span class="invalid-form" *ngIf="!formGroup.valid">*</span>
      </button>
      <mat-spinner *ngIf="submitting" [diameter]="36"></mat-spinner>

      <div class="updating-dependencies-container" *ngIf="updatingDependencies">
        <mat-progress-bar [mode]="updatingDependenciesMode"
                          [value]="updatingDependenciesProgress"></mat-progress-bar>
        <span class="updating-dependencies-text">{{ updatingDependenciesText }}</span>
      </div>
    </div>

    <div class="export-container">
      <button *ngIf="exportEnabled" mat-button type="button" (click)="onExport()" class="hover-primary"
              [disabled]="updatingDependencies || !loaded || exportButtonDisabled">
        <span>{{ exportButtonText }}</span>
        <mat-icon svgIcon="export"></mat-icon>
      </button>
    </div>

    <div *ngIf="!saveButtonEnabled" class="blank-space"></div>
    <div class="navigation-container">
      <span *ngIf="nextFormName" class="navigation-form-name">{{ nextFormName }}</span>
      <div class="navigation-form-button" (click)="onNavigate(nextFormUrl)">
        <span class="navigation-form-button-text">{{ nextFormButtonText }}</span>
        <mat-icon svgIcon="arrow-down"></mat-icon>
      </div>
    </div>
  </div>
</div>

