import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { ApiService } from '@shared/services/api.service';
import { unsubscribedConfiguration } from '../bee-components.configuration';

/**
 * BeeUnsubscribeComponent, unsubscribe an user from the email notifications.
 */
@Component({
  selector: 'app-bee-unsubscribe',
  templateUrl: './bee-unsubscribe.component.html',
  styleUrls: ['./bee-unsubscribe.component.scss']
})
export class BeeUnsubscribeComponent implements OnInit, OnDestroy {
  /**
   * The user to unsubscribe.
   * @type {string}
   */
  userId: string;
  /**
   * The routeSubscription which get the userId.
   * @type {Subscription}
   */
  routeSubscription: Subscription;
  /**
   * iconUrl, icon recommended check. (default icon check)
   * @type {string}
   */
  iconUrl: string = unsubscribedConfiguration.iconUrl;
  /**
   * imageUrl. (default "unsubscribed" illustration)
   * @type {string}
   */
  imageUrl: string = unsubscribedConfiguration.imageUrl;
  /**
   * iconUrl. (default "Has borrado tu subscripción a los emails de la aplicación")
   * @type {string}
   */
  mainText: string = unsubscribedConfiguration.mainText;
  /**
   * iconUrl. (default "Puedes cambiar esta configuración desde tu perfil de usuario accendiento a tu cuenta de la aplicación")
   * @type {string}
   */
  additionalText: string = unsubscribedConfiguration.additionalText;
  /**
   * iconUrl. (default "Ir al inicio de sesión")
   * @type {string}
   */
  buttonText: string = unsubscribedConfiguration.buttonText;
  /**
   * iconUrl. (default "/login")
   * @type {string}
   */
  buttonRoute: string = unsubscribedConfiguration.buttonRoute;

  /**
   * Constructor.
   * @param route
   * @param apiService
   */
  constructor(private route: ActivatedRoute,
              private apiService: ApiService) {
  }

  /**
   * OnInit lifecycle, gets the userId from the route and performs the unsubscription.
   */
  ngOnInit(): void {
    this.routeSubscription = this.route.params.subscribe((data) => {
      this.userId = data.userId;
      if (this.userId) {
        this.apiService.patch(`users/${this.userId}/unsubscribe`, {}).subscribe(() => {
        });
      }
    });
  }

  /**
   * OnDestroy lifecycle, clear the subscription.
   */
  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }
}
