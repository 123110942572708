import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '@shared/angular-material';

import { BeePreloaderModule } from '@bee-components/common/bee-preloader';

import { BeeWizardFormComponent } from './bee-wizard-form.component';

@NgModule({
  declarations: [
    BeeWizardFormComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    BeePreloaderModule,
    ReactiveFormsModule
  ],
  exports: [
    BeeWizardFormComponent
  ]
})
export class BeeWizardFormModule { }
