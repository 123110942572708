import { OverlayContainer } from '@angular/cdk/overlay';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';

import { ApiService } from './api.service';
import { AuthenticationService } from './authentication.service';

/**
 * DEFAULT_THEME InjectionToken, provides the default theme. Default is 'bee-blue-theme'.
 *
 * @example
 *
 * providers: [
 *    ThemeService,
 *    {provide: DEFAULT_THEME, useValue: 'bee-green-theme'},
 * ...
 * ]
 */
export const DEFAULT_THEME: InjectionToken<string> = new InjectionToken<string>('The default theme');

/**
 * ThemeService, changes the selected theme.
 */
@Injectable()
export class ThemeService {
  /**
   * Selected theme. Default is 'bee-blue-theme'.
   * @type {string}
   */
  private _selectedTheme: string;

  /**
   * Constructor.
   * @param defaultTheme The default theme of the app
   * @param overlayContainer
   * @param authenticationService
   * @param apiService
   */
  constructor(private overlayContainer: OverlayContainer,
              private authenticationService: AuthenticationService,
              private apiService: ApiService,
              @Optional() @Inject(DEFAULT_THEME) private defaultTheme: string) {
    (defaultTheme) ? this._selectedTheme = defaultTheme : this._selectedTheme = 'bee-blue-theme';
    /*
    if (authenticationService.account && authenticationService.account.selectedTheme && authenticationService.account.selectedTheme !== this._selectedTheme) {
      this._selectedTheme = authenticationService.account.selectedTheme;
    }*/
    overlayContainer.getContainerElement().classList.add(this._selectedTheme);
  }

  /**
   * Gets the selected theme.
   * @returns {string}
   */
  get selectedTheme(): string {
    return this._selectedTheme;
  }

  /**
   * Sets the selected theme, if account is available, updates the selected theme in the BD.
   * @param value
   */
  set selectedTheme(value: string) {
    /*
    if (this.authenticationService.account && this.authenticationService.account.selectedTheme && this.authenticationService.account.selectedTheme !== value) {
      this.apiService.post('accounts/theme/' + value, '').subscribe(
        () => {
          this.overlayContainer.getContainerElement().classList.remove(this._selectedTheme);
          this._selectedTheme = value;
          // this.authenticationService.account.selectedTheme = value;
          // this.authenticationService.account = this.authenticationService.account;
          this.overlayContainer.getContainerElement().classList.add(this._selectedTheme);
        }, err => {
          console.error(err);
        });
    } else {
    */
    this.overlayContainer.getContainerElement().classList.remove(this._selectedTheme);
    this._selectedTheme = value;
    this.overlayContainer.getContainerElement().classList.add(this._selectedTheme);
  }
}
