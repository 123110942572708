import { Component, Input } from '@angular/core';

/**
 * BeePreloader, shows a Material spinner.
 *
 * @example
 *
 * <bee-preloader [diameter]="30" [absoluteCentered]="true"></bee-preloader>
 */
@Component({
  selector: 'bee-preloader',
  templateUrl: './bee-preloader.component.html',
  styleUrls: ['./bee-preloader.component.scss'],
})
export class BeePreloaderComponent {
  /**
   * Diameter of the spinner.
   * @type {number}
   */
  @Input() diameter = 60;
  /**
   * Loading text.
   * @type {string}
   */
  @Input() loadingText: string = null;
  /**
   * Checks if the spinner is centered in position absolute. (SCSS)
   * @type {boolean}
   */
  @Input() absoluteCentered = false;
  /**
   * Checks if the spinner is displayed as horizontal. (SCSS)
   * @type {boolean}
   */
  @Input() horizontalDisplay = false;
}
