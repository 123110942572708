import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { NewModalForm } from '@shared/abstract/new-modal-form';
import { GlobalArraysService } from '@shared/services';

/**
 * BeeNewNotificationComponent, creates a new notification.
 */
@Component({
  selector: 'bee-new-notification',
  templateUrl: 'bee-new-notification.component.html',
  styleUrls: ['./bee-new-notification.component.scss'],
})
export class BeeNewNotificationComponent extends NewModalForm implements OnInit {
  /** The top position of the modal */
  top: string = null;
  /** The right position of the modal */
  right: string = null;

  /**
   * Constructor.
   * @param dialogRef
   * @param formBuilder
   * @param globalArraysService
   * @param data
   */
  constructor(public globalArraysService: GlobalArraysService,
              private formBuilder: FormBuilder,
              public dialogRef: MatDialogRef<BeeNewNotificationComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    super(dialogRef);

    this.myForm = formBuilder.group({
      type: ['', Validators.required],
      title: ['', Validators.required],
      content: ['', Validators.required],
      isEmail: [false],
    });
  }
}
