import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from '@shared/angular-material';

import { BeePreloaderComponent } from './bee-preloader.component';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
  declarations: [
    BeePreloaderComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule
  ],
  exports: [
    BeePreloaderComponent
  ]
})
export class BeePreloaderModule { }
