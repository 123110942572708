import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from '@shared/angular-material';

import { BeeThemeSelectorComponent } from './bee-theme-selector/bee-theme-selector.component';

@NgModule({
  declarations: [
    BeeThemeSelectorComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
  ],
  exports: [
    BeeThemeSelectorComponent
  ]
})
export class BeeThemeModule { }
