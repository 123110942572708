import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { FloatLabelType, MatFormFieldAppearance } from '@angular/material/form-field';

import { BEE_FORM_ITEM_DEFAULT_APPEARANCE } from '@bee-components/common/bee-forms';

/**
 * BeeFormItem, wrapper for the different types of input.
 * Supports i18n and the following types: text, number, select, checkbox, textarea, boxCheckbox, slide, date, radio, boxRadio.
 */
@Component({
  selector: 'bee-form-item',
  templateUrl: './bee-form-item.component.html',
  styleUrls: ['./bee-form-item.component.scss']
})
export class BeeFormItemComponent implements OnInit, OnChanges {
  /** The id of the BeeFormItemComponent. */
  @Input() id: string = 'id-' + Math.floor((Math.random() * 1000) + 1);
  /** The Form Control. */
  @Input() beeFormControl: AbstractControl | FormControl = new FormControl();
  /** The type of the formControl. */
  @Input() type = 'text';
  /** The name of the formControl. */
  @Input() name = '';
  /** The icon of the formControl. */
  @Input() icon = '';
  /** The placeholder of the formControl. */
  @Input() placeholder = '';
  /** The label of the formControl. */
  @Input() label = '';
  /** Whether the label should float: (always, never, auto). Default is `auto`. */
  @Input() floatLabel: FloatLabelType = 'auto';
  /** The appearance of the formControl. (Outline, standard, legacy, fill)  */
  @Input() appearance: MatFormFieldAppearance;
  /** The options of the formControl. (radio, boxRadio, select) */
  @Input() options: any;
  /** The min value of the formControl. */
  @Input() min: number = null;
  /** The max value of the formControl. */
  @Input() max: number = null;
  /** The step input property the formControl. */
  @Input() step: string | number = 'any';
  /** The number of decimals allowed in the formControl. */
  @Input() decimals: number = null;
  /** Checks if the formControl is disabled. */
  @Input() isDisabled = false;
  /** The tick interval for the mat slider. */
  @Input() tickInterval = 20;
  /** The help tooltip. */
  @Input() helpTooltip = '';
  /** The help icon. Usually help-circle-outline or information-outline. */
  @Input() helpIcon = 'information-outline';
  /** Checks if the modal exists error is shown. */
  @Input() modelExistError = false;
  /** The error shown if the model exists. */
  @Input() modelExistErrorMessage = '';
  /** The checkbox margin (SCSS). */
  @Input() checkboxMargin = true;
  /** Checks if the checkbox ripple is enabled. */
  @Input() checkboxRipple = true;
  /** The min date value. */
  @Input() minDate: Date = null;
  /** The max date value. */
  @Input() maxDate: Date = null;
  /** The required text. */
  @Input() requiredText = 'Este campo es obligatorio.';
  /** Checks if the required sign is shown. */
  @Input() requiredSign = true;
  /** Minimum rows of the textarea. */
  @Input() textAreaMinRows: number;
  /** Maximum rows of the textarea. */
  @Input() textAreaMaxRows: number;
  /** Max length of the textarea. */
  @Input() textAreaMaxLength: number;
  /** Checks if the input is right aligned. */
  @Input() rightAlign = false;
  /** Checks if the radio options are in space-between mode. */
  @Input() radioSpaceBetween = true;
  /** The email not valid text. */
  @Input() emailNotValidText = 'El email introducido no es válido.';
  /** The iban not valid text. */
  @Input() ibanNotValidText = 'El IBAN introducido no es válido.';
  /** The nif not valid text. */
  @Input() nifNotValidText = 'El NIF/NIE/CIF/TR/VAT introducido no es válido.';
  /** The min/max characters tip. */
  @Input() characterText = ' caracteres.';
  /** The min/max value tip. */
  @Input() valueText = ' valor: ';
  /** The already in use text. */
  @Input() alreadyInUseText = ' ya en uso.';
  /** Checks if emit the previous radio value. */
  @Input() emitPreviousRadioValue = false;
  /** Checks if the password can be shown. */
  @Input() passwordCanBeSeen = false;
  /** Emits when the formControl value changes. */
  @Output() readonly beeFormItemChange: EventEmitter<any> = new EventEmitter<any>();
  /** Checks if the password is hidden. */
  hide = true;
  /** Checks if the formControl is required. */
  isRequired = false;
  /** The previous value of the formControl. */
  previousRadioValue: FormControl = new FormControl();

  /**
   * Constructor.
   * @param defaultAppearance
   */
  constructor(@Optional() @Inject(BEE_FORM_ITEM_DEFAULT_APPEARANCE) private defaultAppearance: MatFormFieldAppearance) {
    (defaultAppearance) ? this.appearance = defaultAppearance : this.appearance = 'outline';
  }

  /**
   * OnInit lifecycle.
   */
  ngOnInit() {
    if (this.name === '') {
      this.name = this.id;
    }
    if (this.type === 'date' && !this.placeholder) {
      this.placeholder = 'dd/mm/yyyy';
    }
    if (this.requiredSign) {
      this.checkIfRequired(this.beeFormControl);
    }
    if (this.emitPreviousRadioValue) {
      this.previousRadioValue.setValue(this.beeFormControl.value);
    }
  }

  /**
   * OnChanges lifecycle.
   * @param $event
   */
  ngOnChanges($event: SimpleChanges) {
    if (this.modelExistError) {
      this.beeFormControl.setErrors({modelExists: true});
    }
    if ($event['isDisabled'] && $event['isDisabled'].currentValue) {
      this.beeFormControl.disable({});
    } else {
      if ($event['isDisabled'] && $event['isDisabled'].previousValue && !$event['isDisabled'].currentValue) {
        this.beeFormControl.enable({});
      }
    }
  }

  /**
   * Change event.
   */
  onChange() {
    if (this.emitPreviousRadioValue) {
      this.beeFormItemChange.emit({value: this.beeFormControl.value, previousValue: this.previousRadioValue.value});
    } else {
      this.beeFormItemChange.emit(this.beeFormControl.value);
    }
  }

  /**
   * Sets the previous radio value and emits it.
   */
  setPreviousRadioValue() {
    if (this.emitPreviousRadioValue) {
      this.previousRadioValue.setValue(this.beeFormControl.value);
    }
  }

  /**
   * Used when clicked on boxCheckBox mode, changes the beeFormControl value and emits it.
   */
  onBoxCheckBox() {
    this.beeFormControl.setValue(!this.beeFormControl.value);
    this.beeFormItemChange.emit(this.beeFormControl.value);
  }

  /**
   * Used when clicked on boxRadio mode, changes the beeFormControl value and emits it.
   * @param value
   */
  onBoxRadio(value: any) {
    this.beeFormControl.setValue(value);
    this.beeFormItemChange.emit(this.beeFormControl.value);
  }

  /**
   * Clear the beeFormControl value and emits the change.
   */
  clearValue() {
    this.beeFormControl.setValue(null);
    this.beeFormItemChange.emit(this.beeFormControl.value);
  }

  /**
   * Helper function to check if a control is required.
   * @param abstractControl
   */
  private checkIfRequired(abstractControl: AbstractControl) {
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
  }

  /**
   * Displays the error message. Supports required length (min, max), IBAN, NIF, modelExist, etc.
   * @param formControl
   */
  errorMessage(formControl: FormControl | AbstractControl) {
    if (formControl.errors['required']) {
      return this.requiredText;
    } else if (formControl.errors['min']) {
      return 'Min ' + this.valueText + formControl.errors['min']['min'];
    } else if (formControl.errors['max']) {
      return 'Max ' + this.valueText + +formControl.errors['max']['max'];
    } else if (formControl.errors['minlength']) {
      return 'Min ' + formControl.errors['minlength']['requiredLength'] + this.characterText;
    } else if (formControl.errors['maxlength']) {
      return 'Max. ' + formControl.errors['maxlength']['requiredLength'] + this.characterText;
    } else if (formControl.errors.pattern && this.type === 'email') {
      return this.emailNotValidText;
    } else if (formControl.errors.invalidCifNifNieTr) {
      return this.nifNotValidText;
    } else if (formControl.errors.invalidIBAN) {
      return this.ibanNotValidText;
    } else if (formControl.errors.modelExists) {
      if (this.modelExistErrorMessage) {
        return this.modelExistErrorMessage;
      } else {
        return this.label + this.alreadyInUseText;
      }
    }
  }
}
