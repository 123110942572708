import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { Notification } from '@shared/models';

/**
 * BeeNotificationDetailsComponent, the details of the notification.
 */
@Component({
  selector: 'bee-notification-details',
  templateUrl: 'bee-notification-details.component.html',
  styleUrls: ['./bee-notification-details.component.scss'],
})
export class BeeNotificationDetailsComponent {
  /** The notification. */
  notification: Notification;

  /**
   * Constructor.
   * @param dialogRef
   * @param router
   * @param data
   */
  constructor(private dialogRef: MatDialogRef<BeeNotificationDetailsComponent>,
              private router: Router,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.notification = data.notification;
  }

  /** Navigates to the notification action. */
  onNotificationAction() {
    this.router.navigate([this.notification.actionRoute], {relativeTo: this.data.activatedRoute});
    this.dialogRef.close();
  }

  /** Navigates to the notification list. */
  onNotificationList() {
    this.router.navigate([this.data.notificationsRoute], {relativeTo: this.data.activatedRoute});
    this.dialogRef.close();
  }

  /** Closes the dialog. */
  closeDialog(): void {
    this.dialogRef.close();
  }
}
