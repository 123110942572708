<ng-container *ngIf="insideModal && closeContainerEnabled">
  <div class="close-container">
        <span class="close-box">
            <mat-icon svgIcon="close" (click)="onClose()"></mat-icon>
        </span>
  </div>
</ng-container>
<div class="bee-form-container" [ngClass]="{'bee-modal-form': insideModal, 'full-height-container': fullHeight}">
  <h2 *ngIf="insideModalTitle" class="inside-modal-title"
      [ngClass]="{'primary-colored-title': primaryColoredTitle, 'centered-title': centeredTitle}">
    {{ insideModalTitle }}</h2>

  <div class="form-header"
       *ngIf="title || (backEnabled && !insideModal) || (requiredSign && hasRequiredFields && !insideModal)"
       [ngClass]="{'form-without-padding': formWithoutPadding}">
    <div class="back-box">
      <div class="back-container" *ngIf="backEnabled && !insideModal" (click)="onBack()">
        <mat-icon svgIcon="chevron-left"></mat-icon>
        {{ backText }}
      </div>
    </div>
    <div *ngIf="title" class="form-title-box">
      <div class="form-title"
           [ngClass]="{'primary-colored-title': primaryColoredTitle, 'centered-title': centeredTitle}" *ngIf="title">
        <span>{{ title }}</span>
      </div>
    </div>
    <div class="tip-box">
      <div *ngIf="requiredSign && hasRequiredFields && !insideModal"
           class="required-fields-tip foreground-secondary-text-color">
        {{ requiredText }}
      </div>
    </div>
  </div>

  <form (keydown.enter)="checkPreventDefault($event)" [id]="id" [formGroup]="beeFormGroup" (ngSubmit)="onSubmit()"
        [ngClass]="{'form-without-padding': formWithoutPadding}"
        [fxLayoutGap]="layoutGap"
        [ngStyle]="{'margin-bottom.px': extraMarginBottom}">

    <ng-content></ng-content>

    <div *ngIf="error" fxFlex="100" class="center-align warn-text error-message">{{ errorMessage }}</div>

    <span *ngIf="requiredSign && hasRequiredFields && insideModal"
          class="required-fields-tip foreground-secondary-text-color">
               {{ requiredText }}
    </span>

    <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center" class="button-container"
         *ngIf="showButtons">

      <button *ngIf="cancelButtonText" mat-button [color]="cancelButtonColor" (click)="onCancel()" id="cancelButton">
        {{ cancelButtonText }}
      </button>

      <button *ngIf="editableForm && formDisabled && method == 'patch' && editEnabled"
              mat-raised-button [color]="submitButtonColor" (click)="onEdit()">
        <mat-icon svgIcon="pencil"></mat-icon>
        {{ editButtonText }}
      </button>

      <ng-container *ngIf="!editableForm || !formDisabled">
        <!-- Delete Button -->
        <button mat-button [color]="deleteButtonColor" id="delete-button" *ngIf="method == 'patch' && deleteEnabled"
                type="button" (click)="openConfirmDelete()">
          <mat-icon *ngIf="buttonIconsEnabled" [svgIcon]="deleteButtonIcon"></mat-icon>
          {{ deleteButtonText }}
        </button>
        <!-- Save/Edit Button -->
        <button mat-button [color]="submitButtonColor" *ngIf="editEnabled && !submitting && !primaryRaisedButton"
                [id]="id+'submit-button'"
                class="save-button {{submitButtonClasses}}"
                [ngClass]="{'disabled-submit': !beeFormGroup.valid || disabledClauses}"
                [@submitState]="submitAnimationEnabled && (beeFormGroup.valid ? 'valid' : 'invalid')"
                [disabled]="disabledClauses">
          <mat-icon svgIcon="download" *ngIf="method == 'post' && buttonIconsEnabled"></mat-icon>
          <mat-icon svgIcon="pencil" *ngIf="method == 'patch' && buttonIconsEnabled"></mat-icon>
          {{ saveButtonText }}
          <span class="invalid-form" *ngIf="!beeFormGroup.valid && requiredButtonSign">*</span>
        </button>

        <button mat-raised-button [color]="submitButtonColor"
                *ngIf="editEnabled && !submitting && primaryRaisedButton"
                [id]="id+'submit-button'"
                class="save-button {{submitButtonClasses}}"
                [ngClass]="{'disabled-submit': !beeFormGroup.valid || disabledClauses}"
                [@submitState]="submitAnimationEnabled && (beeFormGroup.valid ? 'valid' : 'invalid')"
                [disabled]="disabledClauses">
          <mat-icon svgIcon="download" *ngIf="method == 'post' && buttonIconsEnabled"></mat-icon>
          <mat-icon svgIcon="pencil" *ngIf="method == 'patch' && buttonIconsEnabled"></mat-icon>
          {{ saveButtonText }}
          <span class="invalid-form" *ngIf="!beeFormGroup.valid && requiredButtonSign">*</span>
        </button>

        <mat-spinner *ngIf="submitting" [diameter]="36"></mat-spinner>

      </ng-container>
    </div>
  </form>
</div>
