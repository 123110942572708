import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';

import { AuthenticationService } from '@shared/services/authentication.service';;

import { BeeSidebarElement } from './bee-sidebar-element/bee-sidebar-element';

@Component({
  selector: 'bee-sidebar',
  templateUrl: './bee-sidebar.component.html',
  styleUrls: ['./bee-sidebar.component.scss']
})
export class BeeSidebarComponent implements OnInit, OnChanges {
  @Input() displaySidebar = true;
  @Input() abilities;
  @Input() imageIcon = false;
  @Input() helpMessage = '';
  @Input() collapseTooltip = 'Minimizar';
  @Input() expandTooltip = 'Desplegar';
  @Input() backgroundColor = '';
  @Input() contentColor: string;
  @Input() collapseRow = true;
  @Input() fullHeight = false;
  @Input() headerHeight = 60;
  @Input() hideaway = false; // if true the sidebar can be completely hidden at the left of the page
  @Input() hideawayButton = false; // if true it displays a button to click on it to display sidebar
  @Input() collapsedWidth = 58; // it is set to 0 if hideaway is true (at ngOnInit())
  @Input() expandedWidth = 250;
  @Input() widthsAsPercentages = false;
  @Input() swippable = false; // if true the sidebar displayed or hided by swipping
  @Input() sidebarId = 'sidebar';
  @Input() customElementClass: string;
  @Input() sidebarSide: 'left' | 'right' = 'left';
  @ContentChild('sidebarHeader') sidebarHeader: TemplateRef<ElementRef>;
  @ContentChild('sidebarExtraContent') sidebarExtraContent: TemplateRef<ElementRef>;
  @ContentChild('sidebarHideawayButton') sidebarHideawayButton: TemplateRef<ElementRef>;

  @Output() readonly collapse: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() readonly helpTrigger: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() readonly clickSidebarElement: EventEmitter<any> = new EventEmitter<any>();

  elementClicked: number;
  elementHeaderClicked = false;
  sidebarElements: BeeSidebarElement[] = [];

  constructor(private authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    // this.createSubElements();
  }

  ngOnChanges($event: SimpleChanges) {
    if ($event['abilities']) {
      this.sidebarElements = [];
      this.createSubElements();
    }
  }

  /**
   * Create the sidebar elements, checks the abilities array
   */
  createSubElements() {
    for (const key of Object.keys(this.abilities)) {
      const element = this.abilities[key];

      if (this.checkAbilities(element['abilities']) || !element['abilities']) {
        const sidebarElement = new BeeSidebarElement();
        sidebarElement.name = element['name'];
        sidebarElement.route = element['route'];
        sidebarElement.icon = element['icon'];

        if (element['subElements']) {
          if (element['subElements'].length > 1) {
            const subElements = [];
            for (const subElement of element['subElements']) {
              if (this.checkAbilities(subElement['abilities']) || !subElement['abilities']) {
                subElements.push(subElement);
              }
            }
            sidebarElement.subElements = subElements;
          }
        }
        if (element['isDisabled']) {
          sidebarElement.isDisabled = element['isDisabled'];
        }
        if (element['imageIcon']) {
          sidebarElement.imageIcon = element['imageIcon'];
        }
        if (element['isLastItem']) {
          sidebarElement.isLastItem = element['isLastItem'];
        }
        if (element['borderTop']) {
          sidebarElement.borderTop = element['borderTop'];
        }
        if (element['borderBottom']) {
          sidebarElement.borderBottom = element['borderBottom'];
        }
        if (element['reverseOrder']) {
          sidebarElement.reverseOrder = element['reverseOrder'];
        }
        if (element['collapseOnClick']) {
          sidebarElement.collapseOnClick = element['collapseOnClick'];
        }
        this.sidebarElements.push(sidebarElement);
      }
    }
  }

  /**
   * Checks if the user has the ability/abilities to show the sidebarElement
   * @param abilities
   * @return boolean
   */
  private checkAbilities(abilities: string[]): boolean {
    if (abilities) {
      for (const ability of abilities) {
        if (this.authenticationService.isSetAbility(ability)) {
          return true;
        }
      }
    }

    return false;
  }

  onHelp() {
    this.helpTrigger.emit(true);
  }

  onClickElement(i: number, sidebarElement) {
    if (!this.sidebarElements[i].isDisabled) {
      (this.elementClicked === i && this.elementHeaderClicked) ? this.elementClicked = 0 : this.elementClicked = i;
    }
    this.clickSidebarElement.emit(sidebarElement);
  }

  /**
   * Sets the element clicked
   * @param result
   */
  handleHeaderClicked(result: boolean) {
    this.elementHeaderClicked = result;
  }

  onCollapseSidebar() {
    this.displaySidebar = !this.displaySidebar;
    this.collapse.emit(this.displaySidebar);
  }

  onShowBackdrop() {
    if (!this.displaySidebar && this.swippable) {
      this.onCollapseSidebar();
    }
  }

  onHideBackdrop() {
    if (this.displaySidebar && this.swippable) {
      this.onCollapseSidebar();
    }
  }
}
