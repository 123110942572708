import { Directive, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

/**
 * NewModalFormComponent class. Abstract class that represents a new form inside a matDialog.
 *
 * @example
 *
 * export class NewExampleComponent extends NewModalForm implements OnInit {
 *
 *    constructor(private formBuilder: FormBuilder,
 *                protected dialogRef: MatDialogRef<NewExampleComponent>) {
 *        super(dialogRef);
 *
 *        this.myForm = formBuilder.group({
 *            name: ['', Validators.required],
 *        });
 *    }
 * }
 */
@Directive()
export abstract class NewModalForm implements OnInit {
  /** The FormGroup. */
  myForm: FormGroup;
  /** Submit emitter. */
  postSubmitEmitter: EventEmitter<any> = new EventEmitter();
  /** The top position of the modal, set null to center. */
  top = '0';
  /** The right position of the modal, set null to center. */
  right = '0';

  /**
   * Constructor.
   * @param dialogRef
   */
  protected constructor(protected dialogRef: MatDialogRef<any>) {
  }

  /**
   * OnInit lifecycle, updates the dialog position.
   */
  ngOnInit() {
    this.dialogRef.updatePosition({top: this.top, right: this.right});
  }

  /**
   * Emits the submit event and closes the dialog.
   * @param $event
   */
  onPostSubmit($event?: any) {
    this.postSubmitEmitter.emit($event);
    this.closeDialog();
  }

  /**
   * Closes the dialog.
   */
  closeDialog(): void {
    this.dialogRef.close();
  }
}
