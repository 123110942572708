import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HammerModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '@shared/angular-material';

import { BeeSidebarElementComponent } from './bee-sidebar-element/bee-sidebar-element.component';
import { BeeSidebarComponent } from './bee-sidebar.component';

@NgModule({
  declarations: [
    BeeSidebarComponent,
    BeeSidebarElementComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FlexLayoutModule,
    HammerModule
  ],
  exports: [
    BeeSidebarComponent,
    BeeSidebarElementComponent
  ],
  providers: [
  ]
})
export class BeeSidebarModule { }
