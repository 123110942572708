import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Group } from '@shared/models/group';

// @Injectable({providedIn: 'root'})
@Injectable()
export class GroupsService {
  private groups: any[] = [];
  groupsChanged = new Subject<Group[]>();
  private group: any;
  constructor() {}
  groupsList = [];

  setGroups(groups) {
    this.groups = groups;
    this.groupsChanged.next(this.groups.slice());
    this.groupList(this.groups);

    return this.groups;
  }

  getGroups() {
    return this.groups.slice();
  }

  addNewGroup(group) {
    this.groups.push(group);
    this.groupsChanged.next(this.groups.slice());
    this.groupList(this.groups);
  }

  editGroup(group, id) {
    for (const i in this.group) {
      if (this.group[i].id === id) {
      }
    }
    this.groupsChanged.next(this.group);
    this.getGroups();
  }

  editSetGroup(group) {
    this.group = group;

    return this.group;
  }

  editGetGroup() {
    return this.group;
  }

  groupList(groups) {
    this.groups = groups;
    this.groupsList = [];
    for (this.group of this.groups) {
      this.groupsList.push({name: this.group.name, location: this.group.location, id: this.group.id});
    }

    return this.groupsList;
  }
}
