import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

/**
 * PWAService, manages the PWA installation.
 */
@Injectable()
export class PwaService {
  /**
   * Prompt event listener variable.
   * @type {any}
   */
  promptEvent: any;

  /**
   * Constructor, install the app or reloads if new version is available.
   * @param swUpdate
   */
  constructor(private swUpdate: SwUpdate) {
    swUpdate.available.subscribe(event => {
      if (confirm('Hay una nueva versión de la aplicación disponible. Recarga la página para actualizar a la nueva versión')) {
        window.location.reload();
      } else {
        // window.location.reload();
      }
    });

    window.addEventListener('beforeinstallprompt', event => {
      this.promptEvent = event;
    });
  }
}
