import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { Notification } from '@shared/models';
import { ApiService } from '@shared/services/api.service';

import { BeeNotificationDetailsComponent } from './dialogs/bee-notification-details.component';

/**
 * BeeNotificationsService, opens NotificationDetails in dialog and manages the unread count.
 */
@Injectable()
export class BeeNotificationsService {
  /** The unreadNotifications array. Date Format: date:'dd/MM/yyyy H:mm:ss' */
  unreadNotifications: Notification[] = [];
  /** Emits if the notifications count changed. */
  notificationsCountEmitter: EventEmitter<number> = new EventEmitter<number>();
  /** The checkingNotifications Subscription. */
  checkingNotifications$: Subscription;
  /** The notifications Subscription. */
  notifications$: Subscription;
  /** The notifications interval check. */
  notificationsInterval: any;
  /** The unread notifications count. */
  private _unreadNotificationsCount = 0;

  /**
   * Constructor.
   * @param apiService
   * @param matDialog
   */
  constructor(private apiService: ApiService,
              private matDialog: MatDialog) {
  }

  /**
   * Get the unreadNotificationsCount.
   */
  public get unreadNotificationsCount(): number {
    return this._unreadNotificationsCount;
  }

  /**
   * Start the notifications count.
   */
  startNotifications() {
    this._unreadNotificationsCount = 0;
    this.notifications$ = this.apiService.get('notifications/unread/count').subscribe(
      data => {
        this._unreadNotificationsCount = data;
      },
      err => {
        console.error(err);
      });

    this.notificationsInterval = setInterval(() => {
      this.checkingNotifications$ = this.apiService.get('notifications/unread/count', null, null, null, null, null, null, false).subscribe(
        data => {
          this._unreadNotificationsCount = data;
        },
        err => {
          console.error(err);
        });
    }, 25000);
  }

  /**
   * Get the unread notifications for the current user.
   */
  getUnreadNotifications() {
    this.unreadNotifications = [];
    this.apiService.get('notifications/unread', null, null, null, 'createdAt', 'desc').subscribe(
      data => {
        for (const notification of data) {
          this.unreadNotifications.push(new Notification(notification));
        }
        this._unreadNotificationsCount = data.length;
      });
  }

  /**
   * Click on notification, open the details.
   * @param notification
   * @param data
   */
  openNotification(notification: Notification, data: any) {
    const notificationWasRead = notification.isRead;
    if (!notification.isRead) {
      this.apiService.patch('notifications/' + notification.id + '/mark-as-read', '').subscribe(
        () => {
          notification.isRead = true;
          if (this._unreadNotificationsCount > 0) {
            this._unreadNotificationsCount--;
          }
        });
    }
    this.matDialog.open(BeeNotificationDetailsComponent, {
      width: '600px',
      minWidth: '320px',
      data: {
        notification: notification,
        notificationWasRead: notificationWasRead,
        notificationsListLabel: data.notificationsListLabel,
        notificationsRoute: data.notificationsRoute,
        activatedRoute: data.activatedRoute,
      }
    });
  }

  /**
   * Marks all notifications as read.
   */
  markAllAsRead() {
    this.apiService.get('notifications/unread/mark-as-read').subscribe(
      () => {
        for (const notification of this.unreadNotifications) {
          notification.isRead = true;
        }
        this._unreadNotificationsCount = 0;
      });
  }

  /**
   * Clear the subscriptions.
   */
  clearSubscriptions() {
    if (this.notifications$) {
      this.notifications$.unsubscribe();
    }
    if (this.checkingNotifications$) {
      this.checkingNotifications$.unsubscribe();
    }
    if (this.notificationsInterval) {
      clearInterval(this.notificationsInterval);
    }
  }
}
