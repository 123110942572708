import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '@shared/services/authentication.service';;

/**
 * Class AppGuard, authorization
 */
@Injectable()
export class AppGuard implements CanActivate, CanActivateChild {
  /**
   * Constructor
   * @param router
   * @param _authenticationService
   */
  constructor(private router: Router, private _authenticationService: AuthenticationService) {
  }

  /**
   * CanActivate Method
   * @param route
   * @param state
   * @returns {boolean}
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkAbility(route.data, state.url);
  }

  /**
   * CanActivateChild method
   * @param route
   * @param state
   * @returns {boolean}
   */
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  /**
   * Checks an ability
   * @param data
   * @param url
   * @returns {boolean}
   */
  checkAbility(data: any, url: string): boolean {
    if (this._authenticationService.token) {
      if (data['abilities']) {
        for (const ability of data['abilities']) {
          if (this._authenticationService.isSetAbility(ability)) {
            return true;
          }
        }
        this.router.navigate(['/app/sin-autorizacion']);

        return false;
      } else {
        return true;
      }
    }
    // Store the attempted URL for redirecting
    // this.authService.redirectUrl = url;

    // Navigate to the login page
    this.router.navigate(['/login']);

    return false;
  }
}
