import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { Notification } from '@shared/models';
import { ApiService, AuthenticationService } from '@shared/services';

import { BeeNotificationsService } from '../bee-notifications.service';
import { BeeNewNotificationComponent } from '../dialogs/bee-new-notification.component';

/**
 * NotificationsComponent, a default list for notifications.
 */
@Component({
  selector: 'bee-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  /** The notifications. */
  notifications: Notification[] = [];
  /** Checks if the notifications are loaded. */
  loaded = false;
  /** The initialNotificationsCount. */
  initialNotificationsCount = 0;
  /** The default pageSize. */
  pageSize = 10;
  /** The default currentPage. */
  currentPage = 1;
  /** The default totalPages. */
  totalPages = 1;
  /** The default length. */
  length = 100;
  /** The default orderBy. */
  orderBy = 'createdAt';
  /** The default sortOrder. */
  sortOrder = 'desc';
  /** Checks if the search is active. */
  searchActive = false;
  /** The default searchSubject. */
  searchSubject: Subject<string> = new Subject<string>();
  /** The default currentQuery. */
  currentQuery = '';
  /** Data for the notification details modal. */
  notificationDetailsData: any;
  /** Check if the user can create notifications. */
  canCreate = false;

  /**
   * Constructor
   * @param authenticationService
   * @param apiService
   * @param activatedRoute
   * @param matDialog
   * @param location
   * @param beeNotificationsService
   */
  constructor(private authenticationService: AuthenticationService,
              private apiService: ApiService,
              private activatedRoute: ActivatedRoute,
              private matDialog: MatDialog,
              private location: Location,
              public beeNotificationsService: BeeNotificationsService) {
    this.searchSubject
      .pipe(
        debounceTime(600),
        distinctUntilChanged()
      )
      .subscribe(query => this.search(query));

    this.notificationDetailsData = {
      notifications_list_label: 'Historial de notificaciones',
      notifications_route: 'notificaciones',
      activated_route: this.activatedRoute.parent,
    };
  }

  /**
   * OnInit lifecycle.
   */
  ngOnInit() {
    window.scroll(0, 0);
    this.canCreate = this.authenticationService.isSetAbility(`create-notifications`);
    this.updateData(true);
  }

  /**
   * Get notifications.
   * @param initial_call
   */
  updateData(initial_call: boolean = false) {
    this.apiService.get('notifications', this.currentQuery, this.pageSize, this.currentPage, this.orderBy, this.sortOrder).subscribe(
      result => {
        this.pageSize = result['perPage'];
        this.totalPages = result['lastPage'];
        this.length = result['total'];
        this.notifications = [];

        for (const notification of result['data']) {
          this.notifications.push(new Notification(notification));
        }
        if (initial_call) {
          this.initialNotificationsCount = this.notifications.length;
          this.loaded = true;
        }
      });
  }

  /**
   * Search
   * @param query
   */
  search(query: string) {
    this.currentPage = 1;
    query = query.trim();
    this.currentQuery = query;
    this.updateData();
  }

  /**
   * Activates search
   */
  onActivateSearch() {
    this.searchActive = !this.searchActive;
  }

  /**
   * Empty search
   */
  onEmptySearch() {
    this.currentPage = 1;
    this.currentQuery = '';
    this.updateData();
  }

  /**
   * Sorts by header
   * @param header
   */
  onSort(header: string) {
    if (header === this.orderBy) {
      if (this.sortOrder === 'desc') {
        this.sortOrder = 'asc';
      } else {
        this.sortOrder = 'desc';
      }
    } else {
      this.orderBy = header;
      this.sortOrder = 'desc';
    }
    this.updateData();
  }

  /**
   * Page event
   * @param event
   */
  pageEvent(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.length = event.length;
    this.updateData();
  }

  /** Creates a new Notification */
  createNotification() {
    this.matDialog.open(BeeNewNotificationComponent, {
      width: '600px',
      minWidth: '320px',
      height: '520px',
    });
  }

  /**
   * Returns to previous location
   */
  onBack() {
    this.location.back();
  }
}
