import { Component, EventEmitter, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ApiService } from '@shared/services/api.service';

/**
 * BeeConfirmDelete, confirms the delete of an element.
 *
 * @example
 *
 * matDialog.open(BeeConfirmDeleteComponent, {
 *  width: '500px',
 *  autoFocus: false,
 *  data: {
 *    deleteUrlRoute: apiUrl,
 *    deleteModalObjectName: 'al descuento',
 *    deleteButtonText: 'Eliminar descuento',
 *    backText: 'Volver',
 *    deleteReasonEnabled: false,
 *    deleteToastMessage: 'Descuento eliminado con éxito',
 *    deleteWarningText: '¡Cuidado!',
 *    deleteWarningDescription: 'Si confirmas esta acción se borrarán permanentemente todos los datos relativos'
 *  }
 * });
 */
@Component({
  selector: 'bee-confirm-delete',
  templateUrl: 'bee-confirm-delete.component.html',
  styleUrls: ['./bee-confirm-delete.component.scss'],
})
export class BeeConfirmDeleteComponent {
  /**
   * Delete formGroup, contains the deleteReason and keys. (multiple delete)
   * @type {FormGroup}
   */
  deleteFormGroup: FormGroup;
  /**
   * Delete route.
   * @type {string}
   */
  deleteUrlRoute = '';
  /**
   * Delete toast message.
   * @type {string}
   */
  deleteToastMessage = '';
  /**
   * Post delete emitter, emits when the element/s are deleted.
   * @type {EventEmitter<any>}
   */
  postDeleteEmitter: EventEmitter<any> = new EventEmitter();
  /**
   * Error message.
   * @type {any}
   */
  error: any;
  /**
   * Close text. (i18n)
   * @type {string}
   */
  closeText = 'Cerrar';
  /**
   * Show the button icons
   * @type {boolean}
   */
  displayButtonIcons = true;

  /**
   * Constructor.
   * @param dialogRef
   * @param formBuilder
   * @param apiService
   * @param snackBar
   * @param data The dialog injected data.
   */
  constructor(private dialogRef: MatDialogRef<BeeConfirmDeleteComponent>,
              private formBuilder: FormBuilder,
              private apiService: ApiService,
              private snackBar: MatSnackBar,
              @Inject(MAT_DIALOG_DATA) public data: any) {

    this.deleteFormGroup = formBuilder.group({
      deleteReason: [''],
      keys: ['']
    });

    this.deleteUrlRoute = this.data.deleteUrlRoute;
    this.deleteToastMessage = this.data.deleteToastMessage;
    if (data.hasOwnProperty('closeText')) {
      this.closeText = data.closeText;
    }
    if (data.hasOwnProperty('keys')) {
      this.deleteFormGroup.controls['keys'].setValue(data.keys);
    }
    if (data.hasOwnProperty('displayButtonIcons')) {
      this.displayButtonIcons = data.displayButtonIcons;
    }
  }

  /**
   * ConfirmDelete, deletes the element and shows a snackbar.
   */
  onConfirmDelete() {
    if (this.deleteUrlRoute) {
      this.apiService.delete(this.deleteUrlRoute, this.deleteFormGroup.value).subscribe(
        () => {
          if (this.deleteToastMessage) {
            this.snackBar.open(this.deleteToastMessage, this.closeText, {duration: 6000});
          }
          this.postDeleteEmitter.emit();
          this.dialogRef.close();
        },
        error => {
          this.error = error as any;
        });
    } else {
      if (this.deleteToastMessage) {
        this.snackBar.open(this.deleteToastMessage, this.closeText, {duration: 6000});
      }
      this.postDeleteEmitter.emit();
      this.dialogRef.close();
    }
  }

  /**
   * Closes the dialog.
   */
  closeDialog(): void {
    this.dialogRef.close();
  }
}
