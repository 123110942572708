import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Product } from '@shared/models/product';

@Injectable()
export class WorkersService {
  private workers: any[] = [];
  private workerPush: any = {};
  workersChanged = new Subject<Product[]>();
  private roles: any[] = [];
  rolesChanged = new Subject<Product[]>();
  private worker: any;
  public workersId: any;
  public l: any;
  workersList = [];
  workerRole: any;

  constructor() {}

  setWorkers(workers) {
    this.workers = workers;
    this.workers = this.transformWorkers(this.workers);
    this.workersChanged.next(this.workers);
    this.workerList(this.workers);

    return this.workers;
  }

  getWorkers() {
    return this.workers.slice();
  }

  transformWorkers(workers) {
    this.workers = workers;
    this.workersId = Object.keys(this.workers);
    const workers2 = [];
    this.l = this.workersId.length;
    for (let i = 0; i < this.l; i++) {
      workers2[i] = this.workers[this.workersId.shift()];
    }
    this.workers = workers2;

    return this.workers;
  }

  setRoles(roles) {
    this.roles = roles;
    this.rolesChanged.next(this.roles.slice());

    return this.roles;
  }

  getRoles() {
    return this.roles.slice();
  }

  addNewWorker(worker) {
    const staffmember: any = {};
    const staffuser: any = {};
    staffmember.name = worker.name;
    staffmember.surname = worker.surname;
    staffmember.id = worker.id;
    staffmember.password = worker.password;
    staffmember.roleId = worker.roleId.id;
    staffuser.name = worker.username;
    this.workerPush = {staffmember: staffmember, staffuser: staffuser};
    this.workers.push(this.workerPush);
    this.workersChanged.next(this.workers.slice());
    this.workerList(this.workers);
  }

  editSetWorker(worker) {
    this.worker = worker;

    return this.worker;
  }

  editGetWorker() {
    return this.worker;
  }

  workerList(workers) {
    this.workers = workers;
    this.workersList = [];
    this.roles = this.getRoles().slice();
    for (this.worker of this.workers) {
      for (const role of this.roles) {
        if (role.id === this.worker.staffmember.roleId) {
          this.workerRole = role.name;
        }
      }
      if (this.worker.staffmember.profileImageUrl !== undefined) {
        this.workersList.push({name: this.worker.staffmember.name, surname: this.worker.staffmember.surname,
          username: this.worker.staffuser[0].name, profileImageUrl: this.worker.staffmember.profileImageUrl,
          password: this.worker.staffmember.password, id: this.worker.staffmember.id, role: this.workerRole});
      } else {
        this.workersList.push({name: this.worker.staffmember.name, surname: this.worker.staffmember.surname,
          username: this.worker.staffuser.name, profileImageUrl: ' ',
          password: this.worker.staffmember.password, id: this.worker.staffmember.id, role: this.workerRole});
      }
    }

    return this.workersList;
  }
}
