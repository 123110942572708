import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '@shared/angular-material';

import { BeeFormModule } from '@bee-components/bee-form';
import { BeePipesModule } from '@bee-components/common/bee-pipes';

import { BeeNewNotificationComponent } from '../dialogs/bee-new-notification.component';
import { BeeNotificationDetailsComponent } from '../dialogs/bee-notification-details.component';
import { BeeNotificationsComponent } from '../dialogs/bee-notifications.component';
import { NotificationsRoutingModule } from './notifications-routing.module';
import { NotificationsComponent } from './notifications.component';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [
    NotificationsComponent,
    BeeNewNotificationComponent,
    BeeNotificationDetailsComponent,
    BeeNotificationsComponent
  ],
  imports: [
    NotificationsRoutingModule,
    BeeFormModule,
    MaterialModule,
    FlexLayoutModule,
    BeePipesModule,
    CommonModule
  ]
})
export class NotificationsModule {
}
