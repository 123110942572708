import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { PageEvent } from '@angular/material/paginator';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ApiService, AuthenticationService } from '@shared/services';

import { RequiredFields } from '@bee-components/bee-form/bee-selector/required-field';
import { BEE_FORM_ITEM_DEFAULT_APPEARANCE } from '@bee-components/common/bee-forms';

import { showSelectorStateTrigger } from './animations';
import { Option } from './option';

/**
 * Class BeeSelector
 *
 * @example
 *
 * <bee-selector
 *      placeholder="Seleccionar transportista"
 *      [label]="'Transportista'"
 *      [route]="'transporters'"
 *      [allowClear]="false"
 *      [preload]="true"
 *      [formKey]="'id'"
 *      [formShow]="['id','fullName']">
 * </bee-selector>
 */
// tslint:disable-next-line:no-conflicting-lifecycle
@Component({
  selector: 'bee-selector',
  templateUrl: './bee-selector.component.html',
  styleUrls: ['./bee-selector.component.scss'],
  animations: [showSelectorStateTrigger],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BeeSelectorComponent implements OnInit, OnDestroy, OnChanges, DoCheck {
  /** The id of the BeeSelectorComponent. */
  @Input() id: string = 'id-' + Math.floor((Math.random() * 1000) + 1);
  /** The Form Control. */
  @Input() beeFormControl: AbstractControl | FormControl = new FormControl();
  /** Pass options to make operations (search, select...) with them. */
  @Input() options: any[];
  /** The appearance of the formControl. (Outline, standard, legacy, fill)  */
  @Input() appearance: MatFormFieldAppearance;
  @Input() placeholder = '';
  @Input() icon = '';
  @Input() route = '';
  @Input() autoSelect = true;
  @Input() formKey: string;
  @Input() formShow: string[] = [];
  @Input() formShowDates: string[] = [];
  @Input() itemIconKey;
  @Input() label = '';
  @Input() isDisabled = false;
  @Input() allowClear = true;
  @Input() defaultValue: any = null;
  @Input() backgroundLabel = false;
  @Input() requiredText = 'Este campo es obligatorio';
  @Input() requiredSign = true;
  @Input() noResultsText = 'No se han encontrado resultados.';
  @Input() errorMessage = 'Ha ocurrido un error inesperado.';
  @Input() searchPlaceholder = 'Buscar...';
  @Input() pageSizeOptions: number[] = [5, 10, 25];
  @Input() pageSize = 5;
  @Input() allResultsEnabled = true;
  @Input() insideModal = false;
  @Input() orderBy: string = null;
  @Input() sortOrder: string = null;
  @Input() ability: string = null;
  /** Creation Inputs. */
  @Input() allowCreation = false;
  @Input() requiredFields: RequiredFields;
  @Input() createButtonLabel = 'Añadir elemento';
  /** Clears the selector after select a value and emit its value. */
  @Input() clearAfterSelect = false;
  @Input() hiddenOptions: any[] = [];
  @Output() readonly beeSelectorChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() readonly optionSelected: EventEmitter<Option> = new EventEmitter<Option>();
  @ViewChild('selector') selectorRef: ElementRef;
  @ViewChild('filterInput') inputRef: ElementRef;
  @ViewChild('optionsList') optionsListRef: ElementRef;

  isRequired = false;
  private KEYS: any = {
    BACKSPACE: 8,
    TAB: 9,
    ENTER: 13,
    ESC: 27,
    SPACE: 32,
    UP: 38,
    DOWN: 40
  };
  error = '';
  selectedOption: Option = null;
  private highlightedOption: Option = null;
  selectorValues: Option[] = [];
  searchSubject: Subject<string> = new Subject<string>();
  apiSubscription: Subscription;
  currentQuery = '';
  searchText = '';

  // MatPaginator Inputs
  currentPage = 1;
  totalPages = 1;
  length = 100;
  show = false;
  loadingData = false;
  private defaultLoaded = false;

  private selectorClicked = false;
  private optionsContainer = false;
  isSetAbilityToSelect = true;
  width: number;
  destroying = false;
  creating = false;
  selectAfterCreate = '';

  /**
   * Constructor.
   * @param apiService
   * @param authenticationService
   * @param changeDetector
   * @param defaultAppearance
   */
  constructor(private apiService: ApiService,
              private authenticationService: AuthenticationService,
              private changeDetector: ChangeDetectorRef,
              @Optional() @Inject(BEE_FORM_ITEM_DEFAULT_APPEARANCE) private defaultAppearance: MatFormFieldAppearance) {
    (defaultAppearance) ? this.appearance = defaultAppearance : this.appearance = 'outline';
    this.searchSubject
      .pipe(
        debounceTime(600),
        distinctUntilChanged()
      )
      .subscribe(query => this.search(query));

    // Real time value for creation
    this.searchSubject.pipe().subscribe(query => this.searchText = query);
  }

  /**
   * Detect the changes if the component is not being destroyed
   */
  private detectChanges() {
    if (!this.destroying) {
      this.changeDetector.detectChanges();
    }
  }

  ngOnInit() {
    if (this.requiredSign) {
      this.checkIfRequired(this.beeFormControl);
    }
    if (this.beeFormControl.value) {
      this.selectValue(this.beeFormControl.value);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.defaultLoaded && this.defaultValue && !this.beeFormControl.value) {
      this.selectValue(this.defaultValue);
      this.defaultLoaded = true;
    } else if (changes['beeFormControl'] && !changes['beeFormControl'].firstChange) {
      this.selectValue(changes['beeFormControl'].currentValue.value);
    } else if (!(changes['label'] || changes['requiredText'] || changes['noResultsText'] || changes['errorMessage'] ||
      changes['searchPlaceholder'] || changes['placeholder'] || changes['id'])) {
      this.clearSelection();
    } else if (changes['ability']) {
      this.isSetAbilityToSelect = this.authenticationService.isSetAbility(changes['ability'].currentValue);
    }
  }

  ngDoCheck(): void {
    this.detectChanges();
  }

  private checkIfRequired(abstractControl: AbstractControl) {
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        this.isRequired = true;
      }
    }
  }

  onSelectorClick() {
    this.selectorClicked = true;
    if (this.selectorValues.length === 0) {
      this.currentQuery = '';
      this.updateData();
    }
    this.show = !this.show;
    setTimeout(() => {
      if (this.show) {
        this.updateWidth();
        if (this.isSetAbilityToSelect) {
          this.inputRef.nativeElement.focus();
        }
      }
    }, 1);
  }

  onSelectorKeyDown(event: any) {
    if (!this.show) {
      const key = event.which;
      if (key === this.KEYS.ENTER || key === this.KEYS.SPACE || (key === this.KEYS.DOWN && event.altKey)) {
        this.show = true;
        if (this.selectorValues.length === 0) {
          this.currentQuery = '';
          this.updateData();
        }
        setTimeout(() => {
          this.updateWidth();
          if (this.isSetAbilityToSelect) {
            this.inputRef.nativeElement.focus();
          }
        }, 1);
      }
    }
  }

  onSearchKeyDown(event: any) {
    if (this.show) {
      const key = event.which;
      if (key === this.KEYS.ESC || key === this.KEYS.TAB || (key === this.KEYS.UP && event.altKey)) {
        this.show = false;
      } else if (key === this.KEYS.ENTER) {
        this.selectHighlightedOption();
      } else if (key === this.KEYS.UP) {
        this.highlightPreviousOption();
        this.moveHighlightedIntoView();
      } else if (key === this.KEYS.DOWN) {
        this.highlightNextOption();
        this.moveHighlightedIntoView();
      }
    }
  }

  onOptionsContainerClick() {
    this.optionsContainer = true;
  }

  search(query: string) {
    this.currentPage = 1;
    query = query.trim();
    this.currentQuery = query;
    this.updateData();
  }

  updateData(): void {
    if (this.isSetAbilityToSelect && !this.destroying) {
      if (this.options) {
        return this.loadOptions();
      }

      this.error = '';
      this.loadingData = true;
      this.apiSubscription = this.apiService.get(
        this.route, this.currentQuery, this.pageSize, this.currentPage, this.orderBy, this.sortOrder).subscribe(
        result => {
          this.loadOptions(result);
        },
        () => {
          this.error = this.errorMessage;
          this.loadingData = false;
        });
    }
  }

  loadOptions(apiResp?) {
    if (apiResp) {
      this.pageSize = apiResp['perPage'];
      this.totalPages = apiResp['lastPage'];
    }

    this.length = apiResp ? apiResp['total'] : this.options.length;
    if (this.length > 25 && this.length <= 50) {
      this.pageSizeOptions = [5, 10, 25, 50];
    } else if (this.length > 50 && this.length <= 100) {
      this.pageSizeOptions = [5, 10, 25, 50, 100];
    } else if (this.allResultsEnabled && this.length > 100) {
      this.pageSizeOptions = [5, 10, 25, 50, 100, this.length];
    }

    this.selectorValues = [];

    let iterableResults;
    if (this.pageSize && apiResp) {
      iterableResults = apiResp['data'];
    } else {
      if (this.options && this.currentQuery) {
        iterableResults = this.options.filter(option => {
          const match = this.formShow.filter(key => {
            const text = this.cleanString(option[key]);

            return text.search(this.cleanString(this.currentQuery)) !== -1;
          });

          return match.length > 0;
        });

        this.length = iterableResults.length;
      } else {
        iterableResults = apiResp ? apiResp : this.options;
      }
    }

    for (const row of iterableResults) {
      this.selectorValues.push(this.createOption(row));
    }
    if (this.selectorValues.length === 1 && this.currentPage === 1 &&
      (this.autoSelect || this.beeFormControl.value !== '')) {
      if (!this.hiddenOptions.includes(this.selectorValues[0].value)) {
        this.onOptionClick(this.selectorValues[0]);
      }
    }

    if (this.selectAfterCreate) {
      this.selectOption(null, this.selectAfterCreate);
      this.selectAfterCreate = '';
    }

    this.loadingData = false;
    this.detectChanges();
  }

  cleanString(str: string): string {
    return str.toLowerCase()
      .replace('á', 'a')
      .replace('é', 'e')
      .replace('í', 'i')
      .replace('ó', 'o')
      .replace('ú', 'u');
  }

  createOption(row: any): Option {
    const option = new Option();
    if (this.itemIconKey) {
      option.icon = row[this.itemIconKey];
    }
    option.value = row[this.formKey];
    // Label creation
    for (let i = 0; i < this.formShow.length; i++) {
      if (row[this.formShow[i]]) {
        let labelToAdd: string = row[this.formShow[i]];
        if (this.formShowDates.includes(this.formShow[i])) {
          labelToAdd = new DatePipe('es').transform(labelToAdd, 'dd/MM/yyyy H:mm');
        }
        if (i === 0 || !option.label) {
          option.label = labelToAdd;
        } else {
          option.label += ' - ' + labelToAdd;
        }
      }
    }

    return option;
  }

  writeValue(value: any) {
    if (this.beeFormControl.value !== value) {
      this.beeFormControl.setValue(value);
      this.beeSelectorChange.emit(this.beeFormControl.value);
      if (!this.clearAfterSelect) {
        if (!this.show && value !== '' && !this.defaultValue) {
          this.selectValue(value);
        } else if (!this.show && value === '' && !this.defaultValue) {
          this.selectedOption = null;
          this.selectOption();
          this.currentQuery = '';
        }
      } else {
        this.clearSelection();
      }
    }
  }

  pageEvent(event: PageEvent) {
    this.optionsContainer = true;
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.length = event.length;
    this.updateData();
  }

  onOptionMouseOver(option: Option) {
    this.clearHighlightedOption();

    if (option !== null) {
      option.highlighted = true;
      this.highlightedOption = option;
    }
  }

  onOptionClick(option: Option) {
    if ((option && (!this.selectedOption || (option && this.selectedOption.value !== option.value))) || !option) {
      this.selectedOption = option;
      this.optionSelected.emit(option);
      this.writeValue(option.value);
      this.show = false;
      this.selectOption(option);
    }
  }

  clearSelection(destroying: boolean = false) {
    this.show = false;
    if (!destroying) {
      this.selectedOption = null;
      this.currentQuery = '';
      this.selectOption();
      this.writeValue('');
      this.updateData();
    }
  }

  selectValue(value: any) {
    if (this.isSetAbilityToSelect) {
      const optionAvailable = this.optionAvailable(value);
      if (optionAvailable) {
        this.onOptionClick(optionAvailable);
      } else {
        if (value && value !== '') {
          this.apiService.get(
            this.route, '', this.pageSize, this.currentPage, this.orderBy, this.sortOrder, value).subscribe(
            result => {
              const row = result[0];

              if (row && row[this.formKey]) {
                this.onOptionClick(this.createOption(row));
              }
              this.detectChanges();
            });
        }
      }
    }
  }

  private selectOption(option: Option = null, text?: string) {
    if (option) {
      for (const selectorOption of this.selectorValues) {
        selectorOption.selected = selectorOption.value === option.value;
      }
    } else {
      for (const selectorOption of this.selectorValues) {
        selectorOption.selected = text && text === selectorOption.label;
        if (selectorOption.selected) {
          this.onOptionClick(selectorOption);
        }
      }
    }
  }

  private optionAvailable(value: any) {
    for (const selectorOption of this.selectorValues) {
      if (value) {
        if (selectorOption.value === value) {
          return selectorOption;
        }
      }
    }

    return null;
  }

  private clearHighlightedOption() {
    if (this.highlightedOption !== null) {
      this.highlightedOption.highlighted = false;
      this.highlightedOption = null;
    }
  }

  private selectHighlightedOption() {
    let option: Option = null;
    for (const selectorOption of this.selectorValues) {
      if (selectorOption.highlighted) {
        option = selectorOption;
        break;
      }
    }
    if (option !== null) {
      this.onOptionClick(option);
    }
  }

  private highlightNextOption() {
    let hasHighlightedOption = false;
    for (let i = 0; i < this.selectorValues.length && !hasHighlightedOption; i++) {
      if (this.selectorValues[i].highlighted) {
        if (i < this.selectorValues.length - 1) {
          this.selectorValues[i].highlighted = false;
          this.selectorValues[i + 1].highlighted = true;
          this.highlightedOption = this.selectorValues[i + 1];
        }
        hasHighlightedOption = true;
      }
    }
    if (!hasHighlightedOption && this.selectorValues.length > 0) {
      this.selectorValues[0].highlighted = true;
    }
  }

  private highlightPreviousOption() {
    let hasHighlightedOption = false;
    for (let i = 0; i < this.selectorValues.length && !hasHighlightedOption; i++) {
      if (this.selectorValues[i].highlighted) {
        if (i !== 0) {
          this.selectorValues[i].highlighted = false;
          this.selectorValues[i - 1].highlighted = true;
          this.highlightedOption = this.selectorValues[i - 1];
        }
        hasHighlightedOption = true;
      }
    }
    if (!hasHighlightedOption && this.selectorValues.length > 0) {
      this.selectorValues[0].highlighted = true;
    }
  }

  private getHighlightedIndex() {
    for (let i = 0; i < this.selectorValues.length; i++) {
      if (this.selectorValues[i].highlighted) {
        return i;
      }
    }

    return 0;
  }

  moveHighlightedIntoView() {
    const list = this.optionsListRef.nativeElement;
    const listHeight = list.offsetHeight;

    const itemIndex = this.getHighlightedIndex();

    if (itemIndex > -1) {
      const item = list.children[0].children[itemIndex];
      const itemHeight = item.offsetHeight;

      const itemTop = itemIndex * itemHeight;
      const itemBottom = itemTop + itemHeight;

      const viewTop = list.scrollTop;
      const viewBottom = viewTop + listHeight;

      if (itemBottom > viewBottom) {
        list.scrollTop = itemBottom - listHeight;
      } else if (itemTop < viewTop) {
        list.scrollTop = itemTop;
      }
    }
  }

  onWindowClick() {
    if (!this.selectorClicked && !this.optionsContainer) {
      this.show = false;
    }
    this.selectorClicked = false;
    this.optionsContainer = false;
  }

  onWindowResize() {
    this.updateWidth();
  }

  private updateWidth() {
    this.width = this.selectorRef.nativeElement.offsetWidth;
  }

  insertElement($event: Event) {
    $event.preventDefault();
    const data = {};

    if (this.requiredFields) {
      const key = Object.keys(this.requiredFields)[0]; // TODO: Add support for all keys
      data[key] = this.searchText;
    } else {
      data[this.formShow[0]] = this.searchText;
    }

    this.selectAfterCreate = this.searchText;
    this.creating = true;
    this.apiService.post(this.route, data).subscribe(() => {
      this.creating = false;
      this.searchText = '';
      this.updateData();
    });
  }

  ngOnDestroy() {
    this.destroying = true;
    if (this.apiSubscription) {
      this.apiSubscription.unsubscribe();
    }
    this.clearSelection(true);
    this.searchSubject.unsubscribe();
    this.changeDetector.detach();
  }
}
