import { Component } from '@angular/core';

import { notFoundConfiguration } from '../bee-components.configuration';

/**
 * Bee404Component, error page when 404.
 */
@Component({
  selector: 'bee-404',
  templateUrl: './bee-404.component.html',
  styleUrls: ['./bee-404.component.scss']
})
export class Bee404Component {
  /**
   * notFoundImage, illustration recommended. (default icon)
   * @type {string}
   */
  notFoundImageUrl: string = notFoundConfiguration.notFoundImageUrl;
  /**
   * notFoundText. (default "Ha ocurrido algún error")
   * @type {string}
   */
  notFoundText: string = notFoundConfiguration.notFoundText;
  /**
   * notFoundAdditionalText. (default null)
   * @type {string}
   */
  notFoundAdditionalText: string = notFoundConfiguration.notFoundAdditionalText;
}
