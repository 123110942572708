import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BeeAltPipe } from '@bee-components/common/bee-pipes/bee-alt.pipe';
import { BeeArrayChangeDetectorPipe } from '@bee-components/common/bee-pipes/bee-array-change-detector.pipe';
import { BeeCurrencyPipe } from '@bee-components/common/bee-pipes/bee-currency.pipe';
import { BeeDatePipe } from '@bee-components/common/bee-pipes/bee-date.pipe';
import { BeeParenthesisNumberPipe } from '@bee-components/common/bee-pipes/bee-parenthesis-number.pipe';
import { BeeSafeHtmlPipe } from '@bee-components/common/bee-pipes/bee-safe-html.pipe';

@NgModule({
  declarations: [
    BeeAltPipe,
    BeeArrayChangeDetectorPipe,
    BeeCurrencyPipe,
    BeeDatePipe,
    BeeParenthesisNumberPipe,
    BeeSafeHtmlPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    BeeAltPipe,
    BeeArrayChangeDetectorPipe,
    BeeCurrencyPipe,
    BeeDatePipe,
    BeeParenthesisNumberPipe,
    BeeSafeHtmlPipe
  ]
})
export class BeePipesModule { }
