import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { Breadcrumb } from './breadcrumb';

/**
 * BeeBreadcrumb, displays a breadcrumb, used with BeeHeader.
 */
@Component({
  selector: 'bee-breadcrumb',
  templateUrl: './bee-breadcrumb.component.html',
  styleUrls: ['./bee-breadcrumb.component.scss']
})
export class BeeBreadcrumbComponent implements OnInit {
  /**
   * Base title of the breadcrumb.
   * @type {string}
   */
  @Input() baseTitle = 'Panel';

  /**
   * The breadcrumbs to display.
   * @type {Breadcrumb[]}
   */
  public breadcrumbs: Breadcrumb[] = [];

  /**
   * Constructor.
   * @param router
   * @param route
   */
  constructor(private router: Router,
              private route: ActivatedRoute) {
  }

  /**
   * OnInit lifecycle event, generates the breadcrumbs based on the activated route.
   */
  ngOnInit() {
    const root: ActivatedRoute = this.route.root;
    this.breadcrumbs = this.getBreadcrumbs(root);
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilChanged()
    ).subscribe(
      () => {
        const activatedRoot: ActivatedRoute = this.route.root;
        this.breadcrumbs = this.getBreadcrumbs(activatedRoot);
      });
  }

  /**
   * Returns array of IBreadcrumb objects that represent the breadcrumb.
   *
   * @class DetailComponent
   * @method getBreadcrumbs
   * @param {ActivatedRoute} route
   * @param {string} url
   * @param {Breadcrumb[]} breadcrumbs
   */
  private getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    const ROUTE_DATA_BREADCRUMB = 'breadcrumb';

    // get the child routes
    const children: ActivatedRoute[] = route.children;

    // return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    // iterate over each children
    for (const child of children) {
      // verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }
      // verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        // return this.getBreadcrumbs(child, url, breadcrumbs);
      }
      // get the route's URL segment
      const routeUrl: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeUrl) {
        // append route URL to URL
        url += `/${routeUrl}`;
      }

      if (child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        const nextLabel = child.snapshot.data[ROUTE_DATA_BREADCRUMB];
        if (breadcrumbs.length > 0) {
          let lastBreadcrumbIndex = breadcrumbs.length;
          if (lastBreadcrumbIndex > 0) {
            lastBreadcrumbIndex = lastBreadcrumbIndex - 1;
          }
          if (breadcrumbs[lastBreadcrumbIndex].label !== nextLabel) {
            const breadcrumb: Breadcrumb = {
              label: nextLabel,
              url: url
            };
            breadcrumbs.push(breadcrumb);
          }
        } else {
          const breadcrumb: Breadcrumb = {
            label: nextLabel,
            url: url
          };
          breadcrumbs.push(breadcrumb);
        }
      }

      // recursive
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
  }
}
