<div class="close-container">
    <span class="close-box">
        <mat-icon svgIcon="close" (click)="closeDialog()"></mat-icon>
    </span>
</div>
<div class="bee-notification-details-container">
  <div mat-dialog-content fxLayout="row wrap" fxLayoutAlign="center center">
    <mat-icon [svgIcon]="'bell-ring'" *ngIf="!data.notificationWasRead"></mat-icon>
    <mat-icon [svgIcon]="'bell'" *ngIf="data.notificationWasRead"></mat-icon>

    <div fxFlex="100" class="notification-details-title">{{ notification.title }}</div>
    <div fxFlex="100" class="notification-details-date">{{ notification.createdAt | beeDate}}</div>
    <div fxFlex="100" class="notification-details-content">{{ notification.content }}</div>

    <button *ngIf="notification.action" mat-raised-button color="primary" type="button"
            (click)="onNotificationAction()">
      {{notification.action}}
    </button>
  </div>

  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="button-container">
    <span class="notifications-list-label" (click)="onNotificationList()">{{ data.notificationsListLabel }}</span>
  </div>
</div>
