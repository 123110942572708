/**
 * Configuration for dataGrid fileFormats.
 */
export const fileFormats: any[] = [
  {
    value: 'xlsx',
    name: 'Excel',
    activeIcon: 'app/shared/assets/icons/excel.svg',
    inactiveIcon: 'app/shared/assets/icons/xlsx-off.svg',
    hideRadio: true
  },
  {
    value: 'pdf',
    name: 'PDF',
    activeIcon: 'app/shared/assets/icons/pdf.svg',
    inactiveIcon: 'app/shared/assets/icons/pdf-off.svg',
    hideRadio: true
  },
  {
    value: 'csv',
    name: 'CSV',
    activeIcon: 'app/shared/assets/icons/csv.svg',
    inactiveIcon: 'app/shared/assets/icons/csv-off.svg',
    hideRadio: true
  },
  {
    value: 'xls',
    name: 'XLS',
    activeIcon: 'app/shared/assets/icons/xls.svg',
    inactiveIcon: 'app/shared/assets/icons/xls-off.svg',
    hideRadio: true
  },
];

/**
 * Default fileFormats.
 */
const defaultFileFormats: any[] = [
  {
    value: 'xlsx',
    name: 'Excel',
    activeIcon: 'app/shared/assets/icons/excel.svg',
    inactiveIcon: 'app/shared/assets/icons/excel-filled.svg',
    hideRadio: true
  },
  {
    value: 'pdf',
    name: 'PDF',
    activeIcon: 'app/shared/assets/icons/pdf.svg',
    inactiveIcon: 'app/shared/assets/icons/pdf-filled.svg',
    hideRadio: true
  },
  {
    value: 'csv',
    name: 'CSV',
    activeIcon: 'app/shared/assets/icons/csv.svg',
    inactiveIcon: 'app/shared/assets/icons/csv-filled.svg',
    hideRadio: true
  },
  {
    value: 'xls',
    name: 'XLS',
    activeIcon: 'app/shared/assets/icons/xls.svg',
    inactiveIcon: 'app/shared/assets/icons/xls-filled.svg',
    hideRadio: true
  },
];

/**
 * Configuration for dataGrid.
 */
export const dataGridConfiguration: any = {
  noResultsImage: 'assets/empty-list.svg',
  showIcon: 'open-in-app',
  openInNewIcon: 'open-in-new',
  editIcon: 'pencil',
  deleteIcon: 'delete',
  printIcon: 'printer',
  emailIcon: 'email-plus-outline',
  duplicateIcon: 'content-duplicate',
  clearIcon: 'close'
};

/**
 * Default dataGrid configuration.
 */
const defaultDataGridConfiguration: any = {
  noResultsImage: '',
  showIcon: 'open-in-app',
  openInNewIcon: 'open-in-new',
  editIcon: 'pencil',
  deleteIcon: 'delete',
  printIcon: 'printer',
  emailIcon: 'email-plus-outline',
  duplicateIcon: 'content-duplicate',
  clearIcon: 'close'
};

/**
 * Configuration for notFound Page.
 */
export const notFoundConfiguration: any = {
  notFoundImageUrl: 'assets/icons/report_problem.svg',
  notFoundText: 'Ha ocurrido algún error',
  notFoundAdditionalText: ''
};

/**
 * Default notFound Page configuration.
 */
const defaultNotFoundConfiguration: any = {
  notFoundImageUrl: 'app/shared/assets/icons/road-closure.svg',
  notFoundText: 'Ha ocurrido algún error',
  notFoundAdditionalText: ''
};

/**
 * Configuration for unsubscribed Page.
 */
export const unsubscribedConfiguration: any = {
  iconUrl: 'assets/check.svg',
  imageUrl: 'assets/unsubscribed.svg',
  mainText: 'Has borrado tu subscripción a los emails de redentradas',
  additionalText: 'Puedes cambiar esta configuración desde tu perfil de usuario accendiento a tu cuenta de redentradas',
  buttonText: 'Ir al inicio de sesión',
  buttonRoute: '/login'
};

/**
 * Default unsubscribed Page configuration.
 */
const defaultUnsubscribedConfiguration: any = {
  iconUrl: 'assets/check.svg',
  imageUrl: 'assets/unsubscribed.svg',
  mainText: 'Has borrado tu subscripción a los emails de la aplicación',
  additionalText: 'Puedes cambiar esta configuración desde tu perfil de usuario accendiento a tu cuenta de la aplicación',
  buttonText: 'Ir al inicio de sesión',
  buttonRoute: '/login'
};
