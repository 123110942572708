<div style="height: calc(100% - 25px); position: relative; overflow-y: scroll">
    <mat-tab-group dynamicHeight #tabgroup (selectedTabChange)="tabChanged($event)">
      <!-- <mat-tab label="INFO">
        <h3 mat-tab-title class="primary-color" fxLayoutAlign="center center" fxLayout="row wrap">Notificaciones del Administrador</h3>
        <div mat-tab-content>
          <bee-form [beeFormGroup]="myForm"
                    [method]="'post'"
                    [toastMessage]="''"
                    [deleteModalObjectName]="''"
                    [errorMessage]="''"
                    [saveButtonText]="'GUARDAR CAMBIOS'"
                    [backEnabled]="false"
                    [requiredSign]="false"
                    [showButtons]="false"
                    [layoutGap]="'0'">
  
             <label [for]="'user-push'" class="primary-color">
              Notificaciones Push Restantes</label>
            <bee-form-item fxFlex="100"
                            [type]="'text'"
                            [id]="'user-push-notify'"
                            [label]="''"
                            [placeholder]="'Notificación'"
                            [appearance]="'box-radio'"
                            class="disabled"
                            [beeFormControl]="NotifyForm.controls['pushLeft']"></bee-form-item>
            <label [for]="'user-push'" class="primary-color">
              Notificaciones Email Restantes</label>
            <bee-form-item fxFlex="100"
                            [type]="'text'"
                            [id]="'user-mail-notify'"
                            [label]="''"
                            [placeholder]="'Notificación'"
                            [appearance]="'box-radio'"
                            class="disabled"
                            [beeFormControl]="NotifyForm.controls['mailLeft']"></bee-form-item>
          </bee-form>
        </div>
      </mat-tab> -->
      <mat-tab label="Mail">
          <h2 mat-tab-title class="primary-color" fxLayoutAlign="center center">Notificación Email</h2>
          <div mat-tab-content>
          <bee-form [beeFormGroup]="myForm"
          [method]="'post'"
          [toastMessage]="'Enviada con éxito'"
          [errorMessage]="'Error al enviar, inténtalo de nuevo'"
          [saveButtonText]="'GUARDAR CAMBIOS'"
          [backEnabled]="false"
          [requiredSign]="false"
          [showButtons]="false"
          [layoutGap]="'0'"
          (postSubmitFunction)="postSubmit(infotab)">
  
          <label [for]="'user-mail'" class="primary-color">
              Asunto del Mensaje</label>
          <bee-form-item fxFlex="100"
                          [type]="'text'"
                          [id]="'user-mail-subject'"
                          [label]="''"
                          [placeholder]="'Titulo Notificación'"
                          [appearance]="'outline'"
                          class="dialog-custom-input"
                          [beeFormControl]="myForm.controls['subject']"></bee-form-item>
                          <label [for]="'user-push'" class="primary-color">
                            Cuerpo del Mensaje </label>
          <bee-form-item fxFlex="100"
                        [type]="'text'"
                        [id]="'user-push-body'"
                        [label]="''"
                        [placeholder]="'Cuerpo del Mensaje'"
                        [appearance]="'outline'"
                        class="dialog-custom-input"
                        [beeFormControl]="myForm.controls['bodytext']"></bee-form-item>
          </bee-form>
          </div>
      </mat-tab>
  
    </mat-tab-group>
    <!-- <div fxLayout="row" fxLayoutAlign="center center" class="message secondary" style="font-size: 14px">SE VA A ENVIAR NOTIFICACIONES A {{this.users.length}} USUARIOS</div> -->
    <div mat-dialog-actions fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="10"  class="submit">
      <button class="default primary-color"
              mat-button
              (click)="closeDialog()">Cancelar</button>
      <!-- <button class="secondary"
              mat-button
              [class.disabled] = "infotab=='INFO'"
              (click)="programNotify()">Programar Notificación</button> -->
    </div>
    <div mat-dialog-actions fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="10"  class="submit" style="padding-bottom: 20px">
      <button fxLayoutAlign="center center" class="secondary"
              [class.disabled]="!myForm.valid"
              mat-button (click)="postSubmit(infotab)">{{message}}</button>
    </div>
  </div>
  