import { Injectable } from '@angular/core';

import { ApiService } from '@shared/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable()

export class ShoppingCartService {

  // Get y set al localStorage de los bonos que se pueden comprar en el evento en el que estamos (Voucher Blueprints)
  // Con esos voucher blueprints y sus cantidades (empiezan en 0) rellenamos el carrito al abrirlo, componente add-vouchers
  // OJO al terminar la compra con éxito ponemos a 0 las cantidades del carrito
  // Variable que me diga los elementos "pendientes" de pagar para poner chip en el header (+1 por cada bono, +1 si hay bracels que comprar)

  constructor(private apiService: ApiService,
              private router: Router,
              private route: ActivatedRoute,
              private authService: AuthenticationService) {
  }

  private _payment_succesful = false;
  private _redirectUrl: string;
  private loaded = true;
  private loading = false;
  private buyingVoucher: boolean;

  get payment_succesful(): boolean {
    return this._payment_succesful;
  }

  set payment_succesful(value: boolean) {
    this._payment_succesful = value;
  }

  private _bracels = 0;

  get bracels(): number {
    return this._bracels;
  }

  // var de los voucher blueprints seleccionados y cuantos de cada uno

  set bracels(value: number) {
    this._bracels = value;
  }

  private _vouchers = {};

  get vouchers(): {} {
    return this._vouchers;
  }

  set vouchers(value: {}) {
    this._vouchers = value;
  }

  private _totalVouchers = 0;

  get totalVouchers(): number {
    return this._totalVouchers;
  }

  set totalVouchers(value: number) {
    this._totalVouchers = value;
  }

  private _totalToPay = 0; // euros

  get totalToPay(): number {
    return this._totalToPay;
  }

  set totalToPay(value: number) {
    this._totalToPay = value;
  }

  private _bracelsEuros = 1; // habrá que setearlo en función del evento!

  get bracelsEuros(): number {
    return this._bracelsEuros;
  }

  set bracelsEuros(value: number) {
    this._bracelsEuros = value;
  }

  /**
   * Method to call the backend route that generates the Redsys payment and
   * if it results successful charge the wallet with the elements bought
   */
  async generatePayment(orderId: string, walletId: string, eventId: string) {
    if (localStorage.getItem(orderId) === 'confirmed') {
      return false;
    }

    localStorage.setItem(orderId, 'confirmed');

    const resp = await this.apiService.put(`momo/web-pay/${orderId}/confirm`, {
      eventId: eventId,
      walletId: walletId,
      buyingAdmin:true,
    }).toPromise();

    // If backend response = payment successful
    this._payment_succesful = true;
    // CARGAMOS LO COMPRADO:
    if (this._bracels > 0) {
      this.chargeBracelsInWallet();
    } else if (Object.keys(this._vouchers).length === 0) {
      this.chargeVouchersInWallet();
    }
    return resp._datos;
  }

  async requestPayment(eventId: string, name: string, moneyImport: number) {
    const resp = await this.apiService.post('momo/web-pay/request', {
      import: moneyImport,
      fullName: name,
      eventId: eventId,
      urlWebPaySuccess: window.location.href.split('?')[0],
      urlWebPayFail: window.location.href.split('?')[0] + '/cancelar'
    }).toPromise();

    localStorage.setItem('bracelsImport', moneyImport + '');

    return resp.url;
  }
  async requestRedsysPayment(moneyImport: number, eventId: string, userId: string, saveCardToken: boolean, walletId: string,payMethod:string) {

    const urlOK =  window.location.href.split('?')[0] + '/resultado-pago';
    const urlKO = window.location.href.split('?')[0] + '/cancelar/resultado-pago';
    const resp = this.apiService.post('redsys/redsys-pay/request', {
      eventId: eventId,
      import: moneyImport,
      urlWebPaySuccess: urlOK,
      urlWebPayFail: urlKO,
      userId: userId,
      walletId: walletId,
      rechargeWithNoFixedCharge: this.buyingVoucher,
      saveCardToken: saveCardToken,
      payMet:payMethod
    }).toPromise();
    localStorage.setItem('bracelsImport', this._bracels + '');

    return resp;
  }

  async requestVoucherPayment(eventId: string, wristbandId: string, name: string, moneyImport: number, data: any) {
    const resp = await this.apiService.post('momo/web-pay/request', {
      import: moneyImport,
      fullName: name,
      eventId: eventId,
      urlWebPaySuccess: window.location.href.split('?')[0],
      urlWebPayFail: window.location.href.split('?')[0] + '/cancelar'
    }).toPromise();

    localStorage.setItem('voucherPurchaseBracels', JSON.stringify(moneyImport));
    localStorage.setItem('voucherPurchaseData', JSON.stringify(data));
    localStorage.setItem('voucherPurchaseWristband', JSON.stringify(wristbandId));

    return resp.url;
  }

  async cancelPayment(orderId, eventId) {
    this.apiService.put(`momo/web-pay/${orderId}/cancel`, { eventId }).toPromise();
  }

  async eventIncludeCommission(eventId) {
    return this.apiService.get(`clients/${this.authService.clientId}/events/${eventId}/include-commission`).toPromise();
  }

  set redirectUrl(value: string) {
    this._redirectUrl = value;
    localStorage.setItem('redirectUrl', JSON.stringify(this._redirectUrl));
  }

  /**
   *
   */
  chargeVouchersInWallet() {
    // POST PARA CREAR LOS VOUCHERS
    // PATCH PARA ASOCIAR LOS VOUCHERS AL WALLET
    this.resetValues();
  }

  /**
   *
   */
  chargeBracelsInWallet() {
    // POST PARA AUMENTAR LOS BRACELS DE LA WALLET
    // this.apiService.post();
    this.resetValues();
  }

  /**
   * Method callet when a payment results succesfull reseting the cart
   */
  resetValues() {
    this._payment_succesful = false;
    this._bracels = 0;
    this._vouchers = {};
  }
  onGoBack() {
    this.router.navigate([`/dashboard`], {relativeTo: this.route});
  }
   userHasCardToken() {
    return this.apiService.get(`clients/${this.authService.clientId}/checkCardToken`).toPromise();
  }

}
