import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BeeUnderConstructionComponent } from './bee-under-construction.component';

@NgModule({
  declarations: [
    BeeUnderConstructionComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    BeeUnderConstructionComponent
  ]
})
export class BeeUnderConstructionModule { }
