import { Injectable } from '@angular/core';

@Injectable()
export class GlobalArraysService {
  /**
   * GLOBAL ARRAYS.
   */
  fonts: object[] = [
    {value: 'Roboto', name: 'Roboto'},
    {value: 'Open-sans', name: 'Open Sans'},
    {value: 'Lato', name: 'Lato'},
    {value: 'Calibri', name: 'Calibri'},
  ];

  notificationTypes: object[] = [
    {value: 'normal', name: 'Normal'},
    {value: 'general', name: 'General'}
  ];

  discountTypes: object[] = [
    {value: 'discount', name: 'Descuento'},
    {value: 'promotion', name: 'Promoción'}
  ];

  /**
   * Zone types.
   */
  zoneTypes: object[] = [
    {value: 'ccaa', name: 'Comunidad Autónoma'},
    {value: 'province', name: 'Provincia'},
    {value: 'country', name: 'País'}
  ];

  /**
   * SGAE options.
   */
  sgaeFeeOptions: object[] = [
    {value: '0.00', name: 'EXENTO (0%)'},
    {value: '1.50', name: 'TEATRO (1.5%)'},
    {value: '3.50', name: 'TEATRO (3.5%)'},
    {value: '5.45', name: 'VARIEDADES (5,45%)'},
    {value: '9.00', name: 'TEATRO (9%)'},
    {value: '10.00', name: 'CONCIERTOS (10%)'},
  ];

  /**
   * IVA options.
   */
  vatOptions: object[] = [
    {value: '0.00', name: 'EXENTO (0%)'},
    {value: '4.00', name: 'SUPERREDUCIDO (4%)'},
    {value: '10.00', name: 'REDUCIDO (10%)'},
    {value: '21.00', name: 'GENERAL (21%)'},
  ];

  /**
   * PointOfSale statuses.
   */
  pointsOfSaleStatuses: object[] = [
    {value: 'active', name: 'Activo'},
    {value: 'inactive', name: 'Inactivo'},
  ];

  /**
   * PointOfSale liquidation periodicity.
   */
  pointOfSaleLiquidationPeriodicity: object[] = [
    {value: 'annual', name: 'Anual'},
    {value: 'biannual', name: 'Semestral'},
    {value: 'quarterly', name: 'Trimestral'},
    {value: 'monthly', name: 'Mensual'}
  ];

  /**
   * Session statuses. 'A la venta', 'Agotada', 'Finalizada'
   */
  sessionStatuses: object[] = [
    // {value: 'Inactiva', name: 'Inactiva'},
    {value: 'A la venta', name: 'A la venta'},
    {value: 'Agotada', name: 'Agotada'},
    {value: 'Finalizada', name: 'Finalizada'}
  ];

  /**
   * Order vias.
   */
  orderVias: object[] = [
    {value: 'web', name: 'Web'},
    {value: 'assisted', name: 'Asistida'},
    {value: 'phone', name: 'Telefónica'},
    {value: 'automatic', name: 'Automática'}
  ];

  /**
   * isHiddenOptions.
   */
  isHiddenOptions: object[] = [
    {value: false, name: 'Visible'},
    {value: true, name: 'Oculto'},
  ];

  /**
   * orderReturnModes.
   */
  orderReturnModes: object[] = [
    {value: 'tpv', name: 'Retrocesión TPV'},
    {value: 'transfer', name: 'Por transferencia'},
    {value: 'cash', name: 'Efectivo en PV'},
  ];

  /** Helper. */
  public getValue(value: any, object_array: object[]): any {
    for (const object of object_array) {
      if (object['value'] === value) {
        return object['name'];
      }
    }

    return '';
  }
}
