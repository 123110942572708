import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

/**
 * BeeBackButtonComponent, navigates the user to the previous location.
 *
 * @example
 *
 * <bee-back-button></bee-back-button>
 */
@Component({
  selector: 'bee-back-button',
  templateUrl: './bee-back-button.component.html',
  styleUrls: ['./bee-back-button.component.scss']
})
export class BeeBackButtonComponent {

  /**
   * Constructor.
   * @param {Location} location
   * @param {Router} router
   */
  constructor(private location: Location,
              private router: Router) {
  }

  /**
   * Navigates to previous location/page.
   */
  goToPreviousPage() {
    if (window.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigate(['']);
    }
  }
}
