import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Activity } from '@shared/models/activity';

// @Injectable({providedIn: 'root'})
@Injectable()
export class ActivitiesService {
  private activities: any[] = [];
  activitiesChanged = new Subject<Activity[]>();
  private activity: any;
  lastGroup: string;
  constructor() {}
  activitiesList = [];

  setActivities(activities) {
    this.activities = activities;
    this.activitiesChanged.next(this.activities.slice());
    this.activityList(this.activities);

    return this.activities;
  }

  getActivities() {
    return this.activities.slice();
  }

  addNewActivity(activity) {
    this.activities.push(activity);
    this.activitiesChanged.next(this.activities);
    this.activityList(this.activities);
  }

  editActivity(activity, id) {
    for (const i in this.activity) {
      if (this.activity[i].id === id) {
      }
    }
    this.activitiesChanged.next(this.activity);
    this.getActivities();
  }

  editSetActivity(activity) {
    this.activity = activity;

    return this.activity;
  }

  editGetActivity() {
    return this.activity;
  }

  activityList(activities) {
    this.activities = activities;
    this.activitiesList = [];
    for (const activity of this.activities) {
      this.activitiesList.push({name: activity.name, maxCapacity: activity.maxCapacity, id: activity.id, autoAccessEnable: activity.autoAccessEnable, date: activity.date});
    }

    return this.activitiesList;
  }

  setLastGroup(group) {
    this.lastGroup = group;
  }

  getLastGroup() {
    return this.lastGroup;
  }
}
