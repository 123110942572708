<div class="close-container">
    <span class="close-box">
        <mat-icon svgIcon="close" (click)="closeDialog()"></mat-icon>
    </span>
</div>
<div mat-dialog-content fxLayout="row wrap" fxLayoutAlign="space-around center">
  <mat-icon svgIcon="information" class="info-icon"></mat-icon>
  <p fxFlex="100">{{ navigationWarningDescription }}</p>
</div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between" class="button-container">
  <button id="leave-button" mat-button color="primary" class="discard-changes-button"
          (click)="closeDialog(false,false)">
    {{ leaveButtonText }}
  </button>

  <button id="stay-button" mat-raised-button color="primary" (click)="closeDialog(false, true)">
    {{ saveAndLeaveButtonText }}
  </button>
</div>
