<div class="close-container">
    <span class="close-box">
        <mat-icon svgIcon="close" (click)="closeDialog()"></mat-icon>
    </span>
</div>
<div mat-dialog-content fxLayout="row wrap" fxLayoutAlign="space-around center">
  <div class="warning-container" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
    <mat-icon svgIcon="alert" color="warn" class="warning-icon"></mat-icon>
    <p><b>{{ data.deleteWarningText }}</b></p>
  </div>

  <p fxFlex="100">{{ data.deleteWarningDescription }}
    <span *ngIf="data.deleteModalObjectName">{{ data.deleteModalObjectName }}</span>
    <span *ngIf="data.multipleDeleteModalObjectName">{{ data.multipleDeleteModalObjectName }}</span>
  </p>

  <form fxFlex="100" *ngIf="data.deleteReasonEnabled" [formGroup]="deleteFormGroup">
    <bee-form-item [id]="'deleteReason'" [label]="data.deleteReasonLabel"
                   [beeFormControl]="deleteFormGroup.controls['deleteReason']">
    </bee-form-item>
  </form>
</div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between" class="button-container">
  <button id="no-click-button" mat-button color="primary" type="button" (click)="closeDialog()">
    <mat-icon *ngIf="displayButtonIcons" svgIcon="arrow-left"></mat-icon>
    {{ data.backText }}
  </button>

  <button id="confirm-delete-button" mat-button color="warn" type="button" (click)="onConfirmDelete()"
          [mat-dialog-close]="deleteFormGroup.controls['deleteReason'].value">
    <mat-icon *ngIf="displayButtonIcons" svgIcon="delete"></mat-icon>
    {{ data.deleteButtonText }}
  </button>
</div>
