import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '@shared/angular-material';

import { BeeFormModule } from '@bee-components/bee-form';

import { BeeEmailComponent } from './bee-email.component';

@NgModule({
  declarations: [
    BeeEmailComponent
  ],
  imports: [
    CommonModule,
    BeeFormModule,
    FlexLayoutModule,
    MaterialModule
  ],
  exports: [
    BeeEmailComponent
  ]
})
export class BeeEmailModule { }
