<mat-dialog-content>
  <div id="bee-dialog-notification-container" fxLayout="row wrap" fxLayoutAlign="center start">
    <span *ngIf="messageAtTop && data.message" id="bee-dialog-notification-message-top" class="bold-text"
          fxFlex="100">{{ data.message }}</span>

    <div *ngIf="data.icon" class="bee-dialog-notification-image-icon-container" fxLayout="row"
         fxLayoutAlign="center center">
      <mat-icon [svgIcon]="data.icon" [ngStyle]="iconStyles"></mat-icon>
    </div>

    <div *ngIf="data.image" class="bee-dialog-notification-image-icon-container" fxLayout="row"
         fxLayoutAlign="center center">
      <img [src]="data.image" alt="data.message">
    </div>

    <span *ngIf="!messageAtTop && data.message" id="bee-dialog-notification-message" class="bold-text"
          fxFlex="100">{{ data.message }}</span>

    <div id="bee-dialog-notification-note-container" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center">
       <span *ngIf="data.note" id="bee-dialog-notification-note" class="primary-color" fxFlex="100">
          {{ data.note }}
       </span>

      <a *ngIf="data.mail" target="_blank" [href]="'mailto:'+data.mail" fxFlex="100">
        {{ data.mail }}
      </a>

      <div *ngIf="data.extraIcon" id="bee-dialog-notification-extra-icon-container" fxLayout="row"
           fxLayoutAlign="center center">
        <mat-icon [svgIcon]="data.extraIcon"></mat-icon>
      </div>
    </div>
    <span id="bee-dialog-notification-close" (click)="closeDialog()" fxFlex="100">{{ closeText }}</span>
  </div>
</mat-dialog-content>
