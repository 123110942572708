<div class="mobile-header-container {{header_background_class}}" [ngStyle]="{'height.px': height}">
  <div class="left-icon-container" [ngStyle]="{'margin-left.px': sidebar_margin_left}">
    <!--(click)="onClickButton('left-button')">-->
    <button mat-button type="button" *ngIf="left_icon" (click)="onClickButton('left-button')">
      <!--<mat-icon svgIcon="delete"></mat-icon>-->
      <img [ngStyle]="{'height.px': left_icon_height, 'width.px': left_icon_width}" [src]="left_icon" alt="">
    </button>

    <button mat-button type="button" *ngIf="left_back_icon" (click)="goToPreviousPage()">
      <!--<mat-icon svgIcon="delete"></mat-icon>-->
      <img [src]="left_back_icon" alt="">
    </button>

  </div>
  <div class="central-container" (click)="onClickButton('central-button')">
    <img *ngIf="central_icon" [src]="central_icon" alt="" [ngStyle]="{'max-width.px': central_icon_with}">
    <div *ngIf="central_text" class="{{header_central_text_class}}">{{central_text}}</div>
  </div>
  <div class="right-icon-container" [ngStyle]="{'margin-right.px': sidebar_margin_right}">
    <button mat-button type="button" *ngIf="right_icon" (click)="onClickButton('right-button')"
            [matBadge]="sidebar_notifications_number" matBadgePosition="before" matBadgeColor="accent"
            [matBadgeHidden]="!checkNotifications()">
      <!--<mat-icon svgIcon="delete"></mat-icon>-->
      <img [ngStyle]="{'height.px': right_icon_height, 'width.px': right_icon_width}" [src]="right_icon" alt="">
    </button>
  </div>
</div>
