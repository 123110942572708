<div class="bee-notifications-dialog-container">
  <div mat-dialog-content fxLayout="row wrap">
    <div fxFlex="100" class="bee-notifications-title" fxLayout="row" fxLayoutAlign="space-between center">
      <span>{{ data.title }}</span>
      <span class="mark-as-read-label" *ngIf="beeNotificationsService.unreadNotificationsCount > 0"
            (click)="beeNotificationsService.markAllAsRead()">{{ data.markAsReadLabel }}</span>
    </div>

    <div fxFlex="100" fxLayout="row wrap" class="notifications-container">
      <div fxFlex="100" fxLayout="row wrap" class="notification-container"
           *ngFor="let notification of beeNotificationsService.unreadNotifications"
           (click)="beeNotificationsService.openNotification(notification, data); closeDialog()">
        <div fxFlex="100" fxLayout="row" fxLayoutAlign="start center" class="notification-title">
          <mat-icon [svgIcon]="'bell-ring'" *ngIf="!notification.isRead"></mat-icon>
          <span [ngClass]="{'title-padding': notification.isRead}">{{ notification.title }}</span>
        </div>
        <div fxFlex="100" class="notification-content">{{ notification.content }}</div>
        <div fxFlex="100" class="notification-date">{{ notification.createdAt | beeDate}}</div>
        <span class="show-tip">Ver</span>
      </div>
    </div>

    <div fxFlex="100" class="bee-notifications-actions" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutAlign="start center" class="notifications-configuration">
        <mat-icon [svgIcon]="data.notificationsConfigurationIcon"
                  *ngIf="beeNotificationsService.unreadNotificationsCount > 0"
                  (click)="onConfiguration()"></mat-icon>
        <span *ngIf="beeNotificationsService.unreadNotificationsCount > 0"
              (click)="onConfiguration()">{{ data.notificationsConfigurationLabel }}</span>
        <span class="no-notifications-label"
              *ngIf="beeNotificationsService.unreadNotificationsCount == 0">{{ data.noNotificationsLabel }}</span>
      </div>
      <span class="notifications-list-label" (click)="onNotificationList()">{{ data.notificationsListLabel }}</span>
    </div>
  </div>
</div>
