import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppGuard } from '@shared/guards';
import { AppCustomPreloader } from '@shared/preloader/app-custom-preloader';

import { Bee404Component } from '@bee-components/bee-404';

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'login', loadChildren: () => import('app/auth/login/login.module').then(m => m.LoginModule)},
  {
    path: 'recuperacion',
    loadChildren: () => import('app/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {
    path: 'confirmacion/:token',
    loadChildren: () => import('app/auth/confirm-email/confirm-email.module').then(m => m.ConfirmEmailModule)
  },
  {
    path: 'confirmacion-credenciales/:token',
    loadChildren: () => import('app/auth/first-password/first-password.module').then(m => m.FirstPasswordModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('app/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AppGuard]
  },
  {
    path: 'movimientos',
    loadChildren: () => import('app/profits/profits.module').then(m => m.ProfitsModule),
    canActivate: [AppGuard]
  },
  {
    path: 'productos',
    loadChildren: () => import('app/products/products.module').then(m => m.ProductsModule),
    canActivate: [AppGuard]
  },
  {
    path: 'trabajadores',
    loadChildren: () => import('app/workers/workers.module').then(m => m.WorkersModule),
    canActivate: [AppGuard]
  },
  {
    path: 'bonos',
    loadChildren: () => import('app/voucher/voucher.module').then(m => m.VoucherModule),
    canActivate: [AppGuard]
  },
  {
    path: 'recintos',
    loadChildren: () => import('app/rooms/rooms.module').then(m => m.RoomsModule),
    canActivate: [AppGuard]
  },
  {
    path: 'usuarios',
    loadChildren: () => import('app/users/users.module').then(m => m.UsersModule),
    canActivate: [AppGuard]
  },
  {
    path: 'confEvent',
    loadChildren: () => import('app/confEvent/confEvent.module').then(m => m.ConfEventModule),
    canActivate: [AppGuard]
  },
  {
    path: 'recharge',
    loadChildren: () => import('app/shopping-cart/shopping-cart.module').then(m => m.ShoppingCartModule),
    canActivate: [AppGuard]
  },
  {
    path: 'recharge/cancelar',
    loadChildren: () => import('app/shopping-cart/shopping-cart.module').then(m => m.ShoppingCartModule),
    canActivate: [AppGuard]
  },
  {
    path: 'resultado-pago',
    loadChildren: () => import('app/shopping-cart/payment-feedback/payment-feedback.module').then(m => m.PaymentFeedbackModule),
    canActivate: [AppGuard]
  },
  {
    path: ':status/resultado-pago',
    loadChildren: () => import('app/shopping-cart/payment-feedback/payment-feedback.module').then(m => m.PaymentFeedbackModule),
    canActivate: [AppGuard]
  },
  {
    path: 'pedidos',
    loadChildren: () => import('app/orders/orders.module').then(m => m.OrdersModule),
    canActivate: [AppGuard]
  },
  {
    path: 'actividades',
    loadChildren: () => import('app/activities/activities.module').then(m => m.ActivitiesModule),
    canActivate: [AppGuard]
  },
  {
    path: 'grupos',
    loadChildren: () => import('app/groups/groups.module').then(m => m.GroupsModule),
    canActivate: [AppGuard]
  },
  {
    path: 'devices',
    loadChildren: () => import('app/devices/devices.module').then(m => m.DevicesModule),
    canActivate: [AppGuard]
  },

  {path: '**', component: Bee404Component, data: {animation: {page: 'notFoundPage'}}}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: AppCustomPreloader,
    paramsInheritanceStrategy: 'always',
    scrollPositionRestoration: 'top',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule],
  providers: [
    AppGuard
  ]
})
export class AppRoutingModule { }
