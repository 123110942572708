<div align="center" style="width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  opacity: 0.8;
  position: fixed;
  z-index: 1;
  padding-top: 350px" *ngIf="authService.getLoader()==true">
  <img src="../assets/animate/svg-loaders/spinning-circles.svg">
  <h6 style="color:#0F223E;font-size:30px">Cargando</h6>
</div>


  <ngx-loading-bar [includeSpinner]="false" color="#0F223E"></ngx-loading-bar>
  <div [ngClass]="themeService.selectedTheme" class="mat-app-background" id="theme-container">
    <router-outlet></router-outlet>
  </div>

