<div class="form-item" [ngSwitch]="type"
     [ngClass]="{'help-enabled': helpTooltip,
     'mat-checkbox-margin': (type === 'checkbox' && checkboxMargin || type === 'slide' && checkboxMargin),
      'right-align': rightAlign}">

  <ng-container *ngSwitchCase="'slider'">
    <div class="form-item-container">
      <label *ngIf="label">{{ label }}<span *ngIf="requiredSign && isRequired"> *</span></label>
      <mat-slider [id]="id" [step]="step" [min]="min" [max]="max" [color]="'primary'"
                  [thumbLabel]="true"
                  [tickInterval]="tickInterval"
                  [formControl]="beeFormControl">
      </mat-slider>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'slide'">
    <mat-slide-toggle [id]="id" [color]="'primary'" [formControl]="beeFormControl">
      <span *ngIf="label">{{ label }}<span *ngIf="requiredSign && isRequired"> *</span></span>
    </mat-slide-toggle>
  </ng-container>

  <ng-container *ngSwitchCase="'checkbox'">
    <mat-checkbox [id]="id" [name]="name" [formControl]="beeFormControl" [color]="'primary'" (change)="onChange()"
                  [disableRipple]="!checkboxRipple">
      <span *ngIf="label">{{ label }}<span *ngIf="requiredSign && isRequired"> *</span></span>
    </mat-checkbox>
  </ng-container>

  <ng-container *ngSwitchCase="'boxCheckbox'">
    <div class="box-checkbox" (click)="onBoxCheckBox()" [ngClass]="{'active-box-checkbox': beeFormControl.value}">
      <span *ngIf="label">{{ label }}</span>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'radio'">
    <div class="form-item-container">
      <label *ngIf="label">{{ label }}<span *ngIf="requiredSign && isRequired"> *</span></label>
      <mat-radio-group [id]="id" [name]="name" [formControl]="beeFormControl" (change)="onChange()"
                       [ngClass]="{'radio-space-between': radioSpaceBetween}">
        <div *ngFor="let option of options" class="radio-container"
             [ngClass]="{'radio-container-with-image':option.activeIcon || option.inactiveIcon,
                 'inactive': beeFormControl.value != option.value}"
             (click)="setPreviousRadioValue()">
          <div class="vertical-aligned">
            <img *ngIf="option.activeIcon && beeFormControl.value == option.value"
                 src="{{option.activeIcon}}" alt="option.value">
            <img *ngIf="option.inactiveIcon && beeFormControl.value != option.value"
                 src="{{option.inactiveIcon}}" alt="option.value" (click)="beeFormControl.setValue(option.value)">
            <mat-radio-button [value]="option.value" [color]="'primary'"
                              [ngClass]="{'hidden': option.hideRadio}">
              {{ option.name }}
            </mat-radio-button>
          </div>
        </div>
      </mat-radio-group>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'radio-logo'">
    <div class="form-item-container">
      <label *ngIf="label">{{ label }}<span *ngIf="requiredSign && isRequired"> *</span></label>
      <mat-radio-group [id]="id" [name]="name" [formControl]="beeFormControl" (change)="onChange()"
                       [ngClass]="{'radio-space-between': radioSpaceBetween}">
        <div *ngFor="let option of options" class="radio-container"
             [ngClass]="{'radio-container-with-image':option.activeIcon || option.inactiveIcon,
                 'inactive': beeFormControl.value != option.value}"
             (click)="setPreviousRadioValue()">
          <div class="vertical-aligned">
            <mat-icon *ngIf="option.activeIcon"
                      svgIcon="{{option.activeIcon}}"></mat-icon>
            <mat-icon *ngIf="option.inactiveIcon && beeFormControl.value != option.value"
                      svgIcon="{{option.inactiveIcon}}" (click)="beeFormControl.setValue(option.value)"></mat-icon>
            <mat-radio-button [value]="option.value" [color]="'primary'"
                              [ngClass]="{'hidden': option.hideRadio}">
            </mat-radio-button>
          </div>
        </div>
      </mat-radio-group>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'boxRadio'">
    <div class="box-radio-container">
      <label *ngIf="label">{{ label }}<span *ngIf="requiredSign && isRequired"> *</span></label>
      <div *ngFor="let option of options" class="box-radio" (click)="onBoxRadio(option.value)"
           [ngClass]="{'active-box-radio': beeFormControl.value == option.value}">
        <span>{{ option.name }}</span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'select'">
    <mat-form-field [appearance]="appearance">
      <mat-label *ngIf="label">{{ label }}<span *ngIf="requiredSign && isRequired"> *</span></mat-label>
      <mat-select placeholder={{placeholder}} [id]="id" [formControl]="beeFormControl"
                  (selectionChange)="onChange()">
        <mat-option *ngFor="let option of options" [value]="option">
          {{ option['name'] }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="beeFormControl.invalid">{{ errorMessage(beeFormControl) }}</mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="'select2'">
    <mat-form-field [appearance]="appearance">
      <mat-label *ngIf="label">{{ label }}<span *ngIf="requiredSign && isRequired"> *</span></mat-label>
      <mat-select placeholder={{placeholder}} [id]="id" [formControl]="beeFormControl"
                  (selectionChange)="onChange()">
        <mat-option *ngFor="let option of options" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="beeFormControl.invalid">{{ errorMessage(beeFormControl) }}</mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="'date'">
    <mat-form-field [appearance]="appearance" [floatLabel]="floatLabel">
      <mat-label *ngIf="label">{{ label }}<span *ngIf="requiredSign && isRequired"> *</span></mat-label>
      <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker" placeholder={{placeholder}} [id]="id"
             [name]="name"
             (dateChange)="onChange()"
             [formControl]="beeFormControl">
      <button *ngIf="beeFormControl.value && !isDisabled" mat-icon-button aria-label="Limpiar"
              class="datepicker-clear-button"
              type="button"
              (click)="clearValue()">
        <mat-icon svgIcon="close"></mat-icon>
      </button>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-error *ngIf="beeFormControl.invalid">{{ errorMessage(beeFormControl) }}</mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchCase="'textarea'">
    <mat-form-field [appearance]="appearance">
      <mat-label *ngIf="label">{{ label }}<span *ngIf="requiredSign && isRequired"> *</span></mat-label>
      <textarea matInput #input placeholder={{placeholder}}
                [maxlength]="textAreaMaxLength"
                [id]="id" [name]="name" [formControl]="beeFormControl"
                cdkTextareaAutosize
                [cdkAutosizeMinRows]="textAreaMinRows"
                [cdkAutosizeMaxRows]="textAreaMaxRows"
                (change)="onChange()">
      </textarea>
      <mat-hint align="end">{{input.value?.length || 0}}/{{textAreaMaxLength}}</mat-hint>
      <mat-icon *ngIf="icon" matSuffix svgIcon={{icon}}></mat-icon>
      <mat-error *ngIf="beeFormControl.invalid">{{ errorMessage(beeFormControl) }}</mat-error>
    </mat-form-field>
  </ng-container>

<!--  <ng-container *ngSwitchCase="'rich'">-->
<!--    <div class="form-item-container">-->
<!--      <label [ngClass]="{'error': beeFormControl.invalid && beeFormControl.dirty}" [attr.for]="id">{{ label }}<span-->
<!--          *ngIf="requiredSign && isRequired"> *</span></label>-->
<!--      <quill-editor #editor [id]="id" class="quill-editor-container" [placeholder]="placeholder"-->
<!--                    [formControl]="beeFormControl">-->
<!--        <div quill-editor-toolbar>-->
<!--                <span class="ql-formats">-->
<!--                    <button class="ql-bold" [title]="'Negrita'"></button>-->
<!--                    <button class="ql-italic" [title]="'Cursiva'"></button>-->
<!--                    <button class="ql-underline" [title]="'Subrayado'"></button>-->
<!--                  &lt;!&ndash;<button class="ql-strike" [title]="'Tachado'"></button>&ndash;&gt;-->
<!--                    <button class="ql-blockquote" [title]="'Cita'"></button>-->
<!--                </span>-->
<!--          <span class="ql-formats">-->
<!--                    <button class="ql-list" value="ordered" [title]="'Lista ordenada'"></button>-->
<!--                    <button class="ql-list" value="bullet" [title]="'Lista'"></button>-->
<!--                </span>-->
<!--          <span class="ql-formats">-->
<!--                    <button class="ql-indent" value="-1" [title]="'Disminuir sangría'"></button>-->
<!--                    <button class="ql-indent" value="+1" [title]="'Aumentar sangría'"></button>-->
<!--                </span>-->
<!--          <span class="ql-formats">-->
<!--                    <select class="ql-color" [title]="'Color de letra'"></select>-->
<!--                    <select class="ql-background" [title]="'Color de fondo'"></select>-->
<!--                </span>-->
<!--          <span class="ql-formats">-->
<!--                     <select class="ql-size" [title]="'Tamaño de letra'">-->
<!--                        <option value="small">Pequeña</option>-->
<!--                        <option selected></option>-->
<!--                        <option value="large">Grande</option>-->
<!--                        <option value="huge">Enorme</option>-->
<!--                      </select>-->
<!--                </span>-->
<!--          <span class="ql-formats">-->
<!--                     <select class="ql-header" [title]="'Tipos cabecera'">-->
<!--                        <option value="1">Cabecera 1</option>-->
<!--                        <option value="2">Cabecera 2</option>-->
<!--                        <option value="3">Cabecera 3</option>-->
<!--                        <option value="4">Cabecera 4</option>-->
<!--                        <option value="5">Cabecera 5</option>-->
<!--                        <option value="6">Cabecera 6</option>-->
<!--                        <option selected></option>-->
<!--                      </select>-->
<!--                </span>-->
<!--          <span class="ql-formats">-->
<!--                  <select class="ql-align" [title]="'Alineamiento'">-->
<!--                    <option selected></option>-->
<!--                    <option value="center"></option>-->
<!--                    <option value="right"></option>-->
<!--                    <option value="justify"></option>-->
<!--                  </select>-->
<!--                </span>-->
<!--          <span class="ql-formats">-->
<!--                    <button class="ql-clean" [title]="'Limpiar formato'"></button>-->
<!--                </span>-->
<!--        </div>-->
<!--      </quill-editor>-->
<!--      <span class="rich-error error"-->
<!--            *ngIf="beeFormControl.invalid && beeFormControl.dirty">{{ errorMessage(beeFormControl) }}</span>-->
<!--    </div>-->
<!--  </ng-container>-->

  <ng-container *ngSwitchCase="'password'">
    <mat-form-field [appearance]="appearance" [floatLabel]="floatLabel">
      <mat-label *ngIf="label">{{ label }}<span *ngIf="requiredSign && isRequired"> *</span></mat-label>
      <input matInput [type]="hide ? 'password' : 'text'"
             [id]="id" [name]="name" [placeholder]="placeholder"
             [formControl]="beeFormControl"
             (change)="onChange()">
      <button *ngIf="passwordCanBeSeen"
              mat-icon-button matSuffix type="button"
              (click)="hide = !hide"
              [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
        <mat-icon [svgIcon]="hide ? 'eye-off' : 'eye'"></mat-icon>
      </button>
      <mat-error *ngIf="beeFormControl.invalid">{{ errorMessage(beeFormControl) }}</mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <mat-form-field [appearance]="appearance" [floatLabel]="floatLabel">
      <mat-label *ngIf="label">{{ label }}<span *ngIf="requiredSign && isRequired"> *</span></mat-label>
      <input matInput [type]="type" [placeholder]="placeholder" [id]="id" [name]="name"
             [step]="step" [min]="min" [max]="max"
             [beePositiveNumber]="(min >= 0 && type === 'number')"
             [formControl]="beeFormControl"
             [beeDecimals]="decimals"
             (change)="onChange()">
      <mat-icon *ngIf="icon" matSuffix [svgIcon]="icon"></mat-icon>
      <mat-error *ngIf="beeFormControl.invalid">{{ errorMessage(beeFormControl) }}</mat-error>
    </mat-form-field>
  </ng-container>

  <span class="help-section" *ngIf="helpTooltip"
        [ngClass]="{'help-section-with-padding': (type !== 'checkbox' && appearance !== 'standard')}">
        <mat-icon [svgIcon]="helpIcon" [matTooltip]="helpTooltip" matTooltipPosition="above"></mat-icon>
    </span>
</div>
