import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { subElementStateTrigger } from './animations';
import { BeeSidebarElement } from './bee-sidebar-element';

/**
 * BeeSidebarElementComponent, an element inside the BeeSidebarComponent.
 */
@Component({
  selector: 'bee-sidebar-element',
  templateUrl: './bee-sidebar-element.component.html',
  styleUrls: ['./bee-sidebar-element.component.scss'],
  animations: [subElementStateTrigger],
})
export class BeeSidebarElementComponent implements OnInit, OnChanges {
  @Input() sidebarElement: BeeSidebarElement;
  @Input() fullWidth = false;
  @Input() subElementsActive = false;
  @Input() imageIcon = false;
  @Input() contentColor: string;
  @Input() subElementsNumber = 3;
  @Input() customElementClass: string;

  @Output() readonly headerClicked = new EventEmitter();
  @Output() readonly elementClickedCloseSidebar = new EventEmitter();

  subElementsHeight = 50;
  loaded = false;
  elementClasses = {};

  constructor() {
  }

  ngOnInit() {
    if (this.sidebarElement.imageIcon) {
      this.imageIcon = this.sidebarElement.imageIcon;
    }
    this.loaded = true;
    this.elementClasses = {
      disabled: this.sidebarElement.isDisabled
    };
    if (this.customElementClass) {
      this.elementClasses[this.customElementClass] = this.customElementClass;
    }
  }

  ngOnChanges() {
    if (this.subElementsActive && this.sidebarElement.subElements.length > 1) {
      this.subElementsHeight = 50 * (this.sidebarElement.subElements.length + 1);
    } else {
      this.subElementsHeight = 50;
    }

    // Limiting the number of subelements to 4
    // The height (tittle  50px + subelements 4*50px + 30px to show that there is scroll if there are more than 4 subelements)
    if (this.subElementsHeight >= 50 * this.subElementsNumber) {
      this.subElementsHeight = 50 * this.subElementsNumber + 80;
    }
  }

  emitHeaderClicked(result: boolean) {
    if (!this.sidebarElement.isDisabled) {
      this.headerClicked.emit(result);
    }
  }

  onClickElement(subElement: BeeSidebarElement = null) {
    if (subElement) {
      if (subElement.collapseOnClick) {
        this.elementClickedCloseSidebar.emit();
      }
    } else {
      if (this.sidebarElement.collapseOnClick) {
        this.elementClickedCloseSidebar.emit();
      }
    }
  }
}
