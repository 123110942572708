<div *ngIf="!routerOutlet.activatedRouteData['toolbarDisabled']" id="toolbar" class="no-print">
    <span id="back-button" (click)="onBack()" matTooltip="Volver">
        <i class="mdi mdi-reply"></i>
    </span>
  <span *ngIf="showEditButton" id="edit-button" (click)="onEdit()" matTooltip="Editar" class="disabled">
            <i class="mdi mdi-pencil"></i>
        </span>
  <span *ngIf="showHelpButton" id="help-button" (click)="onHelp()" matTooltip="Ayuda">
            <i class="mdi mdi-help"></i>
        </span>
  <span id="print-button" (click)="onPrint()" matTooltip="Imprimir">
            <i class="mdi mdi-printer"></i>
    </span>
</div>
