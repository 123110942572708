import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { DashboardService } from '@app/dashboard/dashboard.service';
import { ProductsService } from '@app/products/products.service';
import { ProfitsService } from '@app/profits/profits.service';
import { RoomsService } from '@app/rooms/rooms.service';
import { UsersService } from '@app/users/users.service';
import { VoucherService } from '@app/voucher/voucher.service';
import { WorkersService } from '@app/workers/workers.service';

import { Product } from '@shared/models/product';
import { ApiService, AuthenticationService } from '@shared/services';

@Injectable()
export class LoginService {
  private data: any[] = [];
  public userId: string
  public event:any
  dataChanged = new Subject<Product[]>();
  constructor(private http: HttpClient,
              private dashboardService: DashboardService,
              private productService: ProductsService,
              private profitsService: ProfitsService,
              private userService: UsersService,
              private voucherService: VoucherService,
              private workerService: WorkersService,
              private roomService: RoomsService,
              private apiService: ApiService,
              private authService: AuthenticationService) {
  }

  getDataEvent() {
    this.apiService
      .get('accounts/' + this.authService.getAccountId()['id'] + '/events').subscribe(
      data => {
        return data[0];
      },
      err => {
        console.error(err);
      });
  }
  getEvent() {
    // return this.apiService
    //   .get('accounts/' + this.authService.getAccountId()['id'] + '/events').toPromise();
  this.userId = JSON.parse(localStorage.getItem('userId'));
  return this.apiService
    .get('accounts/' + this.authService.getAccountId()['id'] + '/eventUser', 'query=&userId=' + this.userId).toPromise()
  }
}
