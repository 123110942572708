<div id="footer-container" fxLayout="row" fxLayoutAlign="space-around">
  <ng-template [ngIf]="nButtons < 7">
    <div *ngFor="let option of options; let i = index"
         [class.active]="activeTab === actives[i]"
         class="option"
         fxLayout="column"
         fxLayoutAlign="center center"
         [routerLink]=components[i]>
      <mat-icon svgIcon="{{icons[i]}}"></mat-icon>
      <span>{{option}}</span>
    </div>
  </ng-template>
  <ng-template [ngIf]="nButtons === 7">
    <div *ngFor="let option of options; let i = index"
         [class.active]="activeTab === actives[i]"
         [class]=class[i]
         fxLayout="column"
         fxLayoutAlign="center center"
         [routerLink]=components[i]>
      <ng-template [ngIf]="option !== 'Más'">
        <mat-icon svgIcon="{{icons[i]}}"></mat-icon>
        <span>{{option}}</span>
      </ng-template>
      <ng-container *ngIf="option === 'Más'">
        <ng-container *ngIf="!flag">
          <mat-icon svgIcon="{{icons[i]}}" (click)="changeMenu()"></mat-icon>
          <span (click)="changeMenu()">{{option}}</span>
        </ng-container>
        <ng-container *ngIf="flag">
          <div *ngIf="hasAccessControl"
               [class.active]="activeTab === 'rooms'"
               class="opt"
               fxLayoutAlign="center center"
               [routerLink]="'/recintos'">
            <mat-icon svgIcon="home-city"></mat-icon>
            <span>Recintos</span>
          </div>
          <div [class.active]="activeTab === 'users'"
               class="opt"
               fxLayoutAlign="center center"
               [routerLink]="'/usuarios'">
            <mat-icon svgIcon="account"></mat-icon>
            <span>Usuarios</span>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>
</div>
