import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ToggleSidebarService } from '@shared/services';

@Component({
  selector: 'bee-mobile-header',
  templateUrl: './bee-mobile-header.component.html',
  styleUrls: ['./bee-mobile-header.component.scss']
})
export class BeeMobileHeaderComponent implements OnInit {
  @Input() height = 90;
  @Input() central_text: string;
  @Input() central_icon: string; // url
  @Input() central_icon_with = 60; // url
  @Input() left_icon: string; // url
  @Input() left_back_icon: string; // url
  @Input() left_icon_height = 30;
  @Input() left_icon_width = 30;
  @Input() right_icon: string; // url
  @Input() right_icon_height = 60;
  @Input() right_icon_width = 117;
  @Input() sidebar = false;
  @Input() sidebar_margin_right = 25;
  @Input() sidebar_margin_left = 25;
  @Input() header_central_text_class = '';
  @Input() header_background_class = '';

  @Input() sidebar_notifications_number = 0; // number inside a badge

  @Input() sidebar_abilities = {};

  @Output() readonly onClickLeftButton: EventEmitter<any> = new EventEmitter<any>();
  @Output() readonly onClickCentralButton: EventEmitter<any> = new EventEmitter<any>();
  @Output() readonly onClickRightButton: EventEmitter<any> = new EventEmitter<any>();

  display_sidebar = false;

  constructor(private _toggle_sidebar_service: ToggleSidebarService,
              private _location: Location) {
  }

  ngOnInit() {
  }

  onSidebarElement($event) {
    if ($event.sub_elements && $event.sub_elements.length > 0 && !this.display_sidebar) {
      this._toggle_sidebar_service.toggleSidebar(true);
    }
  }

  onCollapseSidebar($event) {
    this._toggle_sidebar_service.toggleSidebar($event);
  }

  onClickButton(button: string) {
    switch (button) {
      case 'left-button' : {
        this.onClickLeftButton.emit('left-button');
        break;
      }
      case 'central-button' : {
        this.onClickCentralButton.emit('central-button');
        break;
      }
      case 'right-button' : {
        this.onClickRightButton.emit('right-button');
        break;
      }
      default:
        break;
    }
  }

  checkNotifications() {
    return this.sidebar_notifications_number > 0;
  }

  goToPreviousPage() {
    this._location.back();
    window.scroll(0, 0);
  }
}
