import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';

import { Bee404Module } from '@bee-components/bee-404';
import { BeeAlertModule } from '@bee-components/bee-alert';
import { BeeBackButtonModule } from '@bee-components/bee-back-button';
import { BeeBreadcrumbModule } from '@bee-components/bee-breadcrumb';
import { BeeDialogNotificationModule } from '@bee-components/bee-dialog-notification';
import { BeeEmailModule } from '@bee-components/bee-email';
import { BeeFormModule } from '@bee-components/bee-form';
import { BeeMobileHeaderComponent } from '@bee-components/bee-mobile-header/bee-mobile-header.component';
import { BeeNotificationsService } from '@bee-components/bee-notifications';
import { BeeSidebarModule } from '@bee-components/bee-sidebar';
import { BeeThemeModule } from '@bee-components/bee-theme';
import { BeeToolbarModule } from '@bee-components/bee-toolbar';
import { BeeUnderConstructionModule } from '@bee-components/bee-under-construction';
import { BeeUnsubscribeModule } from '@bee-components/bee-unsubscribe';
import { BeeWizardFormModule } from '@bee-components/bee-wizard-form';
import { BeeWizardHelpModule } from '@bee-components/bee-wizard-help';
import { BeeConfirmDeleteModule } from '@bee-components/common/bee-confirm-delete';
import { BeeConfirmNavigationModule } from '@bee-components/common/bee-confirm-navigation';
import { BeeDirectivesModule } from '@bee-components/common/bee-directives/bee-directives.module';
import { BeeFileUploaderModule } from '@bee-components/common/bee-file-uploader';
import { BeeImagePreviewModule } from '@bee-components/common/bee-image-preview';
import { BeePipesModule } from '@bee-components/common/bee-pipes';
import { BeePreloaderModule } from '@bee-components/common/bee-preloader';
import { MaterialModule } from './angular-material';
import { ActionsTopBarComponent } from './components/actions-top-bar/actions-top-bar.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ApiService, SnackBarService } from './services';
import { NotificationDialogComponent } from './components/notification-dialog/notification-dialog.component';

@NgModule({
  declarations: [
    BeeMobileHeaderComponent,
    FooterComponent,
    HeaderComponent,
    ActionsTopBarComponent,
    ConfirmationDialogComponent,
    NotificationDialogComponent

  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    VirtualScrollerModule,
    Bee404Module,
    BeeAlertModule,
    BeeBackButtonModule,
    BeeBreadcrumbModule,
    BeeDialogNotificationModule,
    BeeEmailModule,
    BeeFormModule,
    BeeSidebarModule,
    BeeThemeModule,
    BeeToolbarModule,
    BeeUnderConstructionModule,
    BeeUnsubscribeModule,
    BeeWizardFormModule,
    BeeWizardHelpModule,
    BeeConfirmDeleteModule,
    BeeConfirmNavigationModule,
    BeeFileUploaderModule,
    BeeImagePreviewModule,
    BeePreloaderModule,
    BeePipesModule,
    BeeDirectivesModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
    MaterialModule,
    VirtualScrollerModule,
    Bee404Module,
    BeeAlertModule,
    BeeBackButtonModule,
    BeeBreadcrumbModule,
    BeeDialogNotificationModule,
    BeeEmailModule,
    BeeFormModule,
    BeeSidebarModule,
    BeeThemeModule,
    BeeToolbarModule,
    BeeUnderConstructionModule,
    BeeUnsubscribeModule,
    BeeWizardFormModule,
    BeeWizardHelpModule,
    BeeConfirmDeleteModule,
    BeeConfirmNavigationModule,
    BeeFileUploaderModule,
    BeeImagePreviewModule,
    BeePreloaderModule,
    BeePipesModule,
    BeeDirectivesModule,
    BeeMobileHeaderComponent,
    FooterComponent,
    ActionsTopBarComponent
  ],
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ApiService,
        DatePipe,
        SnackBarService,
        BeeNotificationsService
      ]
    };
  }
}
