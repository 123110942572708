import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Product } from '@shared/models/product';

@Injectable()
export class VoucherService {
  private vouchers: any[] = [];
  private voucher: any;
  vouchersChanged = new Subject<Product[]>();
  productsChanged = new Subject<Product[]>();
  private products: Product[] = [];
  productsList = [];
  constructor() {}

  setVouchers(vouchers) {
    this.vouchers = vouchers;
    this.vouchersChanged.next(this.vouchers.slice());

    return this.vouchers;
  }

  getVouchers() {
    return this.vouchers.slice();
  }

  addNewVoucher(voucher) {
    this.vouchers.push(voucher);
    this.vouchersChanged.next(this.vouchers.slice());
  }

  editVoucher(voucher, id) {
    for (const i in this.vouchers) {
      if (this.vouchers[i].id === id) {
      }
    }
    this.vouchersChanged.next(this.vouchers.slice());
    this.getVouchers();
  }

  editSetVoucher(voucher) {
    this.voucher = voucher;

    return this.voucher;
  }

  editGetVoucher() {
    return this.voucher;
  }

  setProducts(products: Product[]) {
    this.products = products;
    this.productsChanged.next(this.products.slice());

    return this.products;
  }

  getProducts() {
    return this.products.slice();
  }
}
