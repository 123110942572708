<div class="theme-selector-container" [ngClass]="{'with-margin': withMargin}">
  <div class="theme-selector" [matMenuTriggerFor]="themeMenu" matTooltip={{tooltipText}}
       [ngStyle]="{'background': selectedTheme?.primary  ? selectedTheme?.primary : 'white'}">
        <span class="theme-selector-icon">
            <mat-icon svgIcon="pencil"
                      [ngStyle]="{'color': !appliesToThemeService && !selectedTheme ? 'black' : !appliesToThemeService ? 'transparent' : ''}">
            </mat-icon>
        </span>
  </div>
  <span *ngIf="text" class="theme-selector-text">{{ text }}</span>
</div>

<mat-menu class="theme-picker-menu" #themeMenu="matMenu" [overlapTrigger]="false">
  <mat-grid-list cols="5">
    <mat-grid-tile *ngFor="let theme of themes">
      <div class="mat-grid-tile-content" mat-menu-item (click)="selectTheme(theme);$event.stopPropagation()">
        <div class="theme-picker-swatch" [ngClass]="{'swatch-border': theme.primary == '#FFFFFF'}">
          <mat-icon [svgIcon]="'check'" class="theme-chosen-icon" *ngIf="selectedTheme == theme">
          </mat-icon>
          <div class="theme-picker-primary" [style.background]="theme.primary"></div>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</mat-menu>
