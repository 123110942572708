import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LoginService } from '@app/auth/login/login.service';
import { ConfEventService } from '@app/confEvent/confEvent.service';
import { DashboardService } from '@app/dashboard/dashboard.service';
import { ProductsService } from '@app/products/products.service';
import { ProfitsService } from '@app/profits/profits.service';
import { RoomsService } from '@app/rooms/rooms.service';
import { VoucherService } from '@app/voucher/voucher.service';
import { WorkersService } from '@app/workers/workers.service';

import { Bee404Module } from '@bee-components/bee-404';
import { BEE_FORM_ITEM_DEFAULT_APPEARANCE } from '@bee-components/common/bee-forms';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { OrdersService } from '@app/orders/orders.service';
import { GroupsService } from './groups/groups.service';
import { ActivitiesService } from './activities/activities.service';
import { ShoppingCartService } from '@shared/services/shopping-cart.service';
import { AppCustomPreloader } from '@shared/preloader/app-custom-preloader';
import {
  AuthenticationService,
  GlobalArraysService,
  PwaService,
  ThemeService,
  TitleMetaService,
  ToggleSidebarService
} from '@shared/services';
import { DEFAULT_THEME } from '@shared/services/theme.service';
import { SharedModule } from '@shared/shared.module';
import { NgxImageCompressService } from 'ngx-image-compress';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

registerLocaleData(localeEs);

@NgModule({
  declarations: [
    AppComponent,
    ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule.forRoot(),
    MatTableModule,
    // ServiceWorkerModule.register('/custom-service-worker.js', {enabled: environment.production}),
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    LoadingBarRouterModule,
    HammerModule,
    Bee404Module,
    HttpClientModule,
    // ServiceWorkerModule.register('/ngsw-worker.js', {
    //   enabled: environment.production,
    //   // Register the ServiceWorker as soon as the app is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000'
    // })
  ],
  providers: [
    AuthenticationService,
    GlobalArraysService,
    PwaService,
    TitleMetaService,
    ToggleSidebarService,
    AppCustomPreloader,
    ThemeService,
    DashboardService,
    ProfitsService,
    ProductsService,
    WorkersService,
    OrdersService,
    RoomsService,
    GroupsService,
    ActivitiesService,
    VoucherService,
    ConfEventService,
    ShoppingCartService,
    NgxImageCompressService,
    LoginService,
    {provide: DEFAULT_THEME, useValue: 'bee-bracelit-theme'},
    {provide: BEE_FORM_ITEM_DEFAULT_APPEARANCE, useValue: 'standard'},
    {provide: LOCALE_ID, useValue: 'es'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
