import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { ApiService } from '@shared/services/api.service';

import { BeeNotificationsService } from '../bee-notifications.service';

/**
 * BeeNotificationsComponent, dialog with the notifications resume and options.
 */
@Component({
  selector: 'bee-notifications',
  templateUrl: 'bee-notifications.component.html',
  styleUrls: ['./bee-notifications.component.scss'],
})
export class BeeNotificationsComponent implements OnInit {
  /**
   * Constructor.
   * @param dialogRef
   * @param matDialog
   * @param router
   * @param apiService
   * @param beeNotificationsService
   * @param data
   */
  constructor(private dialogRef: MatDialogRef<BeeNotificationsComponent>,
              private matDialog: MatDialog,
              private router: Router,
              private apiService: ApiService,
              public beeNotificationsService: BeeNotificationsService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  /**
   * Load the unread notifications if exist.
   */
  ngOnInit() {
    this.dialogRef.updatePosition({top: this.data.positionY + 'px', left: this.data.positionX + 'px'});
    this.beeNotificationsService.getUnreadNotifications();
  }

  /**
   * Navigates to configuration route.
   */
  onConfiguration() {
    this.router.navigate([this.data.notificationsConfigurationRoute], {relativeTo: this.data.activatedRoute});
    this.dialogRef.close();
  }

  /**
   * Navigates to the notifications list.
   */
  onNotificationList() {
    if (this.data.activatedRoute.snapshot.routeConfig.path !== 'notificaciones') {
      this.router.navigate([this.data.notificationsRoute], {relativeTo: this.data.activatedRoute});
    }
    this.dialogRef.close();
  }

  /**
   * Closes the dialog.
   */
  closeDialog(): void {
    this.dialogRef.close();
  }
}
