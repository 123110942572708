import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';

/** PUBLIC KEY */
export const PUSH_NOTIFICATIONS_PUBLIC_KEY: InjectionToken<string> = new InjectionToken<string>('The push notifications public api key');

/**
 * BeePushNotificationsService, manages the activation of push notifications.
 */
@Injectable()
export class BeePushNotificationsService {
  /**
   * Selected theme. Default is 'bee-blue-theme'.
   * @type {string}
   */
  private _publicApiKey: string;

  /**
   * Constructor, ask for push notifications. Example of apiKey:
   * {"publicKey":"BEUmQQEQEEVcCfqSeZg2PnhcnCDsuBRqglC6RXkcP6nfiBqkMaqBvI68nklu40v2ZILasqNb6b_LkYVkZ6ZthgI","privateKey":"S3qONEX_GclcVfzkz7fVhKRvuwfmW8g1JPYsIDPpxBE"}
   * @param swPush
   * @param apiService
   * @param router
   * @param publicApiKey
   */
  constructor(private swPush: SwPush,
              private apiService: ApiService,
              private router: Router,
              @Optional() @Inject(PUSH_NOTIFICATIONS_PUBLIC_KEY) private publicApiKey: string) {

    (publicApiKey) ? this._publicApiKey = publicApiKey : this._publicApiKey = 'BEUmQQEQEEVcCfqSeZg2PnhcnCDsuBRqglC6RXkcP6nfiBqkMaqBvI68nklu40v2ZILasqNb6b_LkYVkZ6ZthgI';

    this.swPush.requestSubscription({
      serverPublicKey: this._publicApiKey
    })
      .then(sub => this.apiService.post(`notifications/push`, sub).subscribe())
      .catch(err => console.log('No se ha podido suscribir a las notificaciones con éxito.'));

    /*
    const id: string = 'message-' + Math.floor((Math.random() * 1000) + 1);
    this.swPush.messages.subscribe(
      (notification: any) => {
        localStorage.setItem(id, JSON.stringify(notification));
      });
      */
    this.swPush.notificationClicks.subscribe(
      (notificationEvent: any) => {
        if (notificationEvent.action) {
          this.router.navigate([notificationEvent.action]);
        } else if (notificationEvent.notification.actions.length > 0) {
          this.router.navigate([notificationEvent.notification.actions[0].action]);
        }
      });
  }
}
