<div id="action-top-bar-container">
  <div class="tabs" fxLayout="row" fxLayoutAlign="space-around" fxLayoutGap="7px">
    <div *ngFor="let action of actions"
         class="tab"
         [class.accent-background]="action.active"
         fxLayoutAlign="center center"
         (click)="triggerAction.emit(action)">
      {{action.name}}
    </div>
  </div>
</div>
