import { ScrollingModule } from '@angular/cdk/scrolling';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperIntl, MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';

import { getSpanishPaginatorIntl } from './spanish-paginator/spanish-paginator-intl';
import { getSpanishStepperIntl } from './spanish-stepper/spanish-stepper-intl';

/**
 * Date formats
 */
export const MY_FORMATS = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

/**
 * Material module, import/export the needed Material modules
 */
@NgModule({
  imports: [
    MatProgressBarModule,
    MatListModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatMenuModule,
    MatCardModule,
    MatToolbarModule,
    MatGridListModule,
    MatTabsModule,
    MatStepperModule,
    MatExpansionModule,
    MatBadgeModule,
    ScrollingModule
  ],
  exports: [
    MatProgressBarModule,
    MatListModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatMenuModule,
    MatCardModule,
    MatToolbarModule,
    MatGridListModule,
    MatTabsModule,
    MatStepperModule,
    MatExpansionModule,
    MatBadgeModule,
    ScrollingModule
  ],
  declarations: [],
  providers: [
    {provide: MatStepperIntl, useValue: getSpanishStepperIntl()},
    {provide: MatPaginatorIntl, useValue: getSpanishPaginatorIntl()},
    // {provide: MAT_DATE_LOCALE, BeeQrModuleuseValue: 'es'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    {
      provide: APP_INITIALIZER,
      deps: [MatIconRegistry],
      useFactory: preserveSvgViewBox,
      multi: true
    }
  ]
})
export class MaterialModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('assets/mdi.svg'));
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('app/shared/assets/svg-additions/general.min.svg'));

    matIconRegistry.addSvgIcon('icon-circle-add', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/icon-circle-add.svg'));
    matIconRegistry.addSvgIcon('plus-grey', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/plus-grey.svg'));
    matIconRegistry.addSvgIcon('wristband_white', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/wristband_white.svg'));
    matIconRegistry.addSvgIcon('add', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/add.svg'));
    matIconRegistry.addSvgIcon('add_outline_circle', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/add_outline_circle.svg'));
    matIconRegistry.addSvgIcon('arrow_pay_down', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/arrow_pay_down.svg'));
    matIconRegistry.addSvgIcon('arrow_pay_up', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/arrow_pay_up.svg'));
    matIconRegistry.addSvgIcon('back', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/back.svg'));
    matIconRegistry.addSvgIcon('back_color', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/back_color.svg'));
    matIconRegistry.addSvgIcon('card', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/card.svg'));
    matIconRegistry.addSvgIcon('cart', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/cart.svg'));
    matIconRegistry.addSvgIcon('check', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/check.svg'));
    // matIconRegistry.addSvgIcon('close', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/close.svg'));
    matIconRegistry.addSvgIcon('close_white', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/close_white.svg'));
    matIconRegistry.addSvgIcon('drink', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/drink.svg'));
    matIconRegistry.addSvgIcon('empty', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/empty.svg'));
    matIconRegistry.addSvgIcon('empty_ticket', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/empty_ticket.svg'));
    matIconRegistry.addSvgIcon('empty_ticket_secondary', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/empty_ticket_secondary.svg'));
    matIconRegistry.addSvgIcon('event', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/event.svg'));
    matIconRegistry.addSvgIcon('food&drink', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/food&drink.svg'));
    matIconRegistry.addSvgIcon('food', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/food.svg'));
    matIconRegistry.addSvgIcon('full', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/full.svg'));
    matIconRegistry.addSvgIcon('full_ticket', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/full_ticket.svg'));
    matIconRegistry.addSvgIcon('help', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/help.svg'));
    matIconRegistry.addSvgIcon('history', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/history.svg'));
    matIconRegistry.addSvgIcon('info', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/info.svg'));
    matIconRegistry.addSvgIcon('mastercard', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/mastercard.svg'));
    matIconRegistry.addSvgIcon('menu', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/menu.svg'));
    matIconRegistry.addSvgIcon('pay', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/pay.svg'));
    matIconRegistry.addSvgIcon('qrcode', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/qrcode.svg'));
    matIconRegistry.addSvgIcon('scan', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/scan.svg'));
    matIconRegistry.addSvgIcon('scan_white', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/scan_white.svg'));
    matIconRegistry.addSvgIcon('settings_white', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/settings_white.svg'));
    matIconRegistry.addSvgIcon('shop_add', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/shop_add.svg'));
    matIconRegistry.addSvgIcon('shop_minus', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/shop_minus.svg'));
    matIconRegistry.addSvgIcon('shutdown_white', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/shutdown_white.svg'));
    matIconRegistry.addSvgIcon('visa', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/visa.svg'));
    matIconRegistry.addSvgIcon('wristband_primary', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/wristband_primary.svg'));
    matIconRegistry.addSvgIcon('wristband_white', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/wristband_white.svg'));
    matIconRegistry.addSvgIcon('voucher_use_empty', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/voucher_use_empty.svg'));
    matIconRegistry.addSvgIcon('voucher_use_full', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/voucher_use_full.svg'));
    matIconRegistry.addSvgIcon('balance_wallet', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/account_balance_wallet_white_24dp.svg'));
    matIconRegistry.addSvgIcon('notifications_active', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/notifications_active.svg'));
    matIconRegistry.addSvgIcon('refresh_outline', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/refresh-outline.svg'));
    matIconRegistry.addSvgIcon('notifications_active', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/notifications_active.svg'));
    matIconRegistry.addSvgIcon('bizum', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/btn_bizum.png'));
  }
}

/**
 * Preserves de svg view boxes
 * @param matIconRegistry
 */
export function preserveSvgViewBox(matIconRegistry: MatIconRegistry) {
  return () => {
    const oldToSvgElement = matIconRegistry['_toSvgElement'];

    matIconRegistry['_toSvgElement'] = (element: Element) => {
      const svg = oldToSvgElement.call(matIconRegistry, element);

      const viewBox = element.getAttribute('viewBox');
      if (viewBox !== null) {
        svg.setAttribute('viewBox', viewBox);
      }

      return svg;
    };
  };
}
