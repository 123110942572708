import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from '@shared/angular-material';

import { BeeToolbarComponent } from './bee-toolbar.component';

@NgModule({
  declarations: [
    BeeToolbarComponent
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    BeeToolbarComponent
  ]
})
export class BeeToolbarModule { }
