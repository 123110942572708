import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ConfEventService } from '@app/confEvent/confEvent.service';
import { DashboardService } from '@app/dashboard/dashboard.service';
import { ProductsService } from '@app/products/products.service';
import { ProfitsService } from '@app/profits/profits.service';
import { RoomsService } from '@app/rooms/rooms.service';
import { UsersService } from '@app/users/users.service';
import { VoucherService } from '@app/voucher/voucher.service';
import { WorkersService } from '@app/workers/workers.service';
import { GroupsService } from '@app/groups/groups.service';
import { ActivitiesService } from '@app/activities/activities.service';

import { Product } from '@shared/models/product';
import { ApiService } from '@shared/services/api.service';
import { AuthenticationService } from '@shared/services/authentication.service';
import { SnackBarService } from '@shared/services/snack-bar.service';

@Injectable({providedIn: 'root'})
export class DataStorageService {
  myForm: FormGroup;
  product: Product;
  private category: any;
  private worker: any;
  private user: any;
  private notify: any;
  private uses: any;
  private voucher: any;
  private rechargePass: { amount: any; mailTorecharge: any; pushTorecharge: any; };
  private rechargePassNoWallet: { mailTorecharge: any; pushTorecharge: any; };
  private workerPass: { password: any; surname: any; roleId: any; name: any; usuario: any };
  private notifyPass: { title: any; body: any; click_action: any; icon: any;  tokens: string; timeToSend: string};
  private notifyMailPass: { subject: any; plaintext_body: any; sender_email: string; recipient_emails: any; timeToSend: string};
  private roomsAccessPass: { roomIds: any};
  private productPass: { product_category_id: any; price: any; name: string; image: FormData};
  private productEditPass: { NewName: any; NewPrice: any; product_category_id: any; };
  private datePass: any;
  private productId: any;
  private startingDate: string;
  private endingDate: string;
  private categoryId: any;
  private categoryEditPass: { NewName: any; NewIcon: any };
  public newAmount: number;
  private workerId: any;
  private userId: any;
  private userEditPass: { money: any; voucher: any[] };
  private userPassword: { password: any };
  private usesEditPass: { uses: any[] ; voucher: any[] };
  private roomId: any = [];
  private groupId: any = [];
  private activityId: any = [];
  private voucherId: any[];
  private workerEditPass: { roleId: any; name: any; surname: any; username: any; password: any};
  public pushLeft: number;
  public mailLeft: any;
  public noNotify: string;
  private name: any;
  private NewName: any;
  private productAttach: {type: any, limit: any, usesCost: any, discount: any, productId: any};
  private eventId: any;
  private eventImage: string = null;
  public errorMoveEdit: any;
  constructor(private http: HttpClient,
              private dashboardService: DashboardService,
              private productService: ProductsService,
              private profitsService: ProfitsService,
              private userService: UsersService,
              private voucherService: VoucherService,
              private workerService: WorkersService,
              private roomService: RoomsService,
              private confEventService: ConfEventService,
              private apiService: ApiService,
              private snackBarService: SnackBarService,
              private authService: AuthenticationService,
              private groupService: GroupsService,
              private activitiesService : ActivitiesService) {
  }

  fetchAccount() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    this.apiService
      .get('accounts/' + this.authService.getAccountId()).subscribe(
      data => {
        this.dashboardService.setDataAccount(data);
      },
      err => {
        console.error(err);
      });
  }

  fetchEvent() {
    this.userId = JSON.parse(localStorage.getItem('userId'));
    this.apiService
      .get('accounts/' + this.authService.getAccountId()['id'] + '/eventUser', 'query=&userId=' + this.userId).subscribe(
      data => {
        this.eventId = data[0].id;
        localStorage.setItem('eventId', JSON.stringify(this.eventId));
        this.confEventService.setDataEvent(data);
      },
      err => {
      console.error(err);
    });
  }

  fetchEventInfo() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));

    return this.apiService
      .get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/infoEvent');
  }

  fetchEvent2() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    this.userId = JSON.parse(localStorage.getItem('userId'));

    return this.apiService
      .get('accounts/' + this.authService.getAccountId()['id'] + '/eventUser', 'query=&userId=' + this.userId);
  }

  updateEvent(updateData) {
    this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/updateEvent', updateData)
      .subscribe(data => {
        localStorage.setItem('Event', JSON.stringify(data));
      },
      err => {
        console.error(err);
      });
  }

  storeProducts(product: Product, image) {
    this.product = product;
    this.productPass = {name: this.product['name'], price: this.product['price'], product_category_id: this.product['productCategoryId'].id, image: image};
    this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/newProductattach', this.productPass)
      .subscribe(response2 => {
          const respon = response2;
          respon.price = this.productPass.price;
          this.productService.addNewProduct(respon);
          this.apiService.post('files/' + respon.id, image).subscribe((_response) => {
            },
        err => {
          console.error(err);
        });
        },
        err => {
          console.error(err);
        });
  }

  fetchProducts() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    this.apiService
      .get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId)
      .subscribe(data => {
          this.dashboardService.setProducts(data.products);
          this.voucherService.setProducts(data.products);
        },
        err => {
          console.error(err);
        });
  }

  fetchProducts2() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));

    return this.apiService
      .get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId);
  }

  fetchEventProducts() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));

    return this.apiService
      .get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/listProducts' );
  }

  updateProduct(product: Product, id) {
    this.product = product;
    this.productId = id;
    this.productEditPass = {NewName: this.product['name'], NewPrice: this.product['price'], product_category_id: this.product['productCategoryId'].id};

    return this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/products/' + this.productId + '/updateProduct', this.productEditPass);
  }

  deleteProduct(id) {
    this.productId = id;

    return this.apiService
      .delete('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/products/' + this.productId);
  }

  storeCategories(category) {
    this.category = category;
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/newProductCategory', this.category)
      .subscribe(_response => {
        this.fetchCategories2().subscribe(data2 => {
            this.productService.setCategories(data2);
            this.dashboardService.setCategories(data2);
          },
          err => {
            console.error(err);
          });
        },
        err => {
          console.error(err);
        });
  }

  fetchCategories() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));

    this.apiService
      .get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/listProductCategories')
      .subscribe(data => {
        this.dashboardService.setCategories(data);
      },
      err => {
        console.error(err);
      });
  }

  fetchCategories2() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));

    return this.apiService
      .get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/listProductCategories');
  }

  updateCategory(category, id) {
    this.category = category;
    this.categoryId = id;
    this.categoryEditPass = {NewName: this.category['name'], NewIcon: this.category['icon']};
    this.NewName = this.category['name'];
    this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/product-categories/' + this.categoryId + '/updateCategory', this.categoryEditPass)
      .subscribe(_data => {
        this.fetchCategories2().subscribe(data2 => {
            this.productService.setCategories(data2);
            this.dashboardService.setCategories(data2);
          },
          err => {
            console.error(err);
          });
        },
        err => {
          console.error(err);
        });
  }

  deleteCategory(id) {
    this.categoryId = id;

    return this.apiService
      .delete('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/product-categories/' + this.categoryId);
  }

  fetchCreditMoves() {
    this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/creditMoveReportInfoBest').subscribe(
      data2 => {
        this.profitsService.setMoves(data2[0]);
        this.dashboardService.setMoves(data2[0], data2[1], data2[2], data2[3], data2[4]);
      },
      err2 => {
        console.error(err2);
      });
  }

  fetchCreditMoves2(page, amountTy) {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));

    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/creditMoveReportInfo', '&page=' + page + '&type=' + amountTy);
  }

  fetchCreditMovesClient(page, clientId) {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));

    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/clients/' + clientId + '/creditMoveReportInfoClient', '&page=' + page);
  }

  fetchCreditMovesAccount2(page) {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));

    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/creditMoveReportInfoAccount', '&page=' + page);
  }

  fetchCreditMovesDate(startingDate, endingDate) {
    this.startingDate = startingDate;
    this.endingDate = endingDate;
    this.datePass = {startingDate: this.startingDate, endingDate: this.endingDate};

    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/creditMoveReportInfo', '&startingDate=' + this.startingDate + '&' + 'endingDate=' + this.endingDate);
  }

  fetchCreditMovesDateFast(startingDate, endingDate) {
    this.startingDate = startingDate;
    this.endingDate = endingDate;
    this.datePass = {startingDate: this.startingDate, endingDate: this.endingDate};

    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/creditMoveReportInfoFast', '&startingDate=' + this.startingDate + '&' + 'endingDate=' + this.endingDate);
  }

  fetchCreditMovesDateAccount(startingDate, endingDate) {
    this.startingDate = startingDate;
    this.endingDate = endingDate;
    this.datePass = {startingDate: this.startingDate, endingDate: this.endingDate};

    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/creditMoveReportInfoAccount', '&startingDate=' + this.startingDate + '&' + 'endingDate=' + this.endingDate);
  }

  fetchRegisterControl(startingDate, endingDate) {
    this.startingDate = startingDate;
    this.endingDate = endingDate;
    this.datePass = {startingDate: this.startingDate, endingDate: this.endingDate};

    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/registerInfo', '&startingDate=' + this.startingDate + '&' + 'endingDate=' + this.endingDate);
  }

  fetchVouchers() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/listEasyVoucherBlueprint')
      .subscribe(
        data => {
          this.voucherService.setVouchers(data);
        }, err => {
          console.error(err);
        });
  }

  fetchVouchers2() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));

    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/listEasyVoucherBlueprint');
  }

  storeVouchers(voucher, prodsId, uses) {
    this.voucher = voucher;
    this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/easyVoucherBlueprint', this.voucher)
      .subscribe(data => {
        data.product = [];
        for (let i = 0; i < prodsId.length; i++) {
          this.productAttach = {type: 'uses', limit: -1, usesCost: uses[i], discount: 0, productId: prodsId[i]};
          this.apiService
            .post('accounts/' + this.authService.getAccountId()['id'] + '/voucher-blueprints/' + data.id + '/products/' + prodsId[i] + '/attach', this.productAttach)
            .subscribe(data2 => {
              data.product.push({name: data2.product.name, usesCost: (data2.usesCost + '.000')});
              },
              err2 => {
                console.error(err2);
              });
        }
        this.voucherService.addNewVoucher(data);
      },
      err => {
        console.error(err);
      });
  }

  attachProductOfVoucher(voucher, prodsId){
    this.voucher = voucher;

    return this.apiService.delete('accounts/' + this.authService.getAccountId()['id'] + '/voucher-blueprints/' + voucher + '/products/' + prodsId + '/deleteProduct', '');
  }

  listProductsOfVoucher(voucher){
    this.voucher = voucher;

    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/voucher-blueprints/' + voucher + '/listProductsToVoucher', '');
  }

  postVoucherBlueprint(voucher_blueprint: any) {
    const voucher_blueprint_json = {name: voucher_blueprint.name, uses: voucher_blueprint.uses, price: voucher_blueprint.price, has_bonus: voucher_blueprint.has_bonus, bonus_uses: voucher_blueprint.bonus_uses, is_bonus_percentage: voucher_blueprint.is_bonus_percentage, is_bonus_static: false};

    return  this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/voucher-blueprints', voucher_blueprint_json);
  }

  updateVoucherBlueprint(voucher,  id) {
    this.voucherService.editVoucher(voucher, id);
    this.voucherId = id;
    this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/voucher-blueprints/' + this.voucherId + '/updateVoucherBlueprint', voucher)
      .subscribe(_data => {
          this.fetchVouchers();
        },
        err => {
          console.error(err);
        });
  }

  updateVoucherBlueprint2(voucher,  id) {
    this.voucherService.editVoucher(voucher, id);
    this.voucherId = id;

    return this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/voucher-blueprints/' + this.voucherId + '/updateVoucherBlueprint', voucher);
  }

  deleteVoucherBlueprint(id) {
    this.voucherId = id;

    return this.apiService
      .delete('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/voucher-blueprints/' + this.voucherId);
  }

  fetchWorkers() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/staffMemberEvent')
      .subscribe(
        data => {
          this.workerService.setWorkers(data);
          this.dashboardService.setWorkers(data);
        }, err => {
          console.error(err);
        });
  }

  fetchWorkers2() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));

    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/staffMemberEvent');
  }

  deleteWorker(id) {
    this.workerId = id;

    return this.apiService
      .delete('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/staff-members/' + this.workerId);
  }

  fetchDevices() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));

    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/listOrionDevices');
  }

  fetchUsers() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/listUsersFast')
      .subscribe(
        data => {
          this.userService.setusers(data);
        }, err => {
          console.error(err);
        });
  }

  fetchUsers2() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));

    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/listUsersFast');
  }

  fetchUsersRoquetas() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));

    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/listUsersData');
  }

  fetchUserAcces2(wristbandId: string) {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));

    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/wristbands/' + wristbandId + '/rooms');
  }

  grantAccessUser(wristbandId: string, roomIds: any[]) {
    this.roomsAccessPass = {roomIds : roomIds};
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/wristbands/' + wristbandId + '/rooms', this.roomsAccessPass)
      .subscribe(_response => {
        },
        err => {
          console.log(err); });
  }

  clearAccessUser(wristbandId: string, roomIds: any[]) {
    this.roomsAccessPass = {roomIds : roomIds};
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/wristbands/' + wristbandId + '/rooms' + '/clear', this.roomsAccessPass)
      .subscribe(_response => {},
        err => {
          console.log(err); });
  }

  storeWorkers(worker) {
    this.worker = worker;
    this.workerPass = {name: this.worker['name'], surname: this.worker['surname'], password: this.worker['password'], usuario: this.worker['username'], roleId: this.worker['roleId'].id};
    this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/newStaff&User', this.workerPass)
      .subscribe(response2 => {
          const respon = worker;
          respon.id = response2.staffMemberId;
          this.workerService.addNewWorker(this.worker);
          },
        err => {
          console.error(err);
        });
  }

  storeWorkers2(worker) {
    this.worker = worker;
    this.workerPass = {name: this.worker['name'], surname: this.worker['surname'], password: this.worker['password'], usuario: this.worker['username'], roleId: this.worker['roleId'].id};

    return this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/newStaff&User', this.workerPass);
  }

  updateWorker(worker, id) {
    this.worker = worker;
    this.workerId = id;
    this.workerEditPass = {roleId: this.worker['roleId'].id, name: this.worker['name'], surname: this.worker['surname'], username: this.worker['username'], password: this.worker['password']};
    this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/staff-members/' + this.workerId + '/updateStaffMemberuser', this.workerEditPass)
      .subscribe(_data => {
        this.fetchWorkers();
        },
        err => {
          console.error(err);
        });
  }

  updateWorker2(worker, id) {
    this.worker = worker;
    this.workerId = id;
    this.workerEditPass = {roleId: this.worker['roleId'].id, name: this.worker['name'], surname: this.worker['surname'], username: this.worker['username'], password: this.worker['password']};

    return this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/staff-members/' + this.workerId + '/updateStaffMemberuser', this.workerEditPass);
  }

  updateUser(user, voucher, id) {
    this.user = user;
    this.voucherId = voucher;
    this.userId = id;
    this.userEditPass = {money: this.user['money'], voucher: this.voucherId};
    this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/users/' + this.userId + '/updateUser', this.userEditPass)
      .subscribe(_data => {
        this.fetchUsers();
        },
        err => {
          console.error(err);
        });
  }

  updateUser2(user, voucher, id) {
    this.user = user;
    this.voucherId = voucher;
    this.userId = id;
    this.userEditPass = {money: this.user['money'], voucher: this.voucherId};

    return this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/users/' + this.userId + '/updateUser', this.userEditPass);
  }

  updateUserPassword(user, id) {
    this.userId = id;
    this.userPassword = {password: user['password']};
    this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/users/' + this.userId + '/updateUser', this.userPassword)
      .subscribe(_data => {
          this.fetchUsers();
        },
        err => {
          console.error(err);
        });
  }

  userPushNotification(notify) {
    this.notify = notify;
    this.notifyPass = {title: this.notify['subject'], body: this.notify['bodytext'], click_action: this.notify['action'], icon: this.eventImage , tokens: this.notify['tokens'], timeToSend: this.notify['timeToSend']};
    this.apiService
    .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/SendNotification', this.notifyPass)
    .subscribe(data => {
      this.userService.setNotifyPushResponse(data);
    }, err => {
      console.error(err);
    });
  }

  userMailNotification(notify) {
    this.notify = notify;
    this.notifyMailPass = {subject: this.notify['subject'], plaintext_body: this.notify['bodytext'], sender_email: 'notificaciones@bracelit.es', recipient_emails: this.notify['mails'], timeToSend: this.notify['timeToSend']};
    this.apiService
    .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/Mailing', this.notifyMailPass)
    .subscribe(data => {
      this.userService.setNotifyMailResponse(data);
    }, err => {
      console.error(err);
    });
  }

  userPushMailNotification(notify) {
    this.notify = notify;
    this.notifyMailPass = {subject: this.notify['subject'], plaintext_body: this.notify['bodytext'], sender_email: 'notificaciones@bracelit.es', recipient_emails: this.notify['mails'], timeToSend: this.notify['timeToSend']};
    this.notifyPass = {title: this.notify['subject'], body: this.notify['bodytext'], click_action: this.notify['action'], icon: this.eventImage , tokens: this.notify['tokens'], timeToSend: this.notify['timeToSend']};
    this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/SendNotification', this.notifyPass)
      .subscribe(data => {
        if (data['PushLeft']) {
          this.pushLeft = data['PushLeft'];
          } else {
            this.noNotify = data['error'];
          }
        },
        err => {
          console.error(err);
        });
    this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/Mailing', this.notifyMailPass)
      .subscribe(data => {
        if (data['MailLeft']) {
          this.mailLeft = data['MailLeft'];
          } else {
            this.noNotify = data['error'];
        }
      },
        err => {
          console.error(err);
        });
  }

  updateUserUsesLeft(uses, voucher, id) {
    this.uses = uses;
    this.voucher = voucher;
    this.userId = id;
    this.usesEditPass = {voucher: this.voucher, uses: this.uses};
    this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/users/' + this.userId + '/updateUsesLeft', this.usesEditPass)
      .subscribe(_data => {
        this.fetchUsers();
        },
        err => {
          console.error(err);
        });
  }

  addVoucherAutomatically(voucherId, wallets, clients) {
    const dataJSON = {wallets: wallets, clients: clients};
    this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/voucher-blueprints/' + voucherId + '/rechargeVoucherAutomatically', dataJSON)
      .subscribe(_data => {
        },
        err => {
          console.error(err);
        });
  }

  fetchRoles() {
    this.apiService
      .get('roquetas/roles').subscribe(
      data => {
        this.workerService.setRoles(data);
      },
      err => {
        console.error(err);
      });
  }

  fetchRoles2() {
    return this.apiService.get('roquetas/roles');
  }

  fetchRooms() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/rooms')
      .subscribe(
        data => {
          this.roomService.setRooms(data);
          this.dashboardService.setRooms(data);
        }, err => {
          console.error(err);
        });
  }

  fetchRooms2() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));

    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/rooms');
  }

  fetchGroups() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));

    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/listGroup');
  }

  fetchActivities() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));

    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/listActivity');
  }

  storeRooms(room) {
    this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/rooms', room)
      .subscribe(response => {
          const respon = room;
          respon.id = response.id;
          this.roomService.addNewRoom(room);
        },
        err => {
          console.error(err);
        });
  }

  storeGroups(group) {
    this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/createGroup', group)
      .subscribe(response => {
        this.groupService.addNewGroup(response);
        },
        err => {
          console.error(err);
        });
  }

  storeActivities(activity) {
    this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/createActivity', {name: activity.name, maxCapacity: activity.maxCapacity, autoAccessEnable: activity.autoAccessEnable, group: activity.group.id, date: activity.date})
      .subscribe(response => {
        this.activitiesService.addNewActivity(response);
        },
        err => {
          console.error(err);
        });
  }

  storeGroupFromActivity(activity) {
    return this.apiService
      .get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/activities/' + activity + '/listGroupFromActivity', '');
  }

  updateRoom(room, id) {
    this.roomService.editRoom(room, id);
    this.roomId = id;

    return this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/rooms/' + this.roomId, room);
  }

  updateGroup(group, id) {
    this.groupService.editGroup(group, id);
    this.groupId = id;

    return this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/groups/' + this.groupId + '/updateGroup', group);
  }

  updateActivity(activity, id, lastGroup) {
    this.activitiesService.editActivity(activity, id);
    this.activityId = id;

    return this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/activities/' + this.activityId + '/updateActivity', {name: activity.name, maxCapacity: activity.maxCapacity, autoAccessEnable: activity.autoAccessEnable, group: activity.group.id, lastGroup: lastGroup, date: activity.date});
  }

  deleteRoom(id) {
    this.roomId = id;

    return this.apiService
      .delete('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/rooms/' + this.roomId);
  }

  deleteGroup(id) {
    this.groupId = id;

    return this.apiService
      .delete('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/groups/' + this.groupId + '/deleteGroup');
  }

  deleteActivity(id) {
    this.activityId = id;

    return this.apiService
      .delete('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/activities/' + this.activityId + '/deleteActivity');
  }

  fetchMovesRooms(startingDate, endingDate) {
    this.startingDate = startingDate;
    this.endingDate = endingDate;
    this.datePass = {startingDate: this.startingDate, endingDate: this.endingDate};

    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/eventRoomsFast', '&startingDate=' + this.startingDate + '&' + 'endingDate=' + this.endingDate);
  }

  fetchMovesActivities(startingDate?, endingDate?, page?) {
    this.startingDate = startingDate;
    this.endingDate = endingDate;
    this.datePass = {startingDate: this.startingDate, endingDate: this.endingDate};
    if ((this.startingDate !== null) && (this.endingDate !== null)) {
      return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/eventActivitiesFast', '&startingDate=' + this.startingDate + '&' + 'endingDate=' + this.endingDate);
    } else {
      return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/eventActivitiesFast', '&page=' + page);
    }
  }

  fetchMovesRoomsAccount(startingDate, endingDate) {
    this.startingDate = startingDate;
    this.endingDate = endingDate;
    this.datePass = {startingDate: this.startingDate, endingDate: this.endingDate};

    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId +  '/eventRoomsWithAccount', '&startingDate=' + this.startingDate + '&' + 'endingDate=' + this.endingDate);
  }

  fetchMovesRoomsPage(page) {
    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/eventRooms', '&page=' + page);
  }

  fetchMovesRoomsPageWithAccount(page) {
    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/eventRoomsWithAccount', '&page=' + page);
  }

  updateInfoForUsers(info) {
    this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/updateInfoForUsers', info)
      .subscribe(_data => {
        },
        err => {
          console.error(err);
        });
  }
  rechargeNotify(walletId, form) {
    this.rechargePass = {amount: form['bracels'], mailTorecharge: form['mailLeft'], pushTorecharge: form['pushLeft']};
    this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/wallets/' + walletId + '/recharge-notifications' , this.rechargePass)
      .subscribe(_data => {
        },
        err => {
          console.error(err);
        });
  }
  rechargeNotifyNowallet(pushLeft, mailLeft, eventId) {
    this.rechargePassNoWallet = {mailTorecharge: mailLeft, pushTorecharge: pushLeft};
    this.apiService
      .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + eventId  + '/recharge-notifications' , this.rechargePassNoWallet)
      .subscribe(_data => {
        },
        err => {
          console.error(err);
        });
  }

  fecthAdmininfo() {
    const adminId = JSON.parse(localStorage.getItem('userId'));
    this.eventId = JSON.parse(localStorage.getItem('eventId'));

    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/users/' + adminId);
  }

  modifyCreditMove(moveId, amount) {
    return new Promise<void>((resolve, reject) => {
      this.apiService
        .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/creditMove', {
          creditMoveId: moveId,
          newAmount: amount
        }).toPromise().then(
        res => { // Success
          resolve();
          this.newAmount = res.amount;
        },
        msg => { // Error
          reject(msg);
          console.log(msg);
          this.errorMoveEdit = msg;
          this.profitsService.hasErrorMsg = true;
        });
    });
  }

  deleteCreditMove(move) {
    return new Promise<void>((resolve, reject) => {
      this.apiService
        .delete('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/creditMove/' + move.creditMove.id, {
          client: move.client[0]
        })
        .toPromise().then(
        res => { // Success
          this.newAmount = res.json().results;
          resolve();
        },
        msg => { // Error
          reject(msg);
          console.log(msg);
          this.errorMoveEdit = msg;
          this.profitsService.hasErrorMsg = true;
        });
    });
  }

  downloadCSV(id) {
    if (id === 1) {
      return this.apiService.download('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/exportCMCSV');
    } else {
      return this.apiService.download('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/exportAccessCSV');
    }
  }

  fetchWristListByRooms() {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));

    return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/listWristbandRooms').toPromise();
  }

  fetchOrders(onlinePlace) {
    this.eventId = JSON.parse(localStorage.getItem('eventId'));
    if (!onlinePlace) {
      onlinePlace = '';
    }

    return this.apiService.get(`accounts/${this.authService.getAccountId()['id']}/events/${this.eventId}/onlineOrders`, 'query=&onlinePlace=' + onlinePlace);
  }

  listOrderPlaces() {
    const accountId = this.authService.getAccountId()['id'];
    const eventId =  JSON.parse(localStorage.getItem('eventId'));

    return this.apiService.get(`accounts/${accountId}/events/${eventId}/onlineOrderPlaces`);
  }

  createOrderPlace(data) {
    const accountId = this.authService.getAccountId()['id'];
    const eventId =  JSON.parse(localStorage.getItem('eventId'));

    return this.apiService.post(`accounts/${accountId}/events/${eventId}/newOnlineOrderPlace`,
    {name: data.name}).subscribe(response => {
      console.log(response);
    },
    err => {
      console.error(err);
    });
  }

  updateOrderPlace(orderPlace, placeEdit) {
    return this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/onlineOrderPlaces/' + orderPlace, placeEdit);
  }

  deleteOrderPlace(id) {
    return this.apiService
      .delete('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/onlineOrderPlaces/' + id);
  }

  updateOrder(order, value) {
    const orderEdit = {status: value};

    return this.apiService
      .patch('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/onlineOrders/' + order, orderEdit);
  }

  postImport(file){
    return this.apiService.upload('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId+ '/import',file)
  }
  delemStockMailNotification(name,roomId) {
    return this.apiService
    .post('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/delem/rooms/'+roomId+'/stockNotification',{'name':name}).toPromise()
  }

  fetchCreditMovesGraph(startingDate, endingDate, worker?: string, category?: string, product?: string) {
    if ((worker !== null) && (worker !== undefined)) {
      return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/graphCreditMoves', '&startingDate=' + startingDate + '&' + 'endingDate=' + endingDate + '&' + 'workerFilter=' + worker);
    } else if ((category !== null) && (category !== undefined)) {
      return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/graphCreditMoves', '&startingDate=' + startingDate + '&' + 'endingDate=' + endingDate + '&' + 'categoryFilter=' + category);
    } else if ((product !== null) && (product !== undefined)) {
      return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/graphCreditMoves', '&startingDate=' + startingDate + '&' + 'endingDate=' + endingDate + '&' + 'productFilter=' + product);
    } else {
      return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/graphCreditMoves', '&startingDate=' + startingDate + '&' + 'endingDate=' + endingDate);
    }
  }

  fetchAccessGraph(startingDate, endingDate, room?: string) {
    if ((room !== null) && (room !== undefined)) {
      return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/graphAccess', '&startingDate=' + startingDate + '&' + 'endingDate=' + endingDate + '&' + 'roomFilter=' + room);
    } else {
      return this.apiService.get('accounts/' + this.authService.getAccountId()['id'] + '/events/' + this.eventId + '/graphAccess', '&startingDate=' + startingDate + '&' + 'endingDate=' + endingDate);
    }
  }
  listTickets(){
    return this.apiService.get(`accounts/${this.authService.getAccountId()['id']}/events/${this.eventId}/listTickets`);
  }

  editDevice(data){
    return this.apiService.patch(`accounts/${this.authService.getAccountId()['id']}/events/${this.eventId}/editOrionDevice`,data)
  }
}
